import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Formik } from 'formik';
import {
	Button,
	Col,
	Container,
	CustomInput,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
	UncontrolledDropdown,
	Form,
	FormFeedback,
	FormGroup,
	Label,
	Input,
	FormText
} from 'reactstrap';

import {
	fetchUser,
	fetchUsers,
	approveUser,
	deleteUser,
	getAuthenticatedUserAttributes,
	updateUser,
} from 'actions';

import formatDate from 'screens/helpers';

import { Crumbs } from 'containers';

import './Users.scss';

class Users extends Component {
	state = {
		// typeFilter: 'staging',
		// nameFilter: '',
		enabledFilter: '',
		userRoleFilter: '',
		deleteModal: false,
		deleteUser: {},
		groupsModal: false,
		groupsUser: {},
		addGroup: '',
		preparedState: null,
		approveUserModal: false,
		denyUserModal: false,
		updateUserRoleModal: false,
		userInfoModal: false,
		verifyEmail: '',
		approveThisUser: {},
		isAdmin: '',
		userFilter: '',
		currentUser: '',
		thisUser: ''
	};

	componentDidMount() {
		this.props.fetchUsers();
		this.updateUserState();
		//this.props.fetchUsersProduction();
	}

	componentDidUpdate({ userProcess: pUserProcess }) {
		const { userProcess } = this.props;
		const { preparedState } = this.state;

		if (
			pUserProcess === null &&
			userProcess === true &&
			preparedState != null
		) {
			this.setState({
				...preparedState,
				preparedState: null
			});
		}
	}

	async updateUserState() {
		const { email } = await getAuthenticatedUserAttributes();
		let currentUser;
		let users = await this.props.fetchUsers();

		for (let user in users.payload) {
			if (users.payload[user].email === email) {
				currentUser = users.payload[user];

				if (currentUser.userRole === 'admin') {
					this.setState({
						isAdmin: true,
						currentUser: currentUser
					});
				} else {
					this.setState({
						isAdmin: false,
						currentUser: currentUser
					});
				}
			}
		}
	}

	toggleApproveUserModal = () => {
		this.setState((prevState) => ({
			approveUserModal: !prevState.approveUserModal
		}));
	};

	onOpenUserInfo = ({ user }) => {
		this.setState({
			userInfoModal: true,
			thisUser: user
		});
	};

	toggleUserInfoModal = () => {
		this.setState((prevState) => ({
			userInfoModal: !prevState.userInfoModal
		}));
	};

	onApproveUser = async ({ user }) => {
		this.setState({
			approveUserModal: true,
			approveThisUser: user
		});

		console.log("here")
	};

	// async updateUserState() {
	// 	const userAttributes = await getAuthenticatedUserAttributes();
	// 	this.setState({
	// 		...userAttributes
	// 	});
	// }

	onApproveUserAction = async ({ values: { email, userRole, id }, setSubmitting }) => {

		

		let body = {
			email: email.trim(),
			userRole: 'user',
			id: id,
			toaster_title: 'Approve User',
			toaster_feedback: `User '${email}' has been successfully approved`
		};

		console.log(body)

		// Approve the user in the db
		await this.props.approveUser(body);

		setSubmitting(false);
		this.setState({
			approveUserModal: false,
			approveThisUser: {}
		});

		this.onReloadList();
	};

	toggleRejectUserModal = () => {
		this.setState((prevState) => ({
			denyUserModal: !prevState.denyUserModal
		}));
	};

	toggleUpdateUserRoleModal = () => {
		this.setState((prevState) => ({
			updateUserRoleModal: !prevState.updateUserRoleModal
		}));
	};

	onUpdateUserRole = async ({ user }) => {
		console.log(user)

		this.setState({
			updateUserRoleModal: true,
			approveThisUser: user
		});
	};

	onRejectUser = async ({ user }) => {
		this.setState({
			denyUserModal: true,
			approveThisUser: user
		});
	};

	onUpdateUserRoleAction = async ({
		values: { username, email, userRole, enabled },
		setSubmitting
	}) => {
		console.log(username)

		let body = {
			id: username,
			userRole: userRole,
			enabled: enabled,
			toaster_title: 'Change user role',
			toaster_feedback: `User '${email}' has been successfully updated`
		}

		// let body = {
		// 	email: email.trim(),
		// 	userRole: userRole,
		// 	enabled: enabled,
		// 	status_change: new Date(),
		// 	approvedBy: this.state.currentUser['email'],
		// 	toaster_title: 'Change user role',
		// 	toaster_feedback: `User role for '${email}' has been successfully updated`
		// };

		console.log(body);

		// // Approve the user in the db
		await this.props.updateUser(body);

		setSubmitting(false);
		this.setState({
			updateUserRoleModal: false,
			approveThisUser: {}
		});

		// this.onReloadList();
	};

	onRejectUserAction = async ({ values: { email }, setSubmitting }) => {
		let body = {
			email: email.trim(),
			userRole: 'denied',
			toaster_title: 'Deny User',
			toaster_feedback: `User '${email}' has been successfully denied`
		};

		// Approve the user in the db
		await this.props.approveUser(body);

		setSubmitting(false);
		this.setState({
			denyUserModal: false,
			approveThisUser: {}
		});

		this.onReloadList();
	};

	handleChange = (event) => {
		console.log(event.target.value);
		console.log(event.target.id);
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	onReloadList = () => {
		this.props.fetchUsers();
		//this.props.fetchUsersProduction();
	};

	onDisableUserAction = async ({ user: { username } }) => {
		const { typeFilter } = this.state;

		// Disable the user in cognito
		if (typeFilter === 'production') {
			await this.props.disableUserProduction({
				username
			});
		} else if (typeFilter === 'staging') {
			await this.props.disableUserStaging({
				username
			});
		}
	};

	onEnableUserAction = async ({ user: { username } }) => {
		const { typeFilter } = this.state;

		// Enable the user in cognito
		if (typeFilter === 'production') {
			await this.props.enableUserProduction({
				username
			});
		} else if (typeFilter === 'staging') {
			await this.props.enableUserStaging({
				username
			});
		}
	};

	toggleDelete = () => {
		this.setState((prevState) => ({
			deleteModal: !prevState.deleteModal
		}));
	};

	onDeleteUser = ({ user }) => {
		this.setState({
			deleteUser: user,
			deleteModal: true
		});
	};

	onDeleteUserAction = async () => {
		const {
			deleteUser: { email }
		} = this.state;

		console.log(this.state)

		//await this.props.deleteUser({ email });
		// Delete the user in cognito
		// if (typeFilter === 'production') {
		// 	await this.props.deleteUserProduction({
		// 		username
		// 	});
		// } else if (typeFilter === 'staging') {
		// 	await this.props.deleteUserStaging({
		// 		username
		// 	});
		// }

		this.setState({
			deleteModal: false,
			deleteUser: {}
		});
	};

	toggleGroups = () => {
		this.setState((prevState) => ({
			groupsModal: !prevState.groupsModal
		}));
	};

	onUserGroups = ({ user }) => {
		this.setState({
			groupsUser: user,
			groupsModal: true
		});
	};

	onRemoveUserSecurityGroupAction = async ({ groupsUser, group }) => {
		const { typeFilter } = this.state;

		this.setState({
			preparedState: {
				groupsUser: {
					...groupsUser,
					groups: groupsUser.groups.filter((grp) => grp !== group)
				}
			}
		});

		// Delete the user group in cognito
		if (typeFilter === 'production') {
			await this.props.removeUserSecurityGroupProduction({
				username: groupsUser.username,
				usergroup: group
			});
		} else if (typeFilter === 'staging') {
			await this.props.removeUserSecurityGroupStaging({
				username: groupsUser.username,
				usergroup: group
			});
		}
	};

	onAddUserSecurityGroupAction = async ({ groupsUser, group }) => {
		const { typeFilter } = this.state;
		const currentGroups = groupsUser.groups || [];

		this.setState({
			preparedState: {
				groupsUser: {
					...groupsUser,
					groups: [...currentGroups, group]
				}
			},
			addGroup: ''
		});

		// Add the user group in cognito
		if (typeFilter === 'production') {
			await this.props.addUserSecurityGroupProduction({
				username: groupsUser.username,
				usergroup: group
			});
		} else if (typeFilter === 'staging') {
			await this.props.addUserSecurityGroupStaging({
				username: groupsUser.username,
				usergroup: group
			});
		}
	};

	buildResultsTable() {
		let { users } = this.props;
		const { isAdmin, userRoleFilter, userFilter, enabledFilter } = this.state;
		console.log(users);

		// for (let i in users) {
		// 	if (users[i].hasOwnProperty('statusChangeDate')) {
		// 		users[i]['statusChangeDate2'] =
		// 			users[i]['statusChangeDate'].substring(8, 10) +
		// 			'-' +
		// 			users[i]['statusChangeDate'].substring(5, 7) +
		// 			'-' +
		// 			users[i]['statusChangeDate'].substring(0, 4) +
		// 			'  ' +
		// 			users[i]['statusChangeDate'].substring(11, 16) +
		// 			' ' +
		// 			'GMT';

		// 		let newDate = new Date();
		// 		let oldDate = Date.parse(users[i]['statusChangeDate']);
		// 		let oldDate2 = new Date(oldDate);
		// 		let daysDiff = (
		// 			(newDate.getTime() - oldDate2.getTime()) /
		// 			(1000 * 3600 * 24)
		// 		).toFixed();

		// 		if (users[i]['enabled'] === false && daysDiff > 180) {
		// 			users[i]['allowToDelete'] = true;
		// 		}
		// 	}
		// }

		if (userRoleFilter !== '') {
			users = users.filter(({ userRole }) => {
				return userRole === userRoleFilter;
			});
		}

		if (enabledFilter !== '') {
			users = users.filter(({ enabled }) => {
				if (enabledFilter === 'true') {
					return enabled === true;
				} else {
					return enabled === false;
				}
			});
		}

		if (userFilter !== '') {
			users = users.filter(({ email }) => {
				return email.toString().includes(userFilter.toLowerCase());
			});
		}

		//const dspUsers = users;

		const assignedUsers = users.filter((user) =>
			user.hasOwnProperty('userRole')
		);

		const unassignedUsers = users.filter(
			(user) => !user.hasOwnProperty('userRole')
		);

		let userRoles = ['user', 'admin', 'superUser', 'denied', 'editor'];

		return (
			<Row noGutters>
				{!isAdmin && (
					<Col>
						<h4 className="text-center">
							This page can only be viewed by Admin users.
						</h4>
					</Col>
				)}
				{isAdmin && (
					<Col xs="12">
						{unassignedUsers.length > 0 && (
							<h4 className="text-center">
								<i className="fa-solid fa-user-xmark pr-4"></i> Unassigned Users
							</h4>
						)}
						{unassignedUsers.length > 0 && (
							<Table>
								<thead>
									<tr>
										<th style={{ width: '60px' }}>#</th>
										<th style={{ width: '100px' }} className="text-center">
											Enabled
										</th>
										<th>Role</th>

										<th style={{ width: '350px' }}>Email </th>
										<th></th>
										<th></th>
									</tr>
								</thead>

								<tbody>
									{unassignedUsers.map((user, ind) => {
										const { enabled, userRole, email, emailVerified } = user;
										const dspInd = ind + 1;
										return (
											<tr key={`region${dspInd}`}>
												<th scope="row" style={{ width: '60px' }}>
													{dspInd}
												</th>
												<td className="text-center" style={{ width: '100px' }}>
													{enabled && (
														<i className="fas fa-check text-success" />
													)}
													{!enabled && (
														<i className="fas fa-times text-danger" />
													)}
												</td>
												<td>
													{userRole === 'superUser' && (
														<i className="fa-solid fa-user-shield" />
													)}
												</td>

												<td style={{ width: '400px' }}>
													{email}{' '}
													<sup>
														{emailVerified === 'true' && (
															<i className="fas fa-check-circle text-success" />
														)}
														{emailVerified === 'false' && (
															<i className="fas fa-times-circle text-danger" />
														)}
													</sup>
												</td>
												<td style={{ width: '45px' }}>
													<Button
														className="btn btn-success"
														onClick={(e) => this.onApproveUser({ user }, e)}
													>
														Verify
													</Button>
												</td>
												<td style={{ width: '45px' }}>
													<Button
														className="btn btn-danger"
														onClick={(e) => this.onRejectUser({ user }, e)}
													>
														Deny
													</Button>
												</td>
											</tr>
										);
									})}
								</tbody>
							</Table>
						)}

						<h4 className="text-center mt-3">
							<i className="fa-solid fa-user-check pr-4"></i> Assigned Users
						</h4>
						<Table>
							<thead>
								<tr>
									<th style={{ width: '60px' }}>#</th>
									<th style={{ width: '100px' }} className="text-center">
										Enabled
										<CustomInput
											id="enabledFilter"
											name="enabledFilter"
											type="select"
											className="mr-2"
											onChange={(event) => this.handleChange(event)}
											defaultValue={enabledFilter}
											bsSize="sm"
											inline
										>
											<option value="" key="enabledall">
												All
											</option>
											<option value="true" key="enabled1">
												Enabled
											</option>
											<option value="false" key="enabled2">
												Disabled
											</option>
										</CustomInput>
									</th>
									<th style={{ width: '200px' }}>
										<CustomInput
											id="userRoleFilter"
											name="userRoleFilter"
											type="select"
											className="mr-2"
											onChange={(event) => this.handleChange(event)}
											defaultValue={userRoleFilter}
											bsSize="sm"
											inline
										>
											<option value="">All roles</option>
											{userRoles.map((filteredUserRole, idx) => (
												<option value={filteredUserRole} key={`role${idx}`}>
													{filteredUserRole}
												</option>
											))}
										</CustomInput>
									</th>
									<th style={{ width: '350px' }}>
										Email{' '}
										<CustomInput
											id="userFilter"
											name="userFilter"
											type="text"
											className="ml-2 mr-2 name-filter"
											onChange={(event) => this.handleChange(event)}
											defaultValue={userFilter}
											placeholder="User search"
											bsSize="sm"
											inline
										/>
									</th>
									{/* <th title="Status changed between Enabled/Disabled">
										Status Last Changed
									</th> */}
									<th></th>
									{/* <th colSpan="2">
									Groups
									<span
										className="position-absolute align-bottom"
										style={{ right: '12px', top: '10px' }}
									>
										<CustomInput
											id="nameFilter"
											name="nameFilter"
											type="text"
											className="mr-2 name-filter"
											onChange={(event) => this.handleChange(event)}
											defaultValue={nameFilter}
											placeholder="Search"
											bsSize="sm"
											inline
										/>
										<ButtonGroup size="sm" className="mr-2">
											<Button
												color="secondary"
												outline={typeFilter !== 'staging'}
												onClick={() =>
													this.handleChange({
														target: { id: 'typeFilter', value: 'staging' }
													})}
											>
												Staging
											</Button>
											<Button
												color="danger"
												outline={typeFilter !== 'production'}
												onClick={() =>
													this.handleChange({
														target: { id: 'typeFilter', value: 'production' }
													})}
											>
												<i className="fas fa-exclamation mr-2" />Production
											</Button>
										</ButtonGroup>
										<Button
											size="sm"
											outline
											color="secondary"
											onClick={this.onReloadList}
										>
											<i className="fas fa-sync-alt" />
											<span className="sr-only">Refresh</span>
										</Button>
									</span>
								</th> */}
								</tr>
							</thead>
							<tbody>
								{assignedUsers.map((user, ind) => {
									const {
										enabled,
										userRole,
										email,
										givenName,
										
										emailVerified,
										allowToDelete,
										groups = []
									} = user;
									const dspInd = ind + 1;
									return (
										<tr key={`region${dspInd}`}>
											<th scope="row" style={{ width: '60px' }}>
												{dspInd}
											</th>
											<td className="text-center" style={{ width: '100px' }}>
												{enabled && <i className="fas fa-check text-success" />}
												{!enabled && <i className="fas fa-times text-danger" />}
											</td>
											<td>
												{userRole === 'superUser' && (
													<i
														title="Superuser"
														className="fa-solid fa-user-shield"
													/>
												)}

												{userRole === 'admin' && (
													<i title="Admin" className="fa-solid fa-user-lock" />
												)}
												{userRole === 'denied' && (
													<i
														title="Denied"
														className="fa-solid fa-user-slash"
													/>
												)}
												{userRole === 'editor' && (
													<i title="Editor" className="fa-solid fa-user-pen" />
												)}
											</td>
											<td style={{ width: '400px' }}>
												{email}{' '}
												<sup>
													{emailVerified === 'true' && (
														<i className="fas fa-check-circle text-success" />
													)}
													{emailVerified === 'false' && (
														<i className="fas fa-times-circle text-danger" />
													)}
												</sup>
											</td>
											{/* <td>{statusChangeDate2}</td> */}
											<td>{groups.join(' | ')}</td>
											<td style={{ width: '45px' }}>
												<UncontrolledDropdown size="sm">
													<DropdownToggle className="pointered btn-light">
														<i className="fas fa-bars" />
														<span className="sr-only">Actions</span>
													</DropdownToggle>
													<DropdownMenu>
														{/* <DropdownItem
															className="pointered"
															onClick={(e) => this.onOpenUserInfo({ user }, e)}
														>
															<i className="fa-solid fa-circle-info mr-2" />
															User info
														</DropdownItem> */}
														{userRole !== 'denied' && (
															<DropdownItem
																className="pointered"
																onClick={(e) =>
																	this.onUpdateUserRole({ user }, e)
																}
															>
																<i className="fa-solid fa-users-gear mr-2" />
																Update user role/status
															</DropdownItem>
														)}
														{enabled && (
															<Fragment>
																<DropdownItem
																	disabled
																	className="pointered"
																	onClick={(e) =>
																		this.onDeleteUser({ user }, e)
																	}
																>
																	<i className="fas fa-trash-alt fa-fw mr-2 text-muted" />
																	Delete
																</DropdownItem>
															</Fragment>
														)}
														{!enabled && allowToDelete && (
															<Fragment>
																<DropdownItem
																	className="pointered"
																	onClick={(e) =>
																		this.onDeleteUser({ user }, e)
																	}
																>
																	<i className="fas fa-trash-alt fa-fw mr-2" />
																	Delete
																</DropdownItem>
															</Fragment>
														)}
														{!enabled && !allowToDelete && (
															<Fragment>
																<DropdownItem
																	disabled
																	className="pointered"
																	onClick={(e) =>
																		this.onDeleteUser({ user }, e)
																	}
																>
																	<i className="fas fa-trash-alt fa-fw mr-2" />
																	Delete
																</DropdownItem>
															</Fragment>
														)}
													</DropdownMenu>
												</UncontrolledDropdown>
											</td>
										</tr>
									);
								})}
							</tbody>
						</Table>
					</Col>
				)}
			</Row>
		);
	}

	buildModals() {
		const {
			approveUserModal,
			updateUserRoleModal,
			userInfoModal,
			denyUserModal,
			deleteModal,
			deleteUser,
			groupsModal,
			groupsUser,
			approveThisUser,
			thisUser
		} = this.state;

		// var approvedDate = '';
		// var approvedBy = '';
		// var statusChangedDate = '';

		// if (approveThisUser.hasOwnProperty('userCreateDate')) {
		// 	approvedDate = approveThisUser['userCreateDate'].substring(0, 10);
		// }

		// if (approveThisUser.hasOwnProperty('statusChangeDate')) {
		// 	statusChangedDate =
		// 		approveThisUser['statusChangeDate'].substring(11, 19) +
		// 		'  ' +
		// 		approveThisUser['statusChangeDate'].substring(0, 10);
		// } else {
		// 	statusChangedDate = 'never been modified';
		// }

		// if (!approveThisUser.hasOwnProperty('approvedBy')) {
		// 	approvedBy = 'unknown';
		// } else {
		// 	approvedBy = approveThisUser['approvedBy'];
		// }

		console.log(thisUser);
		console.log(approveThisUser)
		console.log(approveUserModal)

		return (
			<Fragment>
				{/* <Modal
					isOpen={userInfoModal}
					toggle={this.toggleUserInfoModal}
					size="lg"
				>
					<ModalHeader toggle={this.toggleUserInfoModal}>
						<i title="User" className="fa-solid fa-user pr-3" />
						{thisUser.givenName} {thisUser.familyName} info
						<p>
							<small>{thisUser.username}</small>
						</p>
					</ModalHeader>

					<ModalBody>
						<Row className="py-2">
							<Col>
								<Label for="userInfoFirstName">First Name</Label>
								<Input
									id="userInfoFirstName"
									type="text"
									disabled
									value={thisUser.givenName}
								/>
							</Col>

							<Col>
								<Label for="userInfoLastName">Last Name</Label>
								<Input
									id="userInfoLastName"
									type="text"
									disabled
									value={thisUser.familyName}
								/>
							</Col>

							<Col>
								<Label for="userInfoOrganisation">Organisation</Label>
								<Input
									id="userInfoOrganisation"
									type="text"
									disabled
									value={thisUser.organisation}
								/>
							</Col>
						</Row>

						<Row className="py-2">
							<Col>
								<Label for="userInfoEmail">Email</Label>
								<Input
									id="userInfoEmail"
									type="text"
									disabled
									value={thisUser.email}
								/>
							</Col>

							<Col>
								<Label for="userInfoEmailVerified">Email Verified</Label>
								<Input
									id="userInfoEmailVerified"
									type="checkbox"
									disabled
									value={thisUser.emailVerified === 'true'}
								/>
							</Col>
						</Row>

						<Row className="py-2">
							<Col>
								<Label for="userInfoUserRole">User Role</Label>
								<Input
									id="userInfoUserRole"
									type="text"
									disabled
									value={thisUser.userRole}
								/>
							</Col>

							<Col>
								<Label for="userInfoUserStatus">Status</Label>
								<Input
									id="userInfoUserStatus"
									type="text"
									disabled
									value={thisUser.userStatus}
								/>
							</Col>

							<Col>
								<Label for="userInfoUserStatusChangeDate">
									Status Change Date
								</Label>
								<Input
									id="userInfoUserStatusChangeDate"
									type="text"
									disabled
									value={thisUser.statusChangeDate}
								/>
							</Col>

							<Col>
								<Label for="userInfoEnabled">Enabled</Label>
								<Input
									id="userInfoEnabled"
									type="checkbox"
									disabled
									value={thisUser.enabled}
								/>
							</Col>
						</Row>
					</ModalBody>

					<ModalFooter>
						<Button
							color="secondary"
							size="sm"
							type="button"
							onClick={this.toggleUserInfoModal}
						>
							Cancel
						</Button>
					</ModalFooter>
				</Modal> */}

<Modal
					isOpen={approveUserModal}
					toggle={this.toggleApproveUserModal}
					size="lg"
				>
					<ModalHeader toggle={this.toggleApproveUserModal}>
						<i className="fa-solid fa-check pr-3" />
						Approve a User
					</ModalHeader>

					<Row>
						<Col className="px-5">
							<Formik
								initialValues={{
									email: approveThisUser['email'],
									id: approveThisUser['username'],
									userRole: 'user'
								}}
								validate={(values) => {
									const errors = {};
									var mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
									if (values.email.trim().length === 0) {
										errors.email = 'An email is required';
									} else if (values.email.trim().length > 256) {
										errors.email =
											'Email should be no more than 256 characters';
									} else if (!values.email.trim().match(mailformat)) {
										errors.email = 'Email does not match standard email format';
									}
									return errors;
								}}
								onSubmit={(values, { setSubmitting }) => {
									this.onApproveUserAction({ values, setSubmitting });
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									submitForm,
									isSubmitting,
									setFieldValue
								}) => (
									<Form onSubmit={handleSubmit}>
										<ModalBody>
											<FormGroup>
												<Label for="email">Email</Label>
												<Input
													id="email"
													placeholder="Enter email"
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.email && touched.email}
													disabled={true}
												/>
												<FormFeedback>{errors.email}</FormFeedback>
											</FormGroup>
										</ModalBody>
										<ModalFooter>
											<Button
												id="user"
												className="m-2"
												color="info"
												size="sm"
												type="submit"
												disabled={isSubmitting}
											>
												Verify email
											</Button>

											<Button
												color="secondary"
												size="sm"
												type="button"
												onClick={this.toggleApproveUserModal}
											>
												Cancel
											</Button>
										</ModalFooter>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</Modal>

				<Modal
					isOpen={denyUserModal}
					toggle={this.toggleRejectUserModal}
					size="lg"
				>
					<ModalHeader toggle={this.toggleRejectUserModal}>
						<i className="fa-solid fa-xmark pr-3" />
						Reject this user
					</ModalHeader>

					<Row>
						<Col className="px-5">
							<Formik
								initialValues={{
									email: approveThisUser['email']
								}}
								validate={(values) => {
									const errors = {};
									var mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
									if (values.email.trim().length === 0) {
										errors.email = 'An email is required';
									} else if (values.email.trim().length > 256) {
										errors.email =
											'Email should be no more than 256 characters';
									} else if (!values.email.trim().match(mailformat)) {
										errors.email = 'Email does not match standard email format';
									}
									return errors;
								}}
								onSubmit={(values, { setSubmitting }) => {
									this.onRejectUserAction({ values, setSubmitting });
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									submitForm,
									isSubmitting,
									setFieldValue
								}) => (
									<Form onSubmit={handleSubmit}>
										<ModalBody>
											<FormGroup>
												<Label for="email">Email</Label>
												<Input
													id="email"
													placeholder="Enter email"
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.email && touched.email}
													disabled={true}
												/>
												<FormFeedback>{errors.email}</FormFeedback>
											</FormGroup>
										</ModalBody>
										<ModalFooter>
											<Button
												id="user"
												className="m-2"
												color="info"
												size="sm"
												type="submit"
												disabled={isSubmitting}
											>
												Deny this user access
											</Button>

											<Button
												color="secondary"
												size="sm"
												type="button"
												onClick={this.toggleRejectUserModal}
											>
												Cancel
											</Button>
										</ModalFooter>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</Modal>

				<Modal
					isOpen={updateUserRoleModal}
					toggle={this.toggleUpdateUserRoleModal}
					size="lg"
				>
					<ModalHeader toggle={this.toggleUpdateUserRoleModal}>
						<i title="User" className="fa-solid fa-user pr-3" />
						Change user role and status
					</ModalHeader>

					<Row>
						<Col className="px-5">
							<Formik
								initialValues={{
									email: approveThisUser['email'],
									userRole: approveThisUser['userRole'],
									enabled: approveThisUser['enabled'],
									givenName: approveThisUser['givenName'],
									familyName: approveThisUser['familyName'],
									signUpSource: approveThisUser['signUpSource'],
									organisation: approveThisUser['organisation'],
									signUpDate: approveThisUser['userCreateDate'],
									approvedBy: approveThisUser['approvedBy'],
									emailVerified: approveThisUser['emailVerified'],
									statusChangeDate: approveThisUser['statusChangeDate'],
									userCreateDate: approveThisUser['userCreateDate'],
									userLastModifiedDate: approveThisUser['userLastModifiedDate'],
									username: approveThisUser['username'],
									userStatus: approveThisUser['userStatus']
								}}
								validate={(values) => {
									const errors = {};
									var mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
									if (values.email.trim().length === 0) {
										errors.email = 'An email is required';
									} else if (values.email.trim().length > 256) {
										errors.email =
											'Email should be no more than 256 characters';
									} else if (!values.email.trim().match(mailformat)) {
										errors.email = 'Email does not match standard email format';
									}

									console.log(values);

									return errors;
								}}
								onSubmit={(values, { setSubmitting }) => {
									this.onUpdateUserRoleAction({ values, setSubmitting });
								}}
							>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									submitForm,
									isSubmitting,
									setFieldValue
								}) => (
									<Form onSubmit={handleSubmit}>
										<ModalBody>
											<FormGroup>
												<Row>
													<Col>
														<Label
															className="font-weight-bold pt-2"
															for="firstName"
														>
															First Name
														</Label>
														<Input
															id="firstName"
															value={values.givenName}
															disabled={true}
														></Input>
													</Col>
													<Col>
														<Label
															className="font-weight-bold pt-2"
															for="lastName"
														>
															Last Name
														</Label>
														<Input
															id="lastName"
															value={values.familyName}
															disabled={true}
														></Input>
													</Col>
													<Col>
														<Label
															className="font-weight-bold pt-2"
															for="organisation"
														>
															Organisation
														</Label>
														<Input
															id="organisation"
															value={values.organisation}
															disabled={true}
														></Input>
													</Col>
												</Row>

												{values.emailVerified ? (
													<Label
														title="Email verified"
														className="font-weight-bold pt-2"
														for="email"
													>
														Email <i className="fas fa-check text-success" />
													</Label>
												) : (
													<Label
														title="Email not verified"
														className="font-weight-bold pt-2"
														for="email"
													>
														Email
													</Label>
												)}

												<Input
													id="email"
													placeholder="Enter email"
													value={values.email}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.email && touched.email}
													disabled={true}
												/>
												<FormFeedback>{errors.email}</FormFeedback>
												<FormText>
													originally approved by <b>{values.approvedBy}</b> on{' '}
													<b>{values.userCreateDate}</b>
												</FormText>
												{values.signUpSource && (
													<FormText>
														signed up via <b>{values.signUpSource}</b>
													</FormText>
												)}
											</FormGroup>
											<FormGroup>
												<Label className="font-weight-bold pt-2">Enabled</Label>
												<Row className="px-4">
													<Col>
														<Input
															type="radio"
															name="enabledRadio"
															id="enabled"
															value="true"
															onChange={() => setFieldValue('enabled', 'true')}
															onBlur={handleBlur}
															defaultChecked={values.enabled === true}
															bsSize="sm"
															title="enabled users can log in to the Admin tool"
														/>
														<Label
															className="pt-2"
															title="enabled users can log in to the Admin tool"
														>
															<i className="fas fa-check text-success" />{' '}
															Enabled
														</Label>
													</Col>
													<Col>
														<Input
															type="radio"
															name="enabledRadio"
															id="enabled"
															value="false"
															onChange={() => setFieldValue('enabled', 'false')}
															onBlur={handleBlur}
															defaultChecked={values.enabled === false}
															bsSize="sm"
															title="disabled users cannot log in to the Admin tool"
														/>
														<Label
															className="pt-2"
															title="disabled users cannot log in to the Admin tool"
														>
															<i className="fas fa-times text-danger" />{' '}
															Disabled
														</Label>
													</Col>
													<Col>
														<FormText>status last updated:</FormText>
														<FormText>{values.statusChangeDate}</FormText>
													</Col>
												</Row>
											</FormGroup>

											<FormGroup>
												<Label className="font-weight-bold pt-2">
													User role
												</Label>
												<Row>
													<Col className="d-flex justify-content-center">
														<div>
															<div className="d-flex justify-content-center">
																<Input
																	type="radio"
																	name="userRole"
																	className="updateUserRoleRadio"
																	id="user"
																	value="user"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	defaultChecked={values.userRole === 'user'}
																	bsSize="sm"
																/>
																<Label
																	className="updateUserRoleLabel"
																	for="user"
																>
																	<i
																		title="User"
																		className="fa-solid fa-user"
																	/>
																</Label>
															</div>
															<p className="userTypeText">
																Users are denied access to the Admin Tool
															</p>
														</div>
													</Col>
													<Col className="d-flex justify-content-center">
														<div>
															<div className="d-flex justify-content-center">
																<Input
																	className="updateUserRoleRadio"
																	type="radio"
																	name="userRole"
																	id="superuser"
																	value="superUser"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	defaultChecked={
																		values.userRole === 'superUser'
																	}
																	bsSize="sm"
																/>
																<Label
																	className="updateUserRoleLabel"
																	for="superuser"
																>
																	<i
																		title="Superuser"
																		className="fa-solid fa-user-shield"
																	/>
																</Label>
															</div>
															<p className="userTypeText">
																SuperUsers are denied access to the Admin Tool
															</p>
														</div>
													</Col>
													<Col className="d-flex justify-content-center">
														<div>
															<div className="d-flex justify-content-center">
																<Input
																	className="updateUserRoleRadio"
																	type="radio"
																	name="userRole"
																	id="admin"
																	value="admin"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	defaultChecked={values.userRole === 'admin'}
																	bsSize="sm"
																/>
																<Label
																	className="updateUserRoleLabel"
																	for="admin"
																>
																	<i
																		title="Admin"
																		className="fa-solid fa-user-lock"
																	/>
																</Label>
															</div>
															<p className="userTypeText">
																Admin users can read and modify site and user
																data
															</p>
														</div>
													</Col>
													<Col>
														<div>
															<div className="d-flex justify-content-center">
																<Input
																	className="updateUserRoleRadio"
																	type="radio"
																	name="userRole"
																	id="editor"
																	value="editor"
																	onChange={handleChange}
																	onBlur={handleBlur}
																	defaultChecked={values.userRole === 'editor'}
																	bsSize="sm"
																/>
																<Label
																	className="updateUserRoleLabel"
																	for="editor"
																>
																	<i
																		title="Editor"
																		className="fa-solid fa-user-pen"
																	/>
																</Label>
															</div>
															<p className="userTypeText">
																Editors can read and modify site data
															</p>
														</div>
													</Col>
												</Row>
												<Row>
													<p>
														<small>
															user last updated on {values.userLastModifiedDate}
														</small>
													</p>
												</Row>
											</FormGroup>

											<FormGroup>
												<Label className="font-weight-bold">User status</Label>
												<Row className="px-4">
													{values.userStatus === 'CONFIRMED' ? (
														<Col>
															<Input
																type="radio"
																name="statusConfirmedRadio"
																id="statusConfirmed"
																defaultChecked={
																	values.userStatus === 'CONFIRMED'
																}
																bsSize="sm"
																title="user is valid from cognito side"
																disabled
															/>
															<Label
																className="pt-2"
																title="user is valid from cognito side"
															>
																<i className="fas fa-check text-success" />{' '}
																Confirmed
															</Label>
														</Col>
													) : (
														<Col>
															<Input
																type="radio"
																name="statusConfirmedRadio"
																id="statusConfirmed"
																defaultChecked={
																	values.userStatus === 'CONFIRMED'
																}
																bsSize="sm"
																title="user is not valid from cognito side"
																disabled
															/>
															<Label
																className="pt-2"
																title="user is not valid from cognito side"
															>
																<i className="fas fa-times text-danger" /> Not
																confirmed
															</Label>
														</Col>
													)}
												</Row>
											</FormGroup>
										</ModalBody>
										<ModalFooter>
											<Button
												id="user"
												className="m-2"
												color="info"
												size="sm"
												type="submit"
												disabled={isSubmitting}
											>
												Change user role
											</Button>

											<Button
												color="secondary"
												size="sm"
												type="button"
												onClick={this.toggleUpdateUserRoleModal}
											>
												Cancel
											</Button>
										</ModalFooter>
									</Form>
								)}
							</Formik>
						</Col>
					</Row>
				</Modal>

				<Modal isOpen={deleteModal} toggle={this.toggleDelete}>
					<ModalHeader toggle={this.toggleDelete}>
						<i className="fas fa-trash-alt mr-3" />
						Delete a user
					</ModalHeader>
					<ModalBody>
						<p>
							Please confirm that you really want to delete user '
							{deleteUser.email}'?
						</p>
						<p className="small text-muted">
							A deleted user cannot be recovered.
						</p>
						<p className="small text-danger">PROCEED WITH CAUTION!</p>
					</ModalBody>
					<ModalFooter>
						<Button color="primary" size="sm" onClick={this.onDeleteUserAction}>
							Delete user
						</Button>{' '}
						<Button color="secondary" size="sm" onClick={this.toggleDelete}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}

	render() {
		//const { typeFilter } = this.state;
		const title = 'Users';

		return (
			<DocumentTitle title="Gains Admin | Users">
				<Container fluid>
					<Crumbs path={[{ title }]} />
					{this.buildResultsTable()}
					{this.buildModals()}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ users, user }) => {
	return {
		users,
		user
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchUsers: () => dispatch(fetchUsers()),
	deleteUser: (data) => dispatch(deleteUser(data)),
	approveUser: (data) => dispatch(approveUser(data)),
	updateUser: (data) => dispatch(updateUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Users);
