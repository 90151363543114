import React from 'react';
import { Route, Switch } from 'react-router-dom';

import { ForgotPassword, Home, Login, NotFound, Groups, GroupType, Regions, Sites, SignUp, Users, ApproveUser, Countries } from 'screens';
import { AppliedRoute } from 'components';

export default ({ childProps }) => (
	<Switch>
		<AppliedRoute path="/" exact component={Login} props={childProps} noTemplate={true} insecure={true} />
		<AppliedRoute
			path="/forgotPassword"
			exact
			component={ForgotPassword}
			props={childProps}
			noTemplate={true}
			insecure={true}
		/>
		<AppliedRoute path="/home" exact component={Home} props={childProps} />
		<AppliedRoute path="/countries" exact component={Countries} props={childProps} />
		<AppliedRoute path="/group" exact component={Groups} props={childProps} />
		<AppliedRoute path="/site" exact component={Sites} props={childProps} />
		<AppliedRoute path="/region" exact component={Regions} props={childProps} />
		<AppliedRoute path="/signUp" exact component={SignUp} props={childProps} noTemplate={true} insecure={true} />
		<AppliedRoute path="/users" exact component={Users} props={childProps} />
		<AppliedRoute path="/grouptype" exact component={GroupType} props={childProps} />
		<AppliedRoute path="/approveUser" exact component={ApproveUser} props={childProps} />
		<Route component={NotFound} />
	</Switch>
);
