import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';

import {
	Button,
	Card,
	CardBody,
	CardHeader,
	Col,
	Container,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row
} from 'reactstrap';

import { fetchRegions, fetchSites, fetchGroups, fetchUsers, fetchCountries } from 'actions';
import { Crumbs } from 'containers';

import './Home.scss';

class Home extends Component {
	state = {
		helpModal: false
	};

	componentDidMount() {
		this.props.fetchRegions();
		this.props.fetchSites();
		this.props.fetchGroups();
		this.props.fetchUsers();
		this.props.fetchCountries();
	}

	toggleHelp = () => {
		this.setState((prevState) => ({
			helpModal: !prevState.helpModal
		}));
	};

	buildModals() {
		const { helpModal } = this.state;

		return (
			<Fragment>
				<Modal isOpen={helpModal} toggle={this.toggleHelp}>
					<ModalHeader toggle={this.toggleHelp}>
						<i className="fas fa-medkit mr-3" />
						Help me
					</ModalHeader>
					<ModalBody>
						<p>
							The Gains Admin platform is being actively developed and it should
							not be considered ready for production use until advised
							otherwise.
						</p>
						<p className="text-center">
							<i className="far fa-grin-wink fa-lg mr-2" /> Please come back
							soon!
						</p>
					</ModalBody>
					<ModalFooter>
						<Button color="secondary" size="sm" onClick={this.toggleHelp}>
							Close
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}

	render() {
		const { regions = [], sites = [], Groups = [], countries = [], users = [] } = this.props;
		console.log(this)

		return (
			<DocumentTitle title="Gains Admin | Dashboard">
				<Container fluid>
					<Crumbs />
					<Row noGutters>
						<Col xs="4" sm="5" md="5" lg="7" xl="8" className="pr-3">
							<Card>
								<CardBody className="p-2">
									<p>
										The <strong>Gains Admin Platform</strong> provides an
										interface through which Gains content can be managed and
										maintained.
									</p>
									<p>
										You may wish to review{' '}
										<NavLink to="/site">
											<strong>Sites</strong>
										</NavLink>
										, or{' '}
										<NavLink to="/group">
											<strong>Groups</strong>
										</NavLink>
										, or{' '}
										<NavLink to="/region">
											<strong>Regions</strong>
										</NavLink>{' '}
										or{' '}
										<NavLink to="/users">
											<strong>Users</strong>
										</NavLink>{' '}
										using the menu options to the left.
									</p>
									<p>
										<strong>
											Please be aware that when creating or editing data in this
											platform, this is reflected in the Gains production
											database.
										</strong>
									</p>
								</CardBody>
							</Card>
						</Col>
						<Col xs="8" sm="7" md="7" lg="5" xl="4">
							<Card className="mb-3">
								<CardHeader className="position-relative">
									<h6 className="mb-0" style={{ marginTop: '4px' }}>
										System status
									</h6>
									<Button
										color="secondary"
										size="sm"
										className="position-absolute"
										style={{ top: '8px', right: '8px' }}
										onClick={(e) => this.toggleHelp()}
									>
										<i className="fas fa-medkit mr-2" /> Help me
									</Button>
								</CardHeader>
								<CardBody className="p-2">
									<p className="ml-2 mb-0 small">
										{/* <i className="fas fa-heartbeat mr-2 fa-fw" />
										Health:{' '}
										<span className="text-success font-weight-bold">Good</span>
										<br /> */}
										<i className="fa-solid fa-fire-flame-simple mr-2" />
										Sites:{' '}
										<span className="font-weight-bold">{sites.length}</span>
										<br />
										<i className="fa-solid fa-sitemap mr-2" />
										Groups:{' '}
										<span className="font-weight-bold">{Groups.length}</span>
										<br />
										<i className="fa-solid fa-globe-americas mr-2" />
										Regions:{' '}
										<span className="font-weight-bold">{regions.length}</span>
										<br />
										<i className="fa-solid fa-globe mr-2" />
										Countries:{' '}
										<span className="font-weight-bold">{countries.length}</span>
										<br />
										<i className="fa-solid fa-user-group mr-2" />
										Users:{' '}
										<span className="font-weight-bold">{users.length}</span>
										<br />

										{/* <i className="fas fa-user-friends mr-2 fa-fw" />Users (staging):{' '}
										<span className="font-weight-bold">{usersStaging.length}</span>
										<br />
										<i className="fas fa-user-friends mr-2 fa-fw" />Users (production):{' '}
										<span className="font-weight-bold">{usersProduction.length}</span> */}
									</p>
								</CardBody>
							</Card>
						</Col>
					</Row>
					{this.buildModals()}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ regions, sites, Groups, countries, users }) => {
	return {
		regions, // redux
		sites, // redux
		Groups, // redux
		countries, // redux
		users, // redux
		// usersStaging, // redux
		// usersProduction // redux
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchRegions: () => dispatch(fetchRegions()),
	fetchSites: () => dispatch(fetchSites()),
	fetchGroups: () => dispatch(fetchGroups()),
	fetchUsers: () => dispatch(fetchUsers()),
	fetchCountries: () => dispatch(fetchCountries())
	// fetchUsersStaging: () => dispatch(fetchUsersStaging()),
	// fetchUsersProduction: () => dispatch(fetchUsersProduction())
});

export default connect(mapStateToProps, mapDispatchToProps)(Home);
