// React imports
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import DocumentTitle from 'react-document-title';
import { CSVLink } from 'react-csv';
import {
	Button,
	Col,
	Container,
	CustomInput,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormFeedback,
	Form,
	FormGroup,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
	UncontrolledDropdown
} from 'reactstrap';

// Local imports
import {
	fetchUsers,
	fetchRegions,
	fetchSites,
	createSite,
	updateSite,
	deleteSite,
	fetchCountries,
	fetchSiteTypes,
	fetchSubsiteInfo,
	fetchCommodities,
	fetchSiteInfo,
	getAuthenticatedUserAttributes,
	fetchSiteGroups
} from 'actions';
import { Crumbs } from 'containers';
import config from 'config';
import {
	calcAOIArea,
	disableMap,
	geojsonioJSONFormat,
	isAOIValid,
	mapLayerGeoJSONFormat,
	mapLayerPointFormat,
	stringifyGeoJSON,
	subsiteAOIWithinParentAOI,
	uploadedFileIsGeojson,
	uploadedFileIsKML
} from './Helpers';

// Map imports - don't remove these or map won't appear
import { area, centerOfMass, polygon } from '@turf/turf';
import ReactMapboxGl, { Layer, Source } from 'react-mapbox-gl';
import DrawControl from 'react-mapbox-gl-draw';

import MapboxDraw from '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import mapboxGlDraw from 'mapbox-gl-draw';
import { map } from 'jquery';
import 'mapbox-gl/src/css/mapbox-gl.css';

import './Sites.scss';
import mapboxgl from 'mapbox-gl';
import { Popup } from 'react-map-gl';

mapboxgl.workerClass =
	require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default; // eslint-disable-line

const Map = ReactMapboxGl({
	accessToken: config.mapboxToken
});

class Sites extends Component {
	state = {
		// users
		isAdmin: '',

		// modals for CRUD
		createModal: false,
		createSubsiteModal: false,
		updateModal: false,
		updateSite: {},
		deleteModal: false,
		deleteSite: {},

		// filters
		typeFilter: '',
		nameFilter: '',
		idFilter: '',
		siteTypeFilter: '',
		countryFilter: '',
		commodityFilter: '',
		plantTypeFilter: '',
		parentSiteFilter: '',

		// drawing a polygon
		drawAOI: 'Draw a polygon on the map to create an AOI',
		drawArea: '',
		drawLat: '',
		drawLong: '',
		currentZoom: 6,
		drawCountry: '',
		drawRegion: '',

		// create site options (draw, upload)
		aoi_input: 'aoi_map',
		mapDisabled: false,
		AOIFieldDisabled: true,
		uploadFieldDisabled: true,
		fileUpload: '',
		kml_geojson: '',
		pasted_coordinates: '',

		// subsites
		parentId: null,
		parentAOI: null,
		subsitesAOIS: {},
		parentSiteInfo: {},

		// show all sites on Map
		drawStyle: 'light-v11',
		mapboxJsons: '',
		listMapLat: 38,
		listMapLong: 11,
		listMapZoom: [4],
		listMapGeoJSON: {},

		// selected site on Map
		setSelectedSite: '',
		selectedSiteLat: '',
		selectedSiteLong: '',
		drawLayer: {}
	};

	clearFilters = () => {
		/**
		 *	clear all site filters to display list of all sites
		 */

		document.getElementById('nameFilter').value = '';
		document.getElementById('countryFilter').value = '';
		document.getElementById('idFilter').value = '';
		document.getElementById('commodityFilter').value = '';
		document.getElementById('siteTypeFilter').value = '';

		this.setState({
			typeFilter: '',
			nameFilter: '',
			idFilter: '',
			siteTypeFilter: '',
			countryFilter: '',
			commodityFilter: ''
		});
	};

	componentDidMount() {
		this.props.fetchRegions();
		this.props.fetchSites();
		this.props.fetchCountries();
		this.props.fetchSiteTypes();
		this.props.fetchCommodities();
		this.updateUserState();
	}

	async updateUserState() {
		/**
		 * determine if current logged in user is an Admin/Superuser
		 */

		const { email } = await getAuthenticatedUserAttributes();
		let currentUser;
		let users = await this.props.fetchUsers();

		for (let user in users.payload) {
			if (users.payload[user].email === email) {
				currentUser = users.payload[user];

				if (
					currentUser.userRole === 'superUser' ||
					currentUser.userRole === 'admin'
				) {
					this.setState({
						isAdmin: true
					});
				} else {
					this.setState({
						isAdmin: false
					});
				}
			}
		}
	}

	onReloadList = () => {
		this.props.fetchSites();
	};

	handleChange = (event) => {
		let currLat = this.state.drawLat;
		let currLong = this.state.drawLong;

		if (
			config.mapbox_styles
				.filter((style) => style.url)
				.includes(event.target.value)
		) {
			console.log('changing style');
		}

		var latlongtext = document.getElementById('latlonginfo');
		for (let i in config.mapbox_styles) {
			if (config.mapbox_styles[i]['url'] === event.target.value) {
				if (latlongtext) {
					latlongtext.style.color = config.mapbox_styles[i]['text'];
				}
			}
		}

		if (event.target.name === 'countryFilter') {
			if (event.target.value !== '') {
				try {
					let countryLatLong = config.country_centroids.filter(
						({ name }) => name === event.target.value
					)[0]['latlong'];

					this.setState({
						listMapLat: countryLatLong[1],
						listMapLong: countryLatLong[0],
						listMapZoom: [4]
					});
				} catch (e) {
					console.log('cannot filter by this country');
				}
			}
		}

		if (event.target.name === 'coordinatesPaste') {
			//console.log(event.target.value)
			//this.getAOIInputInfo(event.target.value)

			const myArray = event.target.value.split('\n');
			//onsole.log(myArray);
			let coords2 = [];

			for (let i in myArray) {
				let x = myArray[i].split(',');

				//x = x.slice(0, -1);

				let y = [];
				for (let j in x) {
					y.push(Number(x[j]));
				}

				coords2.push(y);
			}
			console.log(coords2);

			let geojson = {
				type: 'FeatureCollection',
				features: [
					{
						type: 'Feature',
						geometry: { type: 'Polygon', coordinates: [coords2] }
					}
				]
			};

			const gjv = require('geojson-validation');
			if (gjv.valid(geojson)) {
				console.log('this is valid GeoJSON!');
			} else {
				console.log('invalid');
			}
			// console.log(coords2);

			// let geojson = {
			// 	type: 'geojson',
			// 	data: {
			// 		type: 'Feature',
			// 		geometry: {
			// 			type: 'Polygon',
			// 			coordinates: [coords2]
			// 		}
			// 	}
			// };

			// this.setState({
			// 	pasted_coordinates: geojson
			// });
		}

		this.highlightFilterFields(event.target.id, event.target.value);

		this.setState({
			[event.target.name]: event.target.value,
			[event.target.id]: event.target.value
		});
	};

	highlightFilterFields = (targetId, targetValue) => {
		/**
		 * Change style of filters which have been selected
		 * or remove style if they are deselected
		 */

		if (targetValue === '') {
			document.getElementById(targetId).classList.remove('selected-filter');
		} else {
			document.getElementById(targetId).classList.add('selected-filter');
		}
	};

	// open or close modals
	toggleCreateSubsite = async (parentId) => {
		// if closing modal
		if (this.state.parentId) {
			this.setState({
				drawAOI: 'Draw a polygon on the map to create an AOI',
				drawArea: '',
				drawLat: '',
				drawLong: '',
				currentZoom: 7,
				drawCountry: '',
				drawRegion: '',
				kml_geojson: '',
				parentId: null,
				parentAOI: '',
				subsitesAOIS: [],
				parentSiteInfo: {}
			});

			this.setState((prevState) => ({
				createSubsiteModal: !prevState.createSubsiteModal
			}));

			// if opening modal
		} else {
			let parentSite = this.props.sites.filter(
				(site) => site.id === parentId.id
			)[0];

			let parentAOI = '';
			let lat = 43;
			let long = 1.2;

			if (Number.isInteger(parentId.id)) {
				await this.props.fetchSiteInfo({ id: parentId.id });
				parentAOI = this.props.siteInfo[0]['aoi'];
				console.log(parentAOI)
				lat = this.props.sites.filter((site) => site.id === parentId.id)[0].lat;
				long = this.props.sites.filter((site) => site.id === parentId.id)[0]
					.lon;
			}

			let subsites_aois = [];

			let siteSubsites = this.props.sites.filter(
				(sitez) => sitez.parent_id === parentId.id
			);
			for (let i in siteSubsites) {
				await this.props.fetchSiteInfo({ id: siteSubsites[i]['id'] });
				subsites_aois.push(this.props.siteInfo[0]);
			}

			this.setState((prevState) => ({
				createSubsiteModal: !prevState.createSubsiteModal
			}));

			try {
				var region_name =
					this.props.countries[
						this.props.countries.map((e) => e.name).indexOf(parentSite.country)
					]['region'];

				for (let i in this.props.regions) {
					if (this.props.regions[i]['name'] === region_name) {
						region = this.props.regions[i]['id'];
					}
				}
			} catch (err) {
				var region = '';
			}

			if (parentSite) {
				this.setState({
					drawAOI: 'Draw a polygon on the map to create an AOI',
					drawArea: '',
					drawLat: lat,
					drawLong: long,
					currentZoom: 7,
					drawCountry: '',
					drawRegion: '',
					kml_geojson: '',
					parentId: parentId.id,
					parentAOI: parentAOI,
					subsitesAOIS: subsites_aois,
					parentSiteInfo: {
						aoi: parentAOI,
						parent_id: parentId.id,
						country: parentSite.country,
						region: region,
						...parentSite
					}
				});
			}
		}
	};

	toggleCreate = () => {
		this.setState((prevState) => ({
			createModal: !prevState.createModal,
			drawAOI: 'Draw a polygon on the map to create an AOI',
			drawArea: '',
			drawLat: 43,
			drawLong: 1.2,
			currentZoom: 7,
			drawCountry: '',
			drawRegion: '',
			kml_geojson: ''
		}));
	};

	toggleUpdate = () => {
		this.setState((prevState) => ({
			updateModal: !prevState.updateModal,
			updateSite: {},
			fileUpload: '',
			kml_geojson: '',
			aoi_input: 'aoi_map',
			parentAOI: '',
			parentId: null,
			parentSiteInfo: ''
		}));
	};

	toggleDelete = () => {
		this.setState((prevState) => ({
			deleteModal: !prevState.deleteModal
		}));
	};

	// CRUD forms
	onCreateAction = async ({
		/**
		 * Gather parameters to Create Site and submit to API endpoint
		 */

		values: {
			createSiteId,
			createSiteName,
			createSiteCompany,
			createSiteType,
			createSiteCommodity,
			createSubSiteType,
			parent_id,
			prod_state,
			capacity,
			use_smoke,
			twin_id,
			start_date,
			end_date
		},
		setSubmitting
	}) => {
		if (createSiteType === 'airfield') {
			createSiteCommodity = 5;
		}

		var aoi_json = '';
		if (typeof this.state.kml_geojson === 'object') {
			aoi_json = {
				type: 'MultiPolygon',
				coordinates: [this.state.kml_geojson.data.geometry.coordinates]
			};
		} else {
			aoi_json = {
				type: 'MultiPolygon',
				coordinates: [JSON.parse(this.state.drawAOI).coordinates]
			};
		}

		let params = {};
		if (createSiteType !== 1) {
			params = {
				prod_state: parseInt(prod_state)
			};
		} else {
			params = {
				twin_id: parseInt(twin_id) || '',
				capacity: parseInt(capacity) || '',
				end_date: end_date,
				use_smoke: parseInt(use_smoke),
				prod_state: parseInt(prod_state),
				start_date: start_date,
				parent_id: parent_id
			};
		}

		// if creating new main site
		if (!this.state.parentId) {
			var submit_json = {
				id: createSiteId,
				name: createSiteName,
				aoi: aoi_json,
				params: {
					twin_id: parseInt(twin_id) || '',
					capacity: parseInt(capacity) || '',
					end_date: end_date,
					use_smoke: parseInt(use_smoke),
					prod_state: parseInt(prod_state),
					start_date: start_date
				},
				site_type_id: this.props.siteTypes.filter(
					(siteType) => siteType.name === createSiteType
				)[0]['id'],
				commodity_id: parseInt(createSiteCommodity),
				country_id: this.state.drawCountry,
				company: createSiteCompany
			};

			// if creating subsite
		} else {
			var submit_json = {
				id: createSiteId,
				name: createSiteName,
				aoi: aoi_json,
				params: {
					twin_id: parseInt(twin_id) || '',
					capacity: parseInt(capacity) || '',
					end_date: end_date,
					use_smoke: parseInt(use_smoke),
					prod_state: parseInt(prod_state),
					start_date: start_date,
					parent_id: parent_id
				},
				site_type_id: parseInt(createSubSiteType),
				country_id: this.props.countries.filter(
					(country) => country.name === this.state.parentSiteInfo.country
				)[0].id,
				company: createSiteCompany
			};
		}

		console.log(submit_json);

		// Insert the new site into the db
		await this.props.createSite(submit_json);

		setSubmitting(false);

		this.setState({
			createModal: false,
			createSubsiteModal: false,
			drawAOI: 'Draw a polygon on the map to create an AOI',
			drawArea: '',
			currentZoom: 6,
			drawCountry: '',
			drawRegion: '',
			drawLat: '',
			drawLong: '',
			parentId: null,
			parentSiteInfo: {},
			parentAOI: ''
		});
	};

	onUpdate = async ({ site }) => {
		await this.props.fetchSubsiteInfo({ id: site['id'] });

		await this.props.fetchSiteGroups({ id: site['id'] });
		let parentSite = this.props.sites.filter(
			(psite) => psite.id === site.parent_id
		)[0];

		// if updated subsite, collect parent site AOI
		if (site.parent_id != null) {
			await this.props.fetchSiteInfo({ id: site.parent_id });
			console.log(this.props.siteInfo)

			this.setState({
				parentAOI: this.props.siteInfo[0],
				parentId: parentSite.id,
				parentSiteInfo: {
					...parentSite,
					aoi: this.props.siteInfo[0].aoi
				}
			});
		}

		// if updating parent site, collect subsite AOIs
		let subsites_aois = [];
		if (site.parent_id === null) {
			for (let i in this.props.subsitesInfo) {
				subsites_aois.push(this.props.subsitesInfo[i]);
			}
		}

		await this.props.fetchSiteInfo({ id: site['id'] });

		console.log(this.props.siteInfo[0]['aoi'])

		// if the site has an AOI    // CHANGE HERE
		if (this.props.siteInfo[0]['aoi'] != null) {
			let AOIInfo = calcAOIArea(this.props.siteInfo[0]['aoi']['coordinates'][0]);

			this.setState({
				drawArea: AOIInfo['area'],
				drawLong: AOIInfo['long'],
				drawLat: AOIInfo['lat'],
				updateSite: site,
				drawAOI: JSON.stringify(this.props.siteInfo[0]['aoi'], undefined, 4),
				updateModal: true,
				drawCountry: this.props.countries.filter(
					({ name }) => name === site['country']
				)[0]['id'],
				drawRegion: this.props.regions.filter(
					({ name }) =>
						name ===
						this.props.countries.filter(
							({ name }) => name === site['country']
						)[0]['region']
				)[0]['id'],
				subsitesAOIS: subsites_aois
			});
		} else {
			this.setState({
				drawArea: '',
				drawLong: '43',
				drawLat: '1.2',
				updateSite: site,
				drawAOI: 'There is no AOI associated with this site.',
				updateModal: true
			});
		}

		// this.setState({
		// 	drawArea: AOIInfo['area'],
		// 	drawLong: AOIInfo['long'],
		// 	drawLat: AOIInfo['lat'],
		// 	updateSite: site,
		// 	drawAOI: draw_aoi,
		// 	updateModal: true,
		// 	//currentZoom: curZoom,
		// 	drawCountry: this.props.countries.filter(
		// 		({ name }) => name === site['country']
		// 	)[0]['id'],
		// 	drawRegion: this.props.regions.filter(
		// 		({ name }) =>
		// 			name ===
		// 			this.props.countries.filter(
		// 				({ name }) => name === site['country']
		// 			)[0]['region']
		// 	)[0]['id'],
		// 	subsitesAOIS: subsites_aois
		// });
	};

	onUpdateAction = async ({
		values: {
			updateSiteId,
			updateSiteName,
			updateSiteCompany,
			updateSiteType,
			updateSiteCommodity,
			prod_state,
			capacity,
			use_smoke,
			twin_id,
			start_date,
			end_date,
			plant_type
		},
		setSubmitting
	}) => {
		var updateSiteJSON;
		var aoi_json = {
			type: 'MultiPolygon',
			coordinates: JSON.parse(this.state.drawAOI).coordinates
		};

		// determine params for this site type
		let u = this.props.siteTypes
			.filter((siteType) => siteType.id === updateSiteType)[0]
			['template'].map((m) => {
				return m.name;
			});

		let params = {
			prod_state: parseInt(prod_state),
			capacity: parseInt(capacity) || '',
			use_smoke: parseInt(use_smoke),
			twin_id: parseInt(twin_id) || '',
			start_date: start_date,
			end_date: end_date,
			plant_type: plant_type
		};

		// remove params not relevant for this sitetype
		for (let key in params) {
			if (!u.includes(key)) {
				delete params[key];
			}
		}

		console.log(params);

		// updating subsite
		if (this.state.parentId != null) {
			updateSiteJSON = {
				id: updateSiteId,
				name: updateSiteName,
				aoi: aoi_json,
				params: params,
				site_type_id: parseInt(updateSiteType),
				country_id: this.state.drawCountry,
				company: updateSiteCompany,
				parent_id: this.state.parentId

				//commodity_id: this.state.parentSiteInfo['commodity_id']
			};

			// updating main site
		} else {
			updateSiteJSON = {
				id: updateSiteId,
				name: updateSiteName,
				aoi: aoi_json,
				params: params,
				site_type_id: parseInt(updateSiteType),
				commodity_id: parseInt(updateSiteCommodity),
				country_id: this.state.drawCountry,
				company: updateSiteCompany
			};
		}

		console.log(updateSiteJSON);
		// var updateSiteJSON;
		// var aoi_json = {
		// 	type: 'MultiPolygon',
		// 	coordinates: JSON.parse(this.state.drawAOI).coordinates
		// };

		// let params = {};
		// if (updateSiteType !== 1) {
		// 	params = {
		// 		prod_state: parseInt(prod_state)
		// 	};
		// } else {
		// 	params = {
		// 		twin_id: parseInt(twin_id) || '',
		// 		capacity: parseInt(capacity) || '',
		// 		end_date: end_date,
		// 		use_smoke: parseInt(use_smoke),
		// 		prod_state: parseInt(prod_state),
		// 		start_date: start_date,
		// 		plant_type: plant_type
		// 	};
		// }

		// // updating subsite
		// if(this.state.parentId != null) {
		// 	updateSiteJSON = {
		// 		id: updateSiteId,
		// 		name: updateSiteName,
		// 		aoi: aoi_json,
		// 		params: params,
		// 		site_type_id: parseInt(updateSiteType),
		// 		country_id: this.state.drawCountry,
		// 		company: updateSiteCompany,
		// 		parent_id: this.state.parentId,

		// 	//	commodity_id: this.state.parentSiteInfo['commodity_id']
		// 	};

		// // updating main site
		// } else {
		// 	updateSiteJSON = {
		// 		id: updateSiteId,
		// 		name: updateSiteName,
		// 		aoi: aoi_json,
		// 		params: params,
		// 		site_type_id: parseInt(updateSiteType),
		// 		commodity_id: parseInt(updateSiteCommodity) || '',
		// 		country_id: this.state.drawCountry,
		// 		company: updateSiteCompany,

		// 	};
		// }

		// console.log(updateSiteJSON);

		// Update the site in the db
		await this.props.updateSite(updateSiteJSON);
		setSubmitting(false);

		this.setState({
			kml_geojson: '',
			updateModal: false,
			updateSite: {},
			parentId: null,
			parentAOI: '',
			parentSiteInfo: ''
		});
	};

	onDelete = ({ site }) => {
		this.setState({
			deleteSite: site,
			deleteModal: true
		});
	};

	onDeleteAction = async () => {
		const {
			deleteSite: { id }
		} = this.state;

		// Delete the site in the db
		await this.props.deleteSite({
			id
		});

		this.setState({
			deleteModal: false,
			deleteSite: {}
		});
	};

	onDrawUpdate = (features_obj) => {
		/**
		 *
		 */

		var features;
		if (features_obj.hasOwnProperty('features')) {
			features = features_obj['features'];
		} else {
			features = features_obj;
		}

		document.getElementById('uploadFileButton').disabled = true;
		document.getElementById('uploadFileButton').cursor = 'not-allowed';
		document.getElementById('fileUpload').disabled = true;
		document.getElementById('uploadFileButton').style.cursor = 'not-allowed';
		document.getElementById('uploadFileButton').title =
			'Delete AOI drawn in map to upload file';
		document.getElementsByClassName(
			'mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon'
		)[0].disabled = true;

		var coords;

		console.log(features);

		// if creating new site
		if (Object.keys(this.state.updateSite).length === 0) {
			// 3 bracket coords for turf library
			coords = features[0]['geometry']['coordinates'];
			console.log(coords);
			let AOIInfo = calcAOIArea(coords);

			// if creating SUBSITE
			if (this.state.parentId != null) {
				let allPointsInParentAOI = subsiteAOIWithinParentAOI(
					coords,
					this.state.parentAOI
				);

				console.log(allPointsInParentAOI);

				if (allPointsInParentAOI && isAOIValid(coords)) {
					let AOIInfo = calcAOIArea(coords);

					this.setState({
						drawAOI: stringifyGeoJSON(features),
						drawArea: AOIInfo['area'],
						drawLat: AOIInfo['lat'],
						drawLong: AOIInfo['long']
					});
				} else {
					this.setState({
						drawAOI:
							'AOIs should be of a valid shape and have no intersecting lines, and all subsite points should be within the parent site AOI'
					});
				}

				// if creating PARENT site
			} else {
				if (isAOIValid(coords)) {
					this.getCountryAndRegion(AOIInfo['lat'], AOIInfo['long']);
					this.setState({
						drawAOI: stringifyGeoJSON(features),
						drawArea: AOIInfo['area'],
						drawLat: AOIInfo['lat'],
						drawLong: AOIInfo['long']
					});
				} else {
					this.setState({
						drawAOI:
							'AOIs should be of a valid shape and have no intersecting lines, and all subsite points should be within the parent site AOI'
					});
				}
			}

			// if updating site, drawing new AOI
		} else {
			// DELETED AOI and drawing new one
			if (features[0]['geometry']['coordinates'][0][0].length === 2) {
				coords = features[0]['geometry']['coordinates'];

				// if UPDATING drawn AOI
			} else {
				coords = features[0]['geometry']['coordinates'][0];
			}

			// updating SUBSITE
			if (this.state.parentId != null) {
				let allPointsInParentAOI = subsiteAOIWithinParentAOI(
					coords,
					this.state.parentAOI
				);

				if (allPointsInParentAOI && isAOIValid(coords)) {
					let AOIInfo = calcAOIArea(coords);
					this.setState({
						drawAOI: stringifyGeoJSON(features),
						drawArea: AOIInfo['area'],
						drawLat: AOIInfo['lat'],
						drawLong: AOIInfo['long']
					});
				} else {
					this.setState({
						drawAOI:
							'AOIs should be of a valid shape and have no intersecting lines, and all subsite points should be within the parent site AOI'
					});
				}

				// updating MAIN site
			} else {
				// if MAIN site HAS subsites
				if (this.state.subsitesAOIS.length > 0) {
					// rearrange mapbox polygon to match database polygon
					let parentPoly = {
						aoi: { coordinates: [coords] }
					};
					let allPointsInParentAOI = false;

					// check all points of redrawn parent AOI fall outwith subsites AOIs
					for (let i in this.state.subsitesAOIS) {
						let subcoords = this.state.subsitesAOIS[i]['aoi']['coordinates'][0];

						// if all points fall without
						if (subsiteAOIWithinParentAOI(subcoords, parentPoly)) {
							// check validity of drawn polygon
							if (isAOIValid(coords)) {
								let AOIInfo = calcAOIArea(coords);
								//this.getCountryAndRegion(AOIInfo['lat'], AOIInfo['long']);
								this.setState({
									drawAOI: stringifyGeoJSON(features),
									drawArea: AOIInfo['area'],
									drawLat: AOIInfo['lat'],
									drawLong: AOIInfo['long']
								});
							} else {
								this.setState({
									drawAOI:
										'AOIs should be of a valid shape and have no intersecting lines'
								});
							}

							// all points DO NOT fall without subsites AOIs
						} else {
							allPointsInParentAOI = false;
							this.setState({
								drawAOI: 'All coordinates should be outwith subsite AOIs'
							});
							break;
						}
					}

					// if MAIN site does NOT have subsites
				} else {
					if (isAOIValid(coords)) {
						let AOIInfo = calcAOIArea(coords);
						this.getCountryAndRegion(AOIInfo['lat'], AOIInfo['long']);
						this.setState({
							drawAOI: stringifyGeoJSON(features),
							drawArea: AOIInfo['area'],
							drawLat: AOIInfo['lat'],
							drawLong: AOIInfo['long']
						});
					} else {
						this.setState({
							drawAOI:
								'AOIs should be of a valid shape and have no intersecting lines'
						});
					}
				}
			}
		}
	};

	onDrawDelete = ({ features }) => {
		/**
		 * When a polygon on the map is deleted
		 */

		document.getElementById('fileUpload').disabled = false;
		document.getElementById('uploadFileButton').style.cursor = 'pointer';
		document.getElementById('uploadFileButton').title =
			'Upload a valid .kml or .geojson';
		document.getElementsByClassName(
			'mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon'
		)[0].disabled = false;
		document.getElementsByClassName(
			'mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon'
		)[0].style.backgroundColor = 'transparent';

		let aoiInputRadios = document.getElementsByName('aoi_input');
		for (let i = 0; i < aoiInputRadios.length; i++) {
			aoiInputRadios[i].disabled = false;
		}

		this.setState({
			drawAOI: 'Draw a polygon on the map to create an AOI',
			drawArea: ''
		});
	};

	zoomToSite = (site, event) => {
		window.scrollTo(0, 0);
		let thisSite = this.props.sites.filter(({ id }) => id === site.id)[0];

		if (thisSite.lat != null && thisSite.lon != null) {
			this.setState({
				listMapLat: thisSite.lat,
				listMapLong: thisSite.lon,
				listMapZoom: [13]
			});
		}
	};

	viewSiteDetailsInMap = (site, event) => {
		this.setState({
			setSelectedSite: site,
			listMapLat: site.lat,
			listMapLong: site.lon
		});
	};

	deleteFileUpload = () => {
		document.getElementsByClassName(
			'mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon'
		)[0].disabled = false;
		document.getElementById('fileUploadName').innerHTML = '';
		document.getElementById('uploadFileButton').disabled = false;
		document.getElementById('fileUpload').disabled = false;
		document.getElementById('fileUpload').value = null;
		document.getElementsByClassName(
			'mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash'
		)[0].disabled = false;
		document.getElementById('fileUploadedDiv').style.display = 'none';
		document.getElementById('fileUploadError').innerHTML = '';

		this.setState({
			fileUpload: null,
			kml_geojson: '',
			drawAOI: 'Draw a polygon on the map to create an AOI',
			drawArea: ''
		});
	};

	getCountryAndRegion = async (lat, long) => {
		/**
		 * If uploaded file is valid, determines country and region
		 * of AOI based on lat and long
		 */

		var country_name;
		var region;
		var country;

		const requestt = fetch(
			`https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?types=country&access_token=${config.mapboxToken}`
		)
			.then((response) => response.json())
			.then((data) => {
				console.log(data);
				country_name = data['features'][0]['place_name'];
				country = this.props.countries.filter(
					(country) => country.name === country_name
				)[0]['id'];

				try {
					var region_name =
						this.props.countries[
							this.props.countries.map((e) => e.name).indexOf(country_name)
						]['region'];
					region = this.props.regions.filter(
						(region) => region.name === region_name
					)[0]['id'];

					this.setState({
						drawCountry: country,
						drawRegion: region
					});
				} catch (e) {
					console.log(e);
					region = '';
					document.getElementById(
						'fileUploadError'
					).innerHTML = `AOIs cannot be created in this country (${country_name})`;
				}
			});
	};

	handleFileUpload = (event) => {
		/**
		 * Determines if file is geojson or kml
		 * Reads file and determines AOI data based on contents
		 * Sets state
		 */

		try {
			var kml_geojson, features_geojson;
			let file = event.target.files[0];

			this.setState({
				[event.target.name]: [event.target.files[0]]
			});

			// geojson file
			if (
				file['name'].slice(file['name'].length - 5, file['name'].length) ===
					'.json' ||
				file['name'].slice(file['name'].length - 8, file['name'].length) ===
					'.geojson'
			) {
				const reader = new FileReader();
				reader.onload = (event) => {
					try {
						let geojson = JSON.parse(event.target.result);
						[kml_geojson, features_geojson] = uploadedFileIsGeojson(geojson);

						console.log(kml_geojson);
						console.log(features_geojson);

						this.onDrawUpdate(features_geojson);
						disableMap(file['name']);

						// if file upload was valid
						if (this.state.drawArea != '') {
							this.setState({
								kml_geojson: kml_geojson
							});
						}
					} catch (e) {
						document.getElementById(
							'fileUploadError'
						).innerHTML = `${file['name']} is invalid and has not been uploaded.  Please upload a valid geojson.`;
						console.log(e);
					}
				};
				reader.readAsText(file);

				// kml file
			} else {
				const reader = new FileReader();
				reader.onload = (event) => {
					try {
						var XMLParser = require('react-xml-parser');
						var obj = new XMLParser().parseFromString(event.target.result);
						[kml_geojson, features_geojson] = uploadedFileIsKML(obj);

						this.onDrawUpdate(features_geojson);
						disableMap(file['name']);

						// if file upload was valid
						if (this.state.drawArea != '') {
							this.setState({
								kml_geojson: kml_geojson
							});
						}
					} catch (e) {
						document.getElementById(
							'fileUploadError'
						).innerHTML = `${file['name']} is invalid and has not been uploaded.  Please upload a valid kml.`;
						console.log(e);
					}
				};
				reader.readAsText(file);
			}

			// to catch when File Upload is picked but cancelled
		} catch (e) {
			console.log(e);
		}
	};

	downloadAOIFile = (event) => {
		/**
		 *
		 */

		var geojson;
		let fileData;
		let string = '';
		var fileName;
		var fileID;

		// if updating site
		if (Object.keys(this.state.updateSite).length > 0) {
			if (this.state.parentId) {
				geojson = JSON.parse(this.state.drawAOI)['coordinates'];
				fileName = this.state.updateSite['name'];
				fileID = this.state.updateSite['id'];
			} else {
				geojson = this.props.siteInfo[0]['aoi']['coordinates'];
				fileName = this.props.siteInfo[0]['name'];
				fileID = this.props.siteInfo[0]['id'];
			}
		}

		// if creating site
		else {
			geojson = JSON.parse(this.state.drawAOI)['coordinates'];
			fileName = 'unnamed site';
			fileID = 'unnamed id';
		}

		// if updating site
		if (Object.keys(this.state.updateSite).length > 0) {
			for (let i in geojson[0]) {
				for (let j in geojson[0][i]) {
					string = string + geojson[0][i][j] + ',0' + ' ';
				}
			}
			// if creating site
		} else {
			for (let i in geojson[0]) {
				for (let j in geojson[0][i]) {
					string = string + geojson[0][i][j] + ',';
				}
				string = string + '0 ';
			}
		}

		console.log(string);

		// downloading KML
		if (event.target.id === 'downloadKML') {
			//fileData = `<kml xmlns="http://www.opengis.net/kml/2.2"><Document><Placemark><ExtendedData></ExtendedData><Polygon><outerBoundaryIs><LinearRing><coordinates>${string}</coordinates></LinearRing></outerBoundaryIs></Polygon></Placemark></Document></kml>`;
			fileData = `<?xml version="1.0" encoding="UTF-8"?>
			<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
			<Document>
				<name>${fileName}</name>
				<Placemark>
					<name>${fileName}</name>
					<Polygon>
						<outerBoundaryIs>
							<LinearRing>
								<coordinates>
									${string}
								</coordinates>
							</LinearRing>
						</outerBoundaryIs>
					</Polygon>
				</Placemark>
			</Document>
			</kml>`;
			const blob = new Blob([fileData], { type: 'text/plain' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.download = `${fileName}_${fileID}_aoi_kml.kml`;
			link.href = url;
			link.click();

			// downloading geoJSON
		} else {
			if (Object.keys(this.state.updateSite).length > 0) {
				if (this.state.parentId) {
					fileData = geojsonioJSONFormat(
						JSON.parse(this.state.drawAOI)['coordinates'][0]
					);
				} else {
					fileData = geojsonioJSONFormat(
						this.props.siteInfo[0]['aoi']['coordinates'][0]
					);
				}
			} else {
				fileData = geojsonioJSONFormat(geojson);
			}

			const blob = new Blob([fileData], { type: 'text/plain' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.download = `${fileName}_${fileID}_aoi_geojson.json`;
			link.href = url;
			link.click();
		}
	};

	changeChevron = (event, ind) => {
		if (event.target.classList.contains('fa-caret-down')) {
			document.getElementById(`id${ind}`).classList.remove('fa-caret-down');
			document.getElementById(`id${ind}`).classList.add('fa-caret-up');
		} else {
			document.getElementById(`id${ind}`).classList.remove('fa-caret-up');
			document.getElementById(`id${ind}`).classList.add('fa-caret-down');
		}
	};

	createCSVDownloadOfSites = () => {
		var currentdate = new Date();
		var datetime =
			currentdate.getDate() +
			'/' +
			(currentdate.getMonth() + 1) +
			'/' +
			currentdate.getFullYear();

		// unpack 'params' object in sites object
		let csv_sites = [];
		let sites = this.props.sites;
		let commodities = this.props.commodities;

		if (
			sites.length > 0 &&
			commodities.length > 0 &&
			this.props.siteTypes.length > 0
		) {
			for (let i in sites) {
				let csv_site = {};

				if (sites[i].hasOwnProperty('params')) {
					for (let j in Object.keys(sites[i]['params'])) {
						csv_site[Object.keys(sites[i]['params'])[j]] =
							sites[i]['params'][Object.keys(sites[i]['params'])[j]];
					}
				}

				for (let k in Object.keys(sites[i])) {
					if (Object.keys(sites[i])[k] !== 'params') {
						if (Object.keys(sites[i])[k] === 'commodity_id') {
							csv_site[Object.keys(sites[i])[k]] =
								this.props.commodities.filter(
									({ id }) => id === sites[i][Object.keys(sites[i])[k]]
								)[0]['symbol'];
						} else if (Object.keys(sites[i])[k] === 'site_type_id') {
							csv_site[Object.keys(sites[i])[k]] = this.props.siteTypes.filter(
								({ id }) => id === sites[i][Object.keys(sites[i])[k]]
							)[0]['name'];
						} else {
							csv_site[Object.keys(sites[i])[k]] =
								sites[i][Object.keys(sites[i])[k]];
						}
					}
				}

				csv_sites.push(csv_site);
			}
		}

		return [datetime, csv_sites];
	};

	copyAOI = () => {
		var text;
		var fileData;

		// if updating site
		if (Object.keys(this.state.updateSite).length > 0) {
			text = JSON.parse(document.getElementById('updateSiteAOITextArea').value);
			document.getElementById('updateSiteAOITextArea').focus();
			document.getElementById('updateSiteAOITextArea').select();

			fileData = fileData = geojsonioJSONFormat(text['coordinates'][0]);

			// if creating site
		} else {
			text = JSON.parse(document.getElementById('createSiteAOITextArea').value);
			document.getElementById('createSiteAOITextArea').focus();
			document.getElementById('createSiteAOITextArea').select();

			fileData = geojsonioJSONFormat(text['coordinates']);
		}

		navigator.clipboard.writeText(fileData);
	};

	openInGeojsonIO = (event) => {
		var encodedJSON;
		console.log(event.target.id);
		if (event.target.id === 'updateSiteOpenInGeojson') {
			encodedJSON = encodeURIComponent(
				document.getElementById('updateSiteAOITextArea').value
			);
		} else {
			encodedJSON = encodeURIComponent(
				document.getElementById('createSiteAOITextArea').value
			);
		}

		let url = 'http://geojson.io/#data=data:application/json,';
		window.open(`${url}${encodedJSON}`);
	};

	// render HTML
	buildResultsTable() {
		let { sites = [], commodities, siteTypes, countries } = this.props;
		let plantTypes = ['BF/BOF', 'EAF'];
		const {
			siteTypeFilter,
			nameFilter,
			countryFilter,
			idFilter,
			commodityFilter,
			plantTypeFilter,
			parentSiteFilter,
			isAdmin
		} = this.state;

		let parent_sites = [];
		let sub_sites = [];

		// return lists of parent_sites and sub_sites
		for (let i in sites) {
			if (
				sites[i]['parent_id'] != null &&
				!parent_sites.includes(sites[i]['parent_id'])
			) {
				parent_sites.push(sites[i]['parent_id']);
			}

			if (sites[i]['parent_id'] != null) {
				sub_sites.push(sites[i]['id']);
			}
		}

		for (let i in sites) {
			sites[i]['subsites'] = [];
			if (sites[i]['parent_id'] === null) {
				for (let j in sites) {
					if (sites[j]['parent_id'] === sites[i].id) {
						sites[i]['subsites'].push(sites[j]);
					}
				}
			}
		}

		for (let i in sites) {
			if (sites[i].hasOwnProperty('params')) {
				if (sites[i]['params'].hasOwnProperty('plant_type')) {
					sites[i]['plant_type'] = sites[i]['params']['plant_type'];
				}
			}
		}

		// FILTERS
		if (plantTypeFilter !== '') {
			sites = sites.filter((site) => {
				return site.hasOwnProperty('plant_type');
			});

			sites = sites.filter(({ plant_type }) => {
				return plant_type.includes(plantTypeFilter);
			});
		}

		if (nameFilter !== '') {
			sites = sites.filter(({ name }) => {
				return name.toLowerCase().includes(nameFilter.toLowerCase());
			});
		}

		if (sub_sites.includes(parseInt(idFilter))) {
			let parentSite = sites.filter((site) => site.id === parseInt(idFilter))[0]
				.parent_id;

			sites = sites.filter(({ id }) => {
				return id.toString().includes(parentSite);
			});
		} else {
			sites = sites.filter(({ id }) => {
				return id.toString().includes(idFilter.toLowerCase());
			});
		}

		if (countryFilter !== '') {
			sites = sites.filter(({ country }) => {
				return country.toLowerCase().includes(countryFilter.toLowerCase());
			});
		}

		if (siteTypeFilter !== '') {
			sites = sites.filter(({ site_type_id }) => {
				return site_type_id === parseInt(siteTypeFilter);
			});
		}

		if (commodityFilter !== '') {
			sites = sites.filter(({ commodity_id }) => {
				return commodity_id === parseInt(commodityFilter);
			});
		}

		if (parentSiteFilter !== '') {
			if (parentSiteFilter === 'main-sub') {
				sites = sites.filter(({ subsites }) => {
					return subsites.length > 0;
				});
			} else if (parentSiteFilter === 'main-no-sub') {
				sites = sites.filter(({ subsites }) => {
					return subsites.length === 0;
				});
			}
		}
		// FILTERS

		// Create Mapbox geojson of lat/long for all sites to display on map
		let mapboxJSONs = { smelter: '', airfield: '', stockpiles: '' };
		if (sites.length > 0) {
			for (let j in siteTypes) {
				let mapboxJSONS = {
					type: 'geojson',
					data: {
						type: 'FeatureCollection',
						features: []
					}
				};

				let features = [];
				let sitesFiltered = sites.filter(
					({ site_type_id }) => site_type_id === siteTypes[j]['id']
				);

				for (let i in sitesFiltered) {
					if (sitesFiltered[i].hasOwnProperty('lat')) {
						let mapboxSiteJSON = mapLayerPointFormat(
							sitesFiltered[i]['lat'],
							sitesFiltered[i]['lon'],
							sitesFiltered[i]['id']
						);
						features.push(mapboxSiteJSON);
					}
				}
				mapboxJSONS['data']['features'] = features;
				mapboxJSONs[siteTypes[j]['name']] = mapboxJSONS;
			}
		}

		let [datetime, csv_sites] = this.createCSVDownloadOfSites();

		// DEVELOPMENT
		//sites = sites.filter((site) => site.id > 998999);
		// DEVELOPMENT

		console.log(this);

		return (
			<div>
				<Row className="py-3 justify-content-center" noGutters>
					<Col>
						<div className="d-flex justify-content-center">
							{isAdmin && (
								<Button
									size="md"
									color="info"
									className="mr-2"
									onClick={this.toggleCreate}
								>
									<i className="fas fa-plus mr-2" />
									Add site
								</Button>
							)}
							<Button
								size="md"
								color="info"
								className="mr-2"
								onClick={this.clearFilters}
							>
								<i className="fa-solid fa-filter mr-2" />
								Clear all filters
							</Button>
							{isAdmin && (
								<CSVLink
									data={csv_sites}
									filename={`sites-list-download-${datetime}.csv`}
									className="btn btn-info mr-2"
									target="_blank"
								>
									<i className="fa-solid fa-download mr-2" />
									Download sites as csv
								</CSVLink>
							)}
							<Button
								size="md"
								outline
								color="secondary"
								onClick={this.onReloadList}
							>
								<i className="fas fa-sync-alt" />
								<span className="sr-only">Refresh</span>
							</Button>
						</div>
					</Col>
				</Row>

				<Row noGutters>
					<Col>
						<div>
							<div id="mapStyleMenu" onChange={this.handleChange.bind(this)}>
								<Row className="d-flex justify-content-center p-0 m-0">
									{config.mapbox_styles.map(({ stylename, url }, idx) => (
										<div key={`key${idx}`} className="py-2 m-0">
											<label className="px-1 m-0">
												<Input
													className="py-0 mapRadios"
													id="drawStyle"
													type="radio"
													name="rtoggle"
													value={url}
													defaultChecked={this.state.drawStyle === url}
												></Input>
												<img
													className="map-radio-imgs"
													src={require(`images/${stylename}.png`)}
													alt={stylename}
												></img>
											</label>
										</div>
									))}
								</Row>
							</div>

							<Map
								center={[this.state.listMapLong, this.state.listMapLat]}
								style={`mapbox://styles/mapbox/${this.state.drawStyle}`}
								zoom={this.state.listMapZoom}
								onStyleLoad={(map) => {
									const popup = new mapboxgl.Popup({
										closeOnClick: false,
										closeButton: false
									});

									let layers = [
										'sitesLayerIdSmelter',
										'sitesLayerIdAirfield',
										'sitesLayerIdStockpiles'
									];

									for (let i in layers) {
										map.on('click', layers[i], (e) => {
											const coordinates = [
												e['lngLat']['lng'],
												e['lngLat']['lat']
											];

											map.flyTo({
												center: [e['lngLat']['lng'], e['lngLat']['lat']]
											});
											//const description = e.features[0].properties.description;

											let site = this.props.sites.filter(
												({ id }) => id === e.features[0].properties.description
											)[0];

											this.viewSiteDetailsInMap(site, e);
											this.onUpdate({ site }, e);
										});

										map.on('mouseenter', layers[i], (e) => {
											map.getCanvas().style.cursor = 'pointer';

											const description = e.features[0].properties.description;
											let site = this.props.sites.filter(
												({ id }) => id === e.features[0].properties.description
											)[0];

											popup
												.setLngLat([e['lngLat']['lng'], e['lngLat']['lat']])
												.setHTML(`<p>${description} ${site.name}</p>`)
												.addTo(map);
										});

										map.on('mouseleave', layers[i], () => {
											map.getCanvas().style.cursor = '';
											popup.remove();
										});
									}
								}}
							>
								<Source
									id="sitesSourceIdSmelter"
									geoJsonSource={mapboxJSONs['smelter']}
								></Source>
								<Source
									id="sitesSourceIdAirfield"
									geoJsonSource={mapboxJSONs['airfield']}
								></Source>
								<Source
									id="sitesSourceIdStockpiles"
									geoJsonSource={mapboxJSONs['stockpiles']}
								></Source>
								<Layer
									type="circle"
									id="sitesLayerIdSmelter"
									sourceId="sitesSourceIdSmelter"
									paint={{ 'circle-color': 'yellow' }}
								/>
								<Layer
									type="circle"
									id="sitesLayerIdAirfield"
									sourceId="sitesSourceIdAirfield"
									paint={{ 'circle-color': 'green' }}
								/>
								<Layer
									type="circle"
									id="sitesLayerIdStockpiles"
									sourceId="sitesSourceIdStockpiles"
									paint={{ 'circle-color': 'blue' }}
								/>
							</Map>
						</div>
					</Col>
				</Row>

				<Row noGutters>
					<Col xs="12">
						<Table className="sitesListTable">
							<thead>
								<tr id="filterRow">
									<th>
										<CustomInput
											id="parentSiteFilter"
											name="parentSiteFilter"
											type="select"
											defaultValue={parentSiteFilter}
											onChange={(event) => this.handleChange(event)}
											className="mr-2 parent-filter"
											bsSize="sm"
										>
											<option value="main" key="main">
												Main sites
											</option>
											<option value="main-sub" key="main-sub">
												Main sites with subsites
											</option>
											<option value="main-no-sub" key="main-no-sub">
												Main sites without subsites
											</option>
										</CustomInput>
									</th>
									<th style={{ width: '30px' }}>
										<CustomInput
											id="idFilter"
											name="idFilter"
											type="text"
											className="mr-2 name-filter"
											onChange={(event) => this.handleChange(event)}
											defaultValue={idFilter}
											placeholder="ID search"
											bsSize="sm"
											inline
										/>
									</th>
									<th>
										<CustomInput
											id="countryFilter"
											name="countryFilter"
											type="select"
											defaultValue={countryFilter}
											onChange={(event) => this.handleChange(event)}
											className="mr-2 name-filter"
											bsSize="sm"
										>
											<option value="">Country search</option>
											{countries.map(({ id, name }, idx) => (
												<option value={name} key={`country${idx}`}>
													{name}
												</option>
											))}
										</CustomInput>
									</th>
									<th>
										<CustomInput
											id="nameFilter"
											name="nameFilter"
											type="text"
											className="mr-2 name-filter"
											onChange={(event) => this.handleChange(event)}
											defaultValue={nameFilter}
											placeholder="Name search"
											bsSize="sm"
											inline
										/>
									</th>

									<th>
										<CustomInput
											id="commodityFilter"
											name="commodityFilter"
											type="select"
											defaultValue={commodityFilter}
											onChange={(event) => this.handleChange(event)}
											className="mr-2 name-filter"
											bsSize="sm"
										>
											<option value="">Commodity search</option>
											{commodities.map(({ id, name, symbol }, idx) => (
												<option value={id} key={`comm${idx}`}>
													{symbol}
												</option>
											))}
										</CustomInput>
									</th>

									<th>
										<CustomInput
											id="siteTypeFilter"
											name="siteTypeFilter"
											type="select"
											defaultValue={siteTypeFilter}
											onChange={(event) => this.handleChange(event)}
											className="mr-2 site-type-filter"
											bsSize="sm"
										>
											<option value="">Site Type</option>
											<option value="1">smelter</option>
											<option value="2">airfield</option>
											<option value="3">stockpiles</option>
											{/* {siteTypes.map(({ id, name, symbol }, idx) => (
												<option value={id} key={`comm${idx}`}>
													{name}
												</option>
											))} */}
										</CustomInput>
									</th>
									<th>
										<CustomInput
											id="plantTypeFilter"
											name="plantTypeFilter"
											type="select"
											defaultValue={plantTypeFilter}
											onChange={(event) => this.handleChange(event)}
											className="mr-2 plant-type-filter"
											bsSize="sm"
										>
											<option value="">Plant Type</option>
											{plantTypes.map((type, idx) => (
												<option value={type} key={`plant${idx}`}>
													{type}
												</option>
											))}
										</CustomInput>
									</th>
									<th></th>
								</tr>

								<tr>
									{/* <th style={{ width: '60px' }}>#</th> */}
									<th></th>
									<th style={{ width: '30px' }}>ID</th>
									<th style={{ width: '30px' }}>Country</th>
									<th style={{ width: '50px' }}>Site name</th>
									<th>Commodity</th>
									<th>Site Type</th>
									<th>Plant Type</th>
									<th>{sites.length} sites</th>
								</tr>
							</thead>
							<tbody>
								{sites.map((site, ind) => {
									const {
										id,
										name,
										country,
										site_type_id,
										commodity_id,
										commodity,
										plant_type,
										params,
										subsites,
										parent_id,
										stype
									} = site;
									const dspInd = ind + 1;

									if (parent_id === null && subsites.length > 0) {
										return (
											<>
												<tr key={`region${dspInd}`}>
													{/* <th scope="row">{dspInd}</th> */}
													<td style={{ width: '75px' }}>
														<i
															className="expand-button fa-solid fa-caret-down pr-2"
															data-toggle="collapse"
															data-target={`#accordian${dspInd}`}
															style={{ cursor: 'pointer' }}
															id={`id${ind}`}
															onClick={(e) => this.changeChevron(e, ind)}
															title="Expand to see child sites"
														></i>
														({subsites.length})
													</td>
													<td style={{ width: '30px' }}>{id}</td>
													<td>{country}</td>
													<td
														style={{ cursor: 'pointer' }}
														onClick={(e) => this.zoomToSite({ id }, e)}
													>
														{name}
													</td>
													{commodities
														.filter((comm) => comm.id === commodity_id)
														.map((filteredComm) => (
															<td>{filteredComm.symbol}</td>
														))}

													{siteTypes
														.filter((comm) => comm.id === site_type_id)
														.map((filteredComm) => (
															<td>{filteredComm.name}</td>
														))}
													<td>{plant_type}</td>
													<td>
														<div className="d-flex justify-content-end">
															{isAdmin && (
																<UncontrolledDropdown size="sm">
																	<DropdownToggle className="pointered btn-light">
																		<i className="fas fa-bars" />
																		<span className="sr-only">Actions</span>
																	</DropdownToggle>
																	<DropdownMenu>
																		<DropdownItem
																			className="pointered"
																			onClick={(e) =>
																				this.onUpdate({ site }, e)
																			}
																		>
																			<i className="fas fa-edit fa-fw mr-2" />
																			Update
																		</DropdownItem>
																		{site.stype === 'smelter' &&
																			site.commodity_id === 3 && (
																				<DropdownItem
																					className="pointered"
																					onClick={(e) =>
																						this.toggleCreateSubsite({ id })
																					}
																				>
																					<i className="fas fa-house-fire fa-fw mr-2" />
																					Add subsite
																				</DropdownItem>
																			)}

																		<DropdownItem
																			className="pointered"
																			onClick={(e) =>
																				this.onDelete({ site }, e)
																			}
																		>
																			<i className="fas fa-trash-alt fa-fw mr-2" />
																			Delete
																		</DropdownItem>
																	</DropdownMenu>
																</UncontrolledDropdown>
															)}
														</div>
													</td>
												</tr>

												{subsites.map((site, idx) => (
													<tr id={`accordian${dspInd}`} className="collapse">
														<td className="subsitesStyle">
															{site.params.parent_id}
														</td>
														<td className="subsitesStyle">{site.id}</td>
														<td className="subsitesStyle">{site.country}</td>
														<td className="subsitesStyle">{site.name}</td>

														{commodities
															.filter((comm) => comm.id === site.commodity_id)
															.map((filteredComm) => (
																<td className="subsitesStyle">
																	{filteredComm.symbol}
																</td>
															))}

														{siteTypes
															.filter((comm) => comm.id === site.site_type_id)
															.map((filteredComm) => (
																<td className="subsitesStyle">
																	{filteredComm.name}
																</td>
															))}

														<td className="subsitesStyle">{site.plant_type}</td>
														<td className="subsitesStyle">
															<div className="d-flex justify-content-end">
																{isAdmin && (
																	<UncontrolledDropdown size="sm">
																		<DropdownToggle className="pointered btn-light">
																			<i className="fas fa-bars" />
																			<span className="sr-only">Actions</span>
																		</DropdownToggle>
																		<DropdownMenu>
																			<DropdownItem
																				className="pointered"
																				onClick={(e) =>
																					this.onUpdate({ site }, e)
																				}
																			>
																				<i className="fas fa-edit fa-fw mr-2" />
																				Update
																			</DropdownItem>
																			<DropdownItem
																				className="pointered"
																				onClick={(e) =>
																					this.onDelete({ site }, e)
																				}
																			>
																				<i className="fas fa-trash-alt fa-fw mr-2" />
																				Delete
																			</DropdownItem>
																		</DropdownMenu>
																	</UncontrolledDropdown>
																)}
															</div>
														</td>
													</tr>
												))}
											</>
										);
									} else if (parent_id === null && subsites.length === 0) {
										return (
											<tr key={`region${dspInd}`}>
												{/* <th scope="row">{dspInd}</th> */}
												<td></td>
												<td style={{ width: '30px' }}>{id}</td>
												<td>{country}</td>
												<td
													style={{ cursor: 'pointer' }}
													onClick={(e) => this.zoomToSite({ id }, e)}
												>
													{name}
												</td>
												{commodities
													.filter((comm) => comm.id === commodity_id)
													.map((filteredComm) => (
														<td>{filteredComm.symbol}</td>
													))}

												{siteTypes
													.filter((comm) => comm.id === site_type_id)
													.map((filteredComm) => (
														<td>{filteredComm.name}</td>
													))}
												<td>{plant_type}</td>
												<td>
													<div className="d-flex justify-content-end">
														{isAdmin && (
															<UncontrolledDropdown size="sm">
																<DropdownToggle className="pointered btn-light">
																	<i className="fas fa-bars" />
																	<span className="sr-only">Actions</span>
																</DropdownToggle>
																<DropdownMenu>
																	<DropdownItem
																		className="pointered"
																		onClick={(e) => this.onUpdate({ site }, e)}
																	>
																		<i className="fas fa-edit fa-fw mr-2" />
																		Update
																	</DropdownItem>
																	{site.stype === 'smelter' &&
																		site.commodity_id === 3 && (
																			<DropdownItem
																				className="pointered"
																				onClick={(e) =>
																					this.toggleCreateSubsite({ id })
																				}
																			>
																				<i className="fas fa-house-fire fa-fw mr-2" />
																				Add subsite
																			</DropdownItem>
																		)}
																	<DropdownItem
																		className="pointered"
																		onClick={(e) => this.onDelete({ site }, e)}
																	>
																		<i className="fas fa-trash-alt fa-fw mr-2" />
																		Delete
																	</DropdownItem>
																</DropdownMenu>
															</UncontrolledDropdown>
														)}
													</div>
												</td>
											</tr>
										);
									}
								})}
							</tbody>
						</Table>
					</Col>
				</Row>
			</div>
		);
	}

	buildModals() {
		const { regions, countries, siteTypes, commodities, sites, siteInfo } =
			this.props;
		const {
			createModal,
			createSubsiteModal,
			updateModal,
			updateSite,
			deleteModal,
			deleteSite,
			kml_geojson,
			parentId,
			parentAOI,
			subsitesAOIS,
			parentSiteInfo
		} = this.state;
		var commodities2 = commodities;
		var { drawAOI } = this.state;

		console.log(kml_geojson);

		// SUBSITES //
		let parentAOIMap = '';
		if (parentAOI) {
			parentAOIMap = mapLayerGeoJSONFormat(parentSiteInfo.aoi, parentId);
		}

		let subsitesAOIsMap = [];
		if (subsitesAOIS.length > 0) {
			for (let i in subsitesAOIS) {
				let subsiteAOI = mapLayerGeoJSONFormat(
					subsitesAOIS[i].aoi,
					subsitesAOIS[i].id
				);
				subsitesAOIsMap.push(subsiteAOI);
			}
		}

		for (let i in sites) {
			sites[i]['subsites'] = [];
			if (sites[i].main_id === null) {
				for (let j in sites) {
					if (sites[j].main_id === sites[i].id) {
						sites[i]['subsites'].push(sites[j]);
					}
				}
			}
		}
		// SUBSITES //

		for (let i in commodities2) {
			if (commodities2[i]['id'] === 5) {
				commodities2[i]['symbol'] = '';
			}
		}

		if (Object.hasOwn(updateSite, 'params')) {
			var [
				siteMaxRawIndex,
				siteMaxRawIndex6,
				siteMaxRawIndex7,
				siteMaxRawIndex8,
				siteMaxRawIndex9
			] = ['', '', '', '', ''];

			var siteCapacity = updateSite['params']['capacity'];

			if (updateSite['params']['max_raw_index_score'] !== undefined) {
				siteMaxRawIndex = Math.round(
					updateSite['params']['max_raw_index_score']
				);
			} else {
				siteMaxRawIndex = '-';
			}

			if (updateSite['params']['max_raw_index_score_6'] !== undefined) {
				siteMaxRawIndex6 = Math.round(
					updateSite['params']['max_raw_index_score_6']
				);
			} else {
				siteMaxRawIndex6 = '-';
			}

			if (updateSite['params']['max_raw_index_score_7'] !== undefined) {
				siteMaxRawIndex7 = Math.round(
					updateSite['params']['max_raw_index_score_7']
				);
			} else {
				siteMaxRawIndex7 = '-';
			}

			if (updateSite['params']['max_raw_index_score_8'] !== undefined) {
				siteMaxRawIndex8 = Math.round(
					updateSite['params']['max_raw_index_score_8']
				);
			} else {
				siteMaxRawIndex8 = '-';
			}

			if (updateSite['params']['max_raw_index_score_9'] !== undefined) {
				siteMaxRawIndex9 = Math.round(
					updateSite['params']['max_raw_index_score_9']
				);
			} else {
				siteMaxRawIndex9 = '-';
			}
			var siteStartDate = updateSite['params']['start_date'];
			var siteEndDate = updateSite['params']['end_date'];
			var siteUseSmoke = updateSite['params']['use_smoke'];
			var siteProdState = updateSite['params']['prod_state'];
			var siteTwinId = updateSite['params']['twin_id'];
			var sitePlantType = updateSite['params']['plant_type'];
		}

		let site_ids = sites.map((site) => site.id);

		let uploadedFile;
		if (typeof this.state.kml_geojson === 'object') {
			uploadedFile = true;
		} else {
			uploadedFile = false;
		}

		let mainSiteTypes = ['smelter', 'airfield', 'stockpiles'];
		let filteredSiteTypes = siteTypes.filter(
			(siteType) => !mainSiteTypes.includes(siteType.name)
		);

		console.log(this.state)

		return (
			<Fragment>
				<Modal
					isOpen={createModal}
					toggle={this.toggleCreate}
					size="xl"
					closeonclick={true}
				>
					<ModalHeader toggle={this.toggleCreate}>
						<i className="fas fa-plus mr-3" />
						Create a new site
					</ModalHeader>

					<Row>
						<Col lg={12}>
							<div>
								<div>
									<label
										id="uploadFileButton"
										class="custom-file-upload"
										title="Upload a valid .kml or .geojson file"
									>
										<input
											type="file"
											id="fileUpload"
											onChange={this.handleFileUpload}
											name="fileUpload"
										/>
										<i className="fa-solid fa-file-arrow-up"></i>
									</label>
								</div>

								{/* <div>
									<label
										id="searchForAOI"
										class="search-aoi-button"
										title="Zoom to AOI"
										onClick={this.zoomToAOI}
									>
										
										<i className="fa-solid fa-magnifying-glass"></i>
									</label>
								</div> */}

								<div id="mapStyleMenu" onChange={this.handleChange.bind(this)}>
									<Row className="d-flex justify-content-center p-0 m-0">
										{config.mapbox_styles.map(({ stylename, url }, idx) => (
											<div key={`key${idx}`} className="py-2 m-0">
												<label className="px-1 m-0">
													<Input
														className="py-0 mapRadios"
														id="drawStyle"
														type="radio"
														name="rtoggle"
														value={url}
														defaultChecked={this.state.drawStyle === url}
													></Input>
													<img
														className="map-radio-imgs"
														src={require(`images/${stylename}.png`)}
														alt={stylename}
													></img>
												</label>
											</div>
										))}
									</Row>
								</div>

								<div id="latlonginfo">Lng: 43.000, Lat: 1.200</div>

								{parentAOIMap !== '' && (
									<Map
										center={[this.state.drawLong, this.state.drawLat]}
										style={`mapbox://styles/mapbox/${this.state.drawStyle}`}
										onMouseMove={(map, event) => {
											document.getElementById(
												'latlonginfo'
											).innerHTML = `<i class="fa-solid fa-arrow-pointer"></i> Lng: ${event.lngLat[
												'lng'
											].toFixed(3)}, Lat: ${event.lngLat['lat'].toFixed(3)}`;
										}}
										onStyleLoad={(map, event) => {
											const popup = new mapboxgl.Popup({
												closeOnClick: false,
												closeButton: false
											});

											const draw = new mapboxGlDraw({
												displayControlsDefault: false,

												controls: {
													polygon: true,
													trash: true
												},

												defaultMode: 'draw_polygon'
											});
											map.addControl(draw);
										}}
									>
										{uploadedFile && (
											<Source
												id="source_id"
												geoJsonSource={kml_geojson}
											></Source>
										)}

										{uploadedFile && (
											<Layer
												type="fill"
												id="layer_id"
												sourceId="source_id"
												paint={config.mapBoxLayerStyles.fill_paint}
											/>
										)}
										{uploadedFile && (
											<Layer
												type="line"
												id="line_id"
												sourceId="source_id"
												paint={config.mapBoxLayerStyles.line_paint}
											></Layer>
										)}

										{/* <DrawControl
										onDrawCreate={this.onDrawUpdate}
										onDrawUpdate={this.onDrawUpdate}
										onDrawDelete={this.onDrawDelete}
										displayControlsDefault={false}
										controls={{
											point: false,
											line_string: false,
											polygon: true,
											trash: true
										}}
									/> */}
									</Map>
								)}

								{parentAOIMap === '' && (
									<Map
										center={[this.state.drawLong, this.state.drawLat]}
										style={`mapbox://styles/mapbox/${this.state.drawStyle}`}
										onMouseMove={(map, event) => {
											document.getElementById(
												'latlonginfo'
											).innerHTML = `Lng: ${event.lngLat['lng'].toFixed(
												3
											)}, Lat: ${event.lngLat['lat'].toFixed(3)}`;
										}}
									>
										{uploadedFile && (
											<Source
												id="source_id"
												geoJsonSource={kml_geojson}
											></Source>
										)}

										{uploadedFile && (
											<Layer
												type="fill"
												id="layer_id"
												sourceId="source_id"
												paint={config.mapBoxLayerStyles.fill_paint}
											/>
										)}
										{uploadedFile && (
											<Layer
												type="line"
												id="line_id"
												sourceId="source_id"
												paint={config.mapBoxLayerStyles.line_paint}
											></Layer>
										)}

										<DrawControl
											onDrawCreate={this.onDrawUpdate}
											onDrawUpdate={this.onDrawUpdate}
											onDrawDelete={this.onDrawDelete}
											displayControlsDefault={false}
											controls={{
												point: false,
												line_string: false,
												polygon: true,
												trash: true
											}}
										/>
									</Map>
								)}
							</div>
						</Col>
					</Row>

					<Label className="form-section-names px-5 pt-2">
						Area of Interest
					</Label>
					<Row className="d-flex justify-content-center px-5">
						<Col md={3} className="d-flex flex-column justify-content-center">
							<Row id="fileUploadedDiv" title="Uploaded file">
								<Col md={2}>
									<button
										id="deleteUploadFile"
										onClick={this.deleteFileUpload}
										title="Delete uploaded file"
									>
										<i className="fa-solid fa-trash-can"></i>
									</button>
								</Col>
								<Col md={10} className="text-truncate">
									<p id="fileUploadName"></p>
								</Col>
							</Row>

							<p id="fileUploadError" className="text-center text-danger"></p>
						</Col>

						<Col md={5}>
							<div>
								<textarea
									id="createSiteAOITextArea"
									className="form-control-sm form-control"
									name="textValue"
									value={this.state.drawAOI}
									rows={7}
									cols={42}
									disabled={this.state.AOIFieldDisabled}
									onChange={this.handleChange}
								/>
							</div>

							{/** COPY / DOWNLOAD AOI */}
							<div>
								<Row className="d-flex justify-content-center px-4">
									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												id="createSiteOpenInGeojson"
												type="button"
												onClick={(event) => this.openInGeojsonIO(event)}
												title="Open in geojson.io"
												cursor="pointer"
											>
												<i className="fa-solid fa-up-right-from-square mr-2" />
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												disabled={true}
												//onClick={(event) => this.openInGeojsonIO(event)}
												title="Open in geojson.io"
											>
												<i className="fa-solid fa-up-right-from-square mr-2" />
											</Button>
										)}
									</Col>
									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												id="copyAOI"
												onClick={(event) => this.copyAOI(event)}
												title="Copy AOI to clipboard"
												cursor="pointer"
											>
												<i className="fa-solid fa-copy mr-2" />
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												disabled={true}
												onClick={(event) => this.copyAOI(event)}
												title="Copy AOI to clipboard"
											>
												<i className="fa-solid fa-copy mr-2" />
											</Button>
										)}
									</Col>
									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												id="downloadKML"
												onClick={(event) => this.downloadAOIFile(event)}
												title="Download .kml file"
												cursor="pointer"
											>
												<i className="fa-solid fa-download mr-2" />
												kml
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												id="downloadKML"
												//onClick={(event) => this.downloadAOIFile(event)}
												disabled={true}
												title="Download .kml file"
												cursor="pointer"
											>
												<i className="fa-solid fa-download mr-2" />
												kml
											</Button>
										)}
									</Col>

									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												className="AOIInfoButtons"
												size="sm"
												type="button"
												id="downloadgeoJSON"
												onClick={(event) => this.downloadAOIFile(event)}
												title="Download .geojson file"
											>
												<i className="fa-solid fa-download mr-2" />
												json
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												className="AOIInfoButtons"
												size="sm"
												type="button"
												id="downloadgeoJSON"
												//onClick={(event) => this.downloadAOIFile(event)}
												disabled={true}
												title="Download .geojson file"
											>
												<i className="fa-solid fa-download mr-2" />
												json
											</Button>
										)}
									</Col>
								</Row>
							</div>
							{/** COPY / DOWNLOAD AOI */}
						</Col>

						<Col md={4}>
							<Table id="aoi-table">
								<tbody>
									<tr>
										<td>
											<Label>
												Area km<sup>2</sup>
											</Label>
										</td>
										<td>
											<Input
												type="text"
												disabled={true}
												bsSize="sm"
												value={this.state.drawArea}
											></Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Latitude</Label>
										</td>
										<td>
											<Input
												type="text"
												disabled={true}
												bsSize="sm"
												value={this.state.drawLat}
											></Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Longitude</Label>
										</td>
										<td>
											<Input
												type="text"
												disabled={true}
												bsSize="sm"
												value={this.state.drawLong}
											></Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Country</Label>
										</td>
										<td>
											<Input
												id="createSiteCountry"
												type="select"
												disabled={true}
												bsSize="sm"
												value={this.state.drawCountry}
											>
												<option value="">Select a country</option>
												{countries.map(({ id, name }, idx) => (
													<option value={id} key={`country${idx}`}>
														{name}
													</option>
												))}
											</Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Region</Label>
										</td>
										<td>
											<Input
												id="createSiteRegion"
												type="select"
												disabled={true}
												bsSize="sm"
												value={this.state.drawRegion}
											>
												<option value="">Select a region</option>
												{regions.map(({ id, name }, idx) => (
													<option value={id} key={`region${idx}`}>
														{name}
													</option>
												))}
											</Input>
										</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>

					<Formik
						//enableReinitialize={true}
						initialValues={{
							createSiteId: '',
							createSiteName: '',
							createSiteCompany: '',
							//createSiteAOI: this.state.drawAOI,
							createSiteRegion: '',
							createSiteCountry: '',
							createSiteType: '',
							createSiteCommodity: '',
							createSiteParentId: this.state.parentId,

							// params
							prod_state: 5,
							capacity: '',
							twin_id: '',
							use_smoke: 0,
							start_date: '',
							end_date: '',
							plant_type: ''
						}}
						validate={(values) => {
							const errors = {};
							console.log(values);

							if (values.createSiteType === 'airfield') {
								document.getElementById('createSiteCommodity').disabled = true;
								values.createSiteCommodity = '';
							} else {
								document.getElementById('createSiteCommodity').disabled = false;
							}

							if (values.createSiteCountry) {
								values.createSiteRegion = countries.filter(
									(country) => country.name === values.createSiteCountry
								)[0].region;
							}

							if (site_ids.includes(parseInt(values.createSiteId))) {
								errors.createSiteId = 'This ID already exists';
							} else if (values.createSiteId.length === 0) {
								errors.createSiteId = 'Provide a site ID';
							} else if (parseInt(values.createSiteId) > 999999) {
								errors.createSiteId = 'Site ID out of range (0 - 999999)';
							}

							if (values.createSiteType === 'smelter') {
								var prod_state_values = [-2, -1, 0, 1, 2, 5];
								if (values.prod_state.length === 0) {
									errors.prod_state = 'A production state is required';
								} else if (!prod_state_values.includes(values.prod_state)) {
									errors.prod_state =
										'Production state must be -2, -1, 0, 1, 2 or 5';
								} else {
									errors.prod_state = '';
								}

								if (values.capacity === '') {
									errors.capacity = 'A capacity is required';
								} else {
									errors.capacity = '';
								}

								if (values.twin_id) {
									errors.twin_id = '';
								} else {
									errors.twin_id = '';
								}

								if (values.createSiteCommodity === '3') {
									if (values.plant_type === '') {
										errors.plant_type = 'Plant type must be "EAF" or "BF/BOF"';
									} else {
										errors.plant_type = '';
									}
								} else {
									errors.plant_type = '';
								}

								if (values.use_smoke === '') {
									errors.use_smoke = 'use_smoke must be 1 or 0';
								} else {
									errors.use_smoke = '';
								}

								if (values.start_date) {
									errors.start_date = '';
								} else {
									errors.start_date = '';
								}

								if (values.end_date) {
									errors.end_date = '';
								} else {
									errors.end_date = '';
								}
							}

							let siteTypesWithoutComms = [
								'airfield',
								'blast_furnace',
								'blast_furnaces',
								'bosbof',
								'coke_ovens',
								'ferrous_scrap_flux_beds',
								'pellet_plant',
								'rolling_lines',
								'sinter',
								'slag',
								'steel_inventories',
								'unknown'
							];

							if (
								values.createSiteCommodity.trim().length === 0 &&
								!siteTypesWithoutComms.includes(values.createSiteType)
							) {
								errors.createSiteCommodity = 'A commodity is required';
							}

							if (values.createSiteName.trim().length === 0) {
								errors.createSiteName = 'A name is required';
							} else if (values.createSiteName.trim().length > 80) {
								errors.createSiteName =
									'Name should be no more than 80 characters';
							}

							if (values.createSiteCompany.trim().length === 0) {
								errors.createSiteCompany = 'Company details are required';
							} else if (values.createSiteCompany.trim().length > 256) {
								errors.createSiteCompany =
									'Company details should be no more than 256 characters';
							}

							if (values.createSiteType.trim().length === 0) {
								errors.createSiteType = 'A type is required';
							}

							if (
								this.state.drawAOI ===
									'Draw a polygon on the map to create an AOI' ||
								this.state.drawAOI ===
									'AOIs should be of a valid shape and have no intersecting lines'
							) {
								errors.aoi = 'AOI error';
								document
									.getElementById('createSiteAOITextArea')
									.classList.add('is-invalid');
							} else {
								errors.aoi = '';
								document
									.getElementById('createSiteAOITextArea')
									.classList.remove('is-invalid');
							}

							for (var key in errors) {
								if (errors.hasOwnProperty(key)) {
									var value = errors[key];
									if (value === '') {
										delete errors[key];
									}
								}
							}

							console.log(errors);
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onCreateAction({ values, setSubmitting });
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							setFieldValue,
							handleSubmit,
							isSubmitting
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<Label className="form-section-names pt-1">
										Site Information
									</Label>

									<Row>
										<Col md={2}>
											<FormGroup>
												<Label for="createSiteId">Site ID</Label>
												<Input
													tag={Field}
													type="number"
													id="createSiteId"
													value={values.createSiteId}
													placeholder="14507"
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.createSiteId && touched.createSiteId}
													bsSize="sm"
												/>
												<FormFeedback>{errors.createSiteId}</FormFeedback>
											</FormGroup>
										</Col>

										<Col md={2}>
											<FormGroup>
												<Label for="createParentSiteId">Parent Site ID</Label>
												<Input
													//tag={Field}
													type="text"
													id="createParentSiteId"
													placeholder="null"
													bsSize="sm"
													title="This is a parent site, therefore Parent Site Id is null"
													disabled={true}
												/>
											</FormGroup>
										</Col>

										<Col md={4}>
											<FormGroup>
												<Label for="createSiteName">Site name</Label>
												<Input
													tag={Field}
													id="createSiteName"
													placeholder="Kalgoorlie"
													value={values.createSiteName}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.createSiteName && touched.createSiteName
													}
													bsSize="sm"
													type="search"
												/>
												<FormFeedback>{errors.createSiteName}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={4}>
											<FormGroup>
												<Label for="createSiteCompany">Company</Label>
												<Input
													id="createSiteCompany"
													placeholder="BHP Billiton Nickel West"
													value={values.createSiteCompany}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.createSiteCompany &&
														touched.createSiteCompany
													}
													bsSize="sm"
													type="search"
												/>
												<FormFeedback>{errors.createSiteCompany}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>

									<FormGroup>
										<Row form>
											<Col md={3}>
												<FormGroup>
													<Label for="createSiteType">Site Type</Label>
													<Input
														id="createSiteType"
														type="select"
														onChange={handleChange}
														//onChange={(event) => this.siteTemplate(event)}
														onBlur={handleBlur}
														value={values.createSiteType}
														invalid={
															errors.createSiteType && touched.createSiteType
														}
														bsSize="sm"
													>
														{/* <option value="">Please select a site type</option>
														{siteTypes.map(({ id, name, template }, idx) => (
															<option value={name} key={`sitetype${idx}`}>
																{name}
															</option>
														))} */}
														<option value="">Please select a site type</option>
														<option value="smelter">smelter</option>
														<option value="airfield">airfield</option>
														<option value="stockpiles">stockpiles</option>
													</Input>
													<FormFeedback>{errors.createSiteType}</FormFeedback>
												</FormGroup>
											</Col>

											<Col md={3}>
												<FormGroup>
													<Label for="createSiteCommodity">Commodity</Label>
													<CustomInput
														id="createSiteCommodity"
														type="select"
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder="Please select a commodity type"
														value={values.createSiteCommodity}
														invalid={
															errors.createSiteCommodity &&
															touched.createSiteCommodity
														}
														bsSize="sm"
													>
														<option value="5">Select a commodity type</option>
														{commodities
															.filter(
																({ site_type }) =>
																	site_type === values.createSiteType
															)
															.map(({ id, symbol, site_type }, idx) => (
																<option value={id} key={`comm${idx}`}>
																	{symbol}
																</option>
															))}
													</CustomInput>
													<FormFeedback>
														{errors.createSiteCommodity}
													</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
									</FormGroup>

									<Row>
										<FormGroup>
											<Col md={12}>
												{siteTypes
													.filter(({ name }) => name === values.createSiteType)
													.map((item) => {
														return (
															<Table
																id="createSiteParamsTable"
																key={`key${item.name}`}
															>
																<tbody>
																	{item.template.map(({ name, type }, idx) => {
																		return (
																			<tr key={`key${name}`}>
																				<td>{name}</td>
																				<td>
																					<Input
																						id={name}
																						value={`${values[name]}`}
																						onChange={handleChange}
																						onBlur={handleBlur}
																						//invalid={(`${errors[name]}`) || false}
																						bsSize="sm"
																						invalid={errors.hasOwnProperty(
																							name
																						)}
																						type={type.toLowerCase()}
																					></Input>
																					<FormFeedback>{`${errors[name]}`}</FormFeedback>
																				</td>
																			</tr>
																		);
																	})}
																</tbody>
															</Table>
														);
													})}
											</Col>
										</FormGroup>
									</Row>
								</ModalBody>
								<ModalFooter>
									<Button
										id="createSiteSubmitButton"
										color="info"
										size="sm"
										type="submit"
										disabled={isSubmitting}
									>
										Create site
									</Button>{' '}
									<Button
										color="secondary"
										size="sm"
										type="button"
										onClick={this.toggleCreate}
									>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</Modal>

				<Modal
					isOpen={createSubsiteModal}
					toggle={this.toggleCreateSubsite}
					size="xl"
					backdrop="static"
					keyboard="false"
				>
					{parentId && (
						<ModalHeader>
							<i className="fas fa-plus mr-3" />
							Add a new subsite to{' '}
							<strong>
								{parentSiteInfo.name} ({parentSiteInfo.id})
							</strong>
						</ModalHeader>
					)}

					<Row>
						<Col lg={12}>
							<div>
								<div>
									<label
										id="uploadFileButton"
										class="custom-file-upload"
										title="Upload a valid .kml or .geojson file"
									>
										<input
											type="file"
											id="fileUpload"
											onChange={this.handleFileUpload}
											name="fileUpload"
										/>
										<i className="fa-solid fa-file-arrow-up"></i>
									</label>
								</div>

								{/* <div id="sitesAndSubsitesMenu">
								<Row className="d-flex justify-content-center p-0 m-0">
										<div className="sitesSubsites" id="parentSite" title="Parent site"></div>
										<div className="sitesSubsites" id="subSite" title="Subsite"></div>
										<div className="sitesSubsites" id="newSite" title="New site"></div>
										
									</Row>
								</div> */}

								<div id="sitesAndSubsitesMenu">
									<ul>
										<li id={`parent${parentId}`}>
											<u>
												{parentId}: {parentSiteInfo.name}
											</u>
										</li>
										<li className="subsitesHidden" id={`parent${parentId}`}>
											<u>
												{parentId}: {parentSiteInfo.name}
											</u>
										</li>
									</ul>

									{subsitesAOIS.length > 0 && (
										<ul>
											{subsitesAOIS.map((subsite) => (
												<>
													<li id={`subsite${subsite.id}`}>
														{subsite.id}: {subsite.name}
													</li>
													<li
														className="subsitesHidden"
														id={`subsite${subsite.id}`}
													>
														{subsite.id}: {subsite.name}
													</li>
												</>
											))}
										</ul>
									)}
								</div>

								<div id="mapStyleMenu" onChange={this.handleChange.bind(this)}>
									<Row className="d-flex justify-content-center p-0 m-0">
										{config.mapbox_styles.map(({ stylename, url }, idx) => (
											<div key={`key${idx}`} className="py-2 m-0">
												<label className="px-1 m-0">
													<Input
														className="py-0 mapRadios"
														id="drawStyle"
														type="radio"
														name="rtoggle"
														value={url}
														defaultChecked={this.state.drawStyle === url}
													></Input>
													<img
														className="map-radio-imgs"
														src={require(`images/${stylename}.png`)}
														alt={stylename}
													></img>
												</label>
											</div>
										))}
									</Row>
								</div>

								<div id="latlonginfo">
									<i className="fa-solid fa-arrow-pointer"></i> Lng: 43.000,
									Lat: 1.200
								</div>

								{parentAOIMap !== '' && (
									<Map
										center={[this.state.drawLong, this.state.drawLat]}
										style={`mapbox://styles/mapbox/${this.state.drawStyle}`}
										onMouseMove={(map, event) => {
											document.getElementById(
												'latlonginfo'
											).innerHTML = `<i class="fa-solid fa-arrow-pointer"></i> Lng: ${event.lngLat[
												'lng'
											].toFixed(3)}, Lat: ${event.lngLat['lat'].toFixed(3)}`;
										}}
										onStyleLoad={(map, event) => {
											const popup = new mapboxgl.Popup({
												closeOnClick: false,
												closeButton: false
											});

											try {
												var coordinates = parentAOI.coordinates;
												try {
													const bounds = new mapboxgl.LngLatBounds(
														coordinates[0][0][0],
														coordinates[0][0][0]
													);

													for (const coord of coordinates[0][0]) {
														bounds.extend(coord);
													}

													map.fitBounds(bounds, {
														padding: 10
													});
												} catch (e) {
													console.log(e);
												}
											} catch (e) {
												console.log(e);
											}

											// PARENT LAYER //
											map.on('mouseenter', 'parent_layer_id', (e) => {
												map.getCanvas().style.cursor = 'pointer';

												//document.getElementById(`parent${parentSiteInfo.id}`).style.fontWeight = 'bolder'
												// 	map.setPaintProperty('parent_layer_id', 'fill-color', '#E0FFFF');
											});

											map.on('mouseleave', 'parent_layer_id', () => {
												//document.getElementById(`parent${parentSiteInfo.id}`).style.fontWeight = 'normal'
												map.getCanvas().style.cursor = '';

												// map.setPaintProperty('parent_layer_id', 'fill-color', '#ADD8E6');
											});
											// PARENT LAYER //

											let numSubsiteLayers = this.state.subsitesAOIS.length;
											let subsiteLayers = [];
											let subsiteIds = [];

											for (let i in this.state.subsitesAOIS) {
												let layer = `layer${i}`;
												subsiteLayers.push(layer);
												subsiteIds.push(this.state.subsitesAOIS[i].id);
											}

											for (let i in (subsiteLayers, subsiteIds)) {
												map.on('mouseenter', subsiteLayers[i], (e) => {
													map.getCanvas().style.cursor = 'pointer';

													document.getElementById(
														`subsite${subsiteIds[i]}`
													).style.fontWeight = 'bolder';

													// const description =
													// 	e.features[0].properties.description;
													// let site = this.props.sites.filter(
													// 	({ id }) =>
													// 		id === e.features[0].properties.description
													// )[0];

													// popup
													// 	.setLngLat([e['lngLat']['lng'], e['lngLat']['lat']])
													// 	.setHTML(`<p>${description} ${site.name}</p>`)
													// 	.addTo(map);

													// 	map.setPaintProperty('parent_layer_id', 'fill-color', '#ADD8E6');
													map.setPaintProperty(
														subsiteLayers[i],
														'fill-opacity',
														0.5
													);
												});

												map.on('mouseleave', subsiteLayers[i], () => {
													map.getCanvas().style.cursor = '';
													//popup.remove();
													document.getElementById(
														`subsite${subsiteIds[i]}`
													).style.fontWeight = 'normal';
													//map.setPaintProperty(subsiteLayers[i], 'fill-color', '#ADD8E6');
													map.setPaintProperty(
														subsiteLayers[i],
														'fill-opacity',
														0.3
													);
												});
											}
										}}
									>
										<Source
											id="source_id"
											geoJsonSource={parentAOIMap}
										></Source>

										<Layer
											type="fill"
											id="parent_layer_id"
											sourceId="source_id"
											paint={{
												'fill-color': '#ADD8E6',
												'fill-opacity': 0.2
											}}
										/>

										<Layer
											type="line"
											id="parent_line_id"
											sourceId="source_id"
											paint={{
												'line-color': '#ADD8E6',
												'line-width': 3,
												'line-opacity': 0.5
											}}
										></Layer>

										{subsitesAOIsMap.map((subsite, idx) => (
											<>
												<Source
													id={`source${idx}`}
													geoJsonSource={subsite}
												></Source>
												<Layer
													type="line"
													id={`line${idx}`}
													sourceId={`source${idx}`}
													paint={{
														'line-color': '#ADD8E6',
														'line-width': 3,
														'line-opacity': 0.5
													}}
												></Layer>
												<Layer
													type="fill"
													id={`layer${idx}`}
													sourceId={`source${idx}`}
													paint={{
														'fill-color': '#ADD8E6',
														'fill-opacity': 0.2
													}}
												></Layer>
											</>
										))}

										{uploadedFile && (
											<Source
												id="subsite_source_id"
												geoJsonSource={kml_geojson}
											></Source>
										)}

										{uploadedFile && (
											<Layer
												type="fill"
												id="subsite_layer_id"
												sourceId="subsite_source_id"
												paint={{
													'fill-color': '#00BFFF',
													'fill-opacity': 0.5
												}}
											/>
										)}
										{uploadedFile && (
											<Layer
												type="line"
												id="subsite_line_id"
												sourceId="subsite_source_id"
												paint={{
													'line-color': '#00BFFF',
													'line-width': 3
												}}
											></Layer>
										)}

										<DrawControl
											onDrawCreate={this.onDrawUpdate}
											onDrawUpdate={this.onDrawUpdate}
											onDrawDelete={this.onDrawDelete}
											displayControlsDefault={false}
											controls={{
												point: false,
												line_string: false,
												polygon: true,
												trash: true
											}}
										/>
									</Map>
								)}
							</div>
						</Col>
					</Row>

					<Label className="form-section-names px-5 pt-2">
						Area of Interest
					</Label>
					<Row className="d-flex justify-content-center px-5">
						<Col md={3} className="d-flex flex-column justify-content-center">
							<Row id="fileUploadedDiv" title="Uploaded file">
								<Col md={2}>
									<button
										id="deleteUploadFile"
										onClick={this.deleteFileUpload}
										title="Delete uploaded file"
									>
										<i className="fa-solid fa-trash-can"></i>
									</button>
								</Col>
								<Col md={10} className="text-truncate">
									<p id="fileUploadName"></p>
								</Col>
							</Row>

							<p id="fileUploadError" className="text-center text-danger"></p>
						</Col>

						<Col md={5}>
							<div>
								<textarea
									id="createSiteAOITextArea"
									className="form-control-sm form-control"
									name="textValue"
									value={this.state.drawAOI}
									rows={8}
									cols={42}
									disabled={this.state.AOIFieldDisabled}
									onChange={this.handleChange}
								/>
							</div>

							{/** COPY / DOWNLOAD AOI */}
							<div>
								<Row className="d-flex justify-content-center px-4">
									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												id="createSiteOpenInGeojson"
												type="button"
												onClick={(event) => this.openInGeojsonIO(event)}
												title="Open in geojson.io"
												cursor="pointer"
											>
												<i className="fa-solid fa-up-right-from-square mr-2" />
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												disabled={true}
												//onClick={(event) => this.openInGeojsonIO(event)}
												title="Open in geojson.io"
											>
												<i className="fa-solid fa-up-right-from-square mr-2" />
											</Button>
										)}
									</Col>
									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												id="copyAOI"
												onClick={(event) => this.copyAOI(event)}
												title="Copy AOI to clipboard"
												cursor="pointer"
											>
												<i className="fa-solid fa-copy mr-2" />
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												disabled={true}
												onClick={(event) => this.copyAOI(event)}
												title="Copy AOI to clipboard"
											>
												<i className="fa-solid fa-copy mr-2" />
											</Button>
										)}
									</Col>
									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												id="downloadKML"
												onClick={(event) => this.downloadAOIFile(event)}
												title="Download .kml file"
												cursor="pointer"
											>
												<i className="fa-solid fa-download mr-2" />
												kml
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												id="downloadKML"
												//onClick={(event) => this.downloadAOIFile(event)}
												disabled={true}
												title="Download .kml file"
												cursor="pointer"
											>
												<i className="fa-solid fa-download mr-2" />
												kml
											</Button>
										)}
									</Col>

									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												className="AOIInfoButtons"
												size="sm"
												type="button"
												id="downloadgeoJSON"
												onClick={(event) => this.downloadAOIFile(event)}
												title="Download .geojson file"
											>
												<i className="fa-solid fa-download mr-2" />
												json
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												className="AOIInfoButtons"
												size="sm"
												type="button"
												id="downloadgeoJSON"
												//onClick={(event) => this.downloadAOIFile(event)}
												disabled={true}
												title="Download .geojson file"
											>
												<i className="fa-solid fa-download mr-2" />
												json
											</Button>
										)}
									</Col>
								</Row>
							</div>
							{/** COPY / DOWNLOAD AOI */}
						</Col>

						<Col md={4}>
							<Table id="aoi-table">
								<tbody>
									<tr>
										<td>
											<Label>
												Area km<sup>2</sup>
											</Label>
										</td>
										<td>
											<Input
												type="text"
												disabled={true}
												bsSize="sm"
												value={this.state.drawArea}
											></Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Latitude</Label>
										</td>
										<td>
											<Input
												type="text"
												disabled={true}
												bsSize="sm"
												value={this.state.drawLat}
											></Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Longitude</Label>
										</td>
										<td>
											<Input
												type="text"
												disabled={true}
												bsSize="sm"
												value={this.state.drawLong}
											></Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Country</Label>
										</td>
										<td>
											{parentSiteInfo.country && (
												<Input
													id="createSiteCountry"
													type="select"
													disabled={true}
													bsSize="sm"
													value={
														this.props.countries.filter(
															(country) =>
																country.name === parentSiteInfo.country
														)[0]['id']
													}
												>
													<option value="">Select a country</option>
													{countries.map(({ id, name }, idx) => (
														<option value={id} key={`country${idx}`}>
															{name}
														</option>
													))}
												</Input>
											)}
											{/* <FormFeedback>{errors.createSiteCountry}</FormFeedback> */}
										</td>
									</tr>

									<tr>
										<td>
											<Label>Region</Label>
										</td>
										<td>
											{parentSiteInfo.region && (
												<Input
													id="createSiteRegion"
													type="select"
													disabled={true}
													bsSize="sm"
													value={parentSiteInfo.region}
												>
													<option value="">Select a region</option>
													{regions.map(({ id, name }, idx) => (
														<option value={id} key={`region${idx}`}>
															{name}
														</option>
													))}
												</Input>
											)}
											{/* <FormFeedback>{errors.createSiteRegion}</FormFeedback> */}
										</td>
									</tr>
								</tbody>
							</Table>
						</Col>
					</Row>

					<Formik
						enableReinitialize={true}
						initialValues={{
							// inherited from parent site
							createSiteCompany: this.state.parentSiteInfo['company'],
							createSiteRegion: '',
							createSiteCountry: this.state.parentSiteInfo['country'],
							createSiteType: this.state.parentSiteInfo['site_type_id'],
							createSiteCommodity: this.state.parentSiteInfo['commodity_id'],
							parent_id: this.state.parentId,

							// subsite info
							createSubSiteType: '',
							createSiteId: '',
							createSiteName: '',

							// params
							prod_state: 5,
							capacity: '',
							twin_id: '',
							use_smoke: 0,
							start_date: '',
							end_date: '',
							plant_type: ''
						}}
						validate={(values) => {
							console.log(values);
							const errors = {};

							// if (values.createSiteType === 'airfield') {
							// 	document.getElementById('createSiteCommodity').disabled = true;
							// 	values.createSiteCommodity = '';
							// } else {
							// 	document.getElementById('createSiteCommodity').disabled = false;
							// }

							// values.createSiteCommodity = this.props.commodities.filter(
							// 	(comm) => comm.id === this.state.parentSiteInfo['commodity_id']
							// )[0]['symbol'];

							if (values.createSiteCountry) {
								values.createSiteRegion = countries.filter(
									(country) => country.name === values.createSiteCountry
								)[0].region;
							}

							if (site_ids.includes(parseInt(values.createSiteId))) {
								errors.createSiteId = 'This ID already exists';
							} else if (values.createSiteId.length === 0) {
								errors.createSiteId = 'Provide a site ID';
							} else if (parseInt(values.createSiteId) > 999999) {
								errors.createSiteId = 'Site ID out of range (0 - 999999)';
							}

							if (values.createSiteType === 1) {
								var prod_state_values = [-2, -1, 0, 1, 2, 5];
								if (values.prod_state.length === 0) {
									errors.prod_state = 'A production state is required';
								} else if (!prod_state_values.includes(values.prod_state)) {
									errors.prod_state =
										'Production state must be -2, -1, 0, 1, 2 or 5';
								} else {
									errors.prod_state = '';
								}

								if (values.capacity === '') {
									errors.capacity = 'A capacity is required';
								} else {
									errors.capacity = '';
								}

								if (values.parent_id != this.state.parentSiteInfo['id']) {
									errors.parent_id = `Parent ID must be ${this.state.parentSiteInfo['id']}`;
								} else {
									errors.parent_id = '';
								}

								if (values.createSiteCommodity === '1') {
									if (values.plant_type === '') {
										errors.plant_type = 'Plant type must be "EAF" or "BF/BOF"';
									} else {
										errors.plant_type = '';
									}
								} else {
									errors.plant_type = '';
								}

								if (values.twin_id) {
									errors.twin_id = '';
								} else {
									errors.twin_id = '';
								}

								if (values.use_smoke === '') {
									errors.use_smoke = 'use_smoke must be 1 or 0';
								} else {
									errors.use_smoke = '';
								}

								if (values.start_date) {
									errors.start_date = '';
								} else {
									errors.start_date = '';
								}

								if (values.end_date) {
									errors.end_date = '';
								} else {
									errors.end_date = '';
								}
							}

							if (values.createSiteName.trim().length === 0) {
								errors.createSiteName = 'A name is required';
							} else if (values.createSiteName.trim().length > 80) {
								errors.createSiteName =
									'Name should be no more than 80 characters';
							}

							if (values.createSiteCompany.trim().length === 0) {
								errors.createSiteCompany = 'Company details are required';
							} else if (values.createSiteCompany.trim().length > 256) {
								errors.createSiteCompany =
									'Company details should be no more than 256 characters';
							}

							if (values.createSubSiteType.trim().length === 0) {
								errors.createSubSiteType = 'Subsite type is required';
							}

							// if (values.createSiteType.trim().length === 0) {
							// 	errors.createSiteType = 'A type is required';
							// }

							if (
								this.state.drawAOI ===
									'Draw a polygon on the map to create an AOI' ||
								this.state.drawAOI ===
									'AOIs should be of a valid shape and have no intersecting lines' ||
								this.state.drawAOI ===
									'Subsite drawn contains coordinates outwith parent site AOI'
							) {
								errors.aoi = 'AOI error';
								document
									.getElementById('createSiteAOITextArea')
									.classList.add('is-invalid');
							} else {
								errors.aoi = '';
								document
									.getElementById('createSiteAOITextArea')
									.classList.remove('is-invalid');
							}

							for (var key in errors) {
								if (errors.hasOwnProperty(key)) {
									var value = errors[key];
									if (value === '') {
										delete errors[key];
									}
								}
							}

							console.log(errors);
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onCreateAction({ values, setSubmitting });
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							setFieldValue,
							handleSubmit,
							isSubmitting
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<Label className="form-section-names px-3 pt-2">
										Site Information
									</Label>
									<Row>
										<Col md={2}>
											<FormGroup>
												<Label for="createSiteId">Site ID</Label>
												<Input
													tag={Field}
													type="number"
													id="createSiteId"
													value={values.createSiteId}
													placeholder="14507"
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.createSiteId && touched.createSiteId}
													bsSize="sm"
												/>
												<FormFeedback>{errors.createSiteId}</FormFeedback>
											</FormGroup>
										</Col>

										<Col md={2}>
											<FormGroup>
												<Label for="createSubsiteParentId">
													Site Parent ID
												</Label>
												<Input
													tag={Field}
													type="number"
													id="createSubsiteParentId"
													value={this.state.parentId}
													//placeholder="14507"
													//onChange={handleChange}
													//onBlur={handleBlur}
													//invalid={errors.createSiteId && touched.createSiteId}
													bsSize="sm"
													disabled={true}
													title="This value cannot be changed"
												/>
												{/* <FormFeedback>{errors.createSiteId}</FormFeedback> */}
											</FormGroup>
										</Col>

										<Col md={4}>
											<FormGroup>
												<Label for="createSiteName">Site name</Label>
												<Input
													tag={Field}
													id="createSiteName"
													placeholder="Kalgoorlie"
													value={values.createSiteName}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.createSiteName && touched.createSiteName
													}
													bsSize="sm"
													type="search"
												/>
												<FormFeedback>{errors.createSiteName}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={4}>
											<FormGroup>
												<Label for="createSiteCompany">Company</Label>
												<Input
													id="createSiteCompany"
													placeholder="BHP Billiton Nickel West"
													value={values.createSiteCompany}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.createSiteCompany &&
														touched.createSiteCompany
													}
													bsSize="sm"
													type="search"
													disabled={true}
												/>
												<FormFeedback>{errors.createSiteCompany}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>

									<FormGroup>
										<Row form>
											<Col>
												<FormGroup>
													<Label for="createSiteType">Site Type</Label>
													<Input
														id="createSiteType"
														type="select"
														onChange={handleChange}
														//onChange={(event) => this.siteTemplate(event)}
														onBlur={handleBlur}
														value={values.createSiteType}
														invalid={
															errors.createSiteType && touched.createSiteType
														}
														bsSize="sm"
														disabled={true}
													>
														<option value="">Please select a site type</option>
														{siteTypes.map(({ id, name, template }, idx) => (
															<option value={id} key={`sitetype${idx}`}>
																{name}
															</option>
														))}
													</Input>
													<FormFeedback>{errors.createSiteType}</FormFeedback>
												</FormGroup>
											</Col>

											<Col>
												<FormGroup>
													<Label for="createSiteCommodity">Commodity</Label>
													<Input
														id="createSiteCommodity"
														type="select"
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder="Please select a commodity type"
														value={parseInt(values.createSiteCommodity)}
														invalid={
															errors.createSiteCommodity &&
															touched.createSiteCommodity
														}
														bsSize="sm"
														disabled={true}
													>
														<option value="5">Select a commodity type</option>
														{commodities.map(({ id, symbol }, idx) => (
															<option value={id} key={`comm${idx}`}>
																{symbol}
															</option>
														))}
													</Input>
													<FormFeedback>
														{errors.createSiteCommodity}
													</FormFeedback>
												</FormGroup>
											</Col>

											<Col>
												<FormGroup>
													<Label for="createSiteType">Subsite Type</Label>
													<Input
														id="createSubSiteType"
														type="select"
														onChange={handleChange}
														//onChange={(event) => this.siteTemplate(event)}
														onBlur={handleBlur}
														value={values.createSubSiteType}
														invalid={
															errors.createSubSiteType &&
															touched.createSubSiteType
														}
														bsSize="sm"
													>
														<option value="">Please select a site type</option>
														{siteTypes
															.filter(
																(siteType) =>
																	siteType.parent_type_id ===
																	values.createSiteType
															)
															.map(
																(
																	{ id, name, template, parent_type_id },
																	idx
																) => (
																	<option value={id} key={`sitetype${idx}`}>
																		{name}
																	</option>
																)
															)}
													</Input>
													<FormFeedback>{errors.createSiteType}</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
									</FormGroup>

									<Row>
										<FormGroup>
											<Col md={12}>
												{siteTypes
													.filter(
														({ id }) =>
															id === parseInt(values.createSubSiteType)
													)
													.map((item) => {
														return (
															<Table
																id="createSiteParamsTable"
																key={`key${item.name}`}
															>
																<tbody>
																	{item.template.map(({ name, type }, idx) => {
																		return (
																			<tr key={`key${name}`}>
																				<td>{name}</td>
																				<td>
																					<Input
																						id={name}
																						value={`${values[name]}`}
																						onChange={handleChange}
																						onBlur={handleBlur}
																						//invalid={(`${errors[name]}`) || false}
																						bsSize="sm"
																						invalid={errors.hasOwnProperty(
																							name
																						)}
																						type={type.toLowerCase()}
																					></Input>
																					<FormFeedback>{`${errors[name]}`}</FormFeedback>
																				</td>
																			</tr>
																		);
																	})}
																</tbody>
															</Table>
														);
													})}
											</Col>
											{/* <Col md={12}>
												{filteredSiteTypes
													.filter(({ name }) => name === values.createSiteType)
													.map((item) => {
														return (
															<Table
																id="createSiteParamsTable"
																key={`key${item.name}`}
															>
																<tbody>
																	{item.template.map(({ name, type }, idx) => {
																		return (
																			<tr key={`key${name}`}>
																				<td>{name}</td>
																				<td>
																					<Input
																						id={name}
																						value={`${values[name]}`}
																						onChange={handleChange}
																						onBlur={handleBlur}
																						//invalid={(`${errors[name]}`) || false}
																						bsSize="sm"
																						invalid={errors.hasOwnProperty(
																							name
																						)}
																						type={type.toLowerCase()}
																					></Input>
																					<FormFeedback>{`${errors[name]}`}</FormFeedback>
																				</td>
																			</tr>
																		);
																	})}
																</tbody>
															</Table>
														);
													})}
											</Col> */}
										</FormGroup>
									</Row>
								</ModalBody>
								<ModalFooter>
									<Button
										id="createSiteSubmitButton"
										color="info"
										size="sm"
										type="submit"
										disabled={isSubmitting}
									>
										Create site
									</Button>{' '}
									<Button
										color="secondary"
										size="sm"
										type="button"
										onClick={this.toggleCreateSubsite}
									>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</Modal>

				<Modal isOpen={updateModal} toggle={this.toggleUpdate} size="xl">
					<ModalHeader>
						<i className="fas fa-pen mr-3" />
						Update <b>{updateSite['name']}</b>
					</ModalHeader>

					<Row>
						<Col>
							<div>
								<div>
									<label
										id="uploadFileButton"
										class="custom-file-upload"
										title="Upload a valid .kml or .geojson file"
									>
										<input
											type="file"
											id="fileUpload"
											onChange={this.handleFileUpload}
											name="fileUpload"
										/>
										<i className="fa-solid fa-file-arrow-up"></i>
									</label>
								</div>

								<div id="mapStyleMenu" onChange={this.handleChange.bind(this)}>
									<Row className="d-flex justify-content-center p-0 m-0">
										{config.mapbox_styles.map(({ stylename, url }, idx) => (
											<div key={`key${idx}`} className="py-2 m-0">
												<label className="px-1 m-0">
													<Input
														className="py-0 mapRadios"
														id="drawStyle"
														type="radio"
														name="rtoggle"
														value={url}
														defaultChecked={this.state.drawStyle === url}
													></Input>
													<img
														className="map-radio-imgs"
														src={require(`images/${stylename}.png`)}
														alt={stylename}
													></img>
												</label>
											</div>
										))}
									</Row>
								</div>

								{/* <div id="sitesAndSubsitesMenu">
								<Row className="d-flex justify-content-center p-0 m-0">
										<div className="sitesSubsites" id="parentSite" title="Parent site"></div>
										<div className="sitesSubsites" id="subSite" title="Subsite"></div>
										<div className="sitesSubsites" id="newSite" title="New site"></div>
									</Row>
								</div> */}

								<div id="sitesAndSubsitesMenu">
									<ul>
										<li id={`parent${parentId}`}>
											<u>
												{parentId}: {parentSiteInfo.name}
											</u>
										</li>
										<li className="subsitesHidden" id={`parent${parentId}`}>
											<u>
												{parentId}: {parentSiteInfo.name}
											</u>
										</li>
									</ul>

									{subsitesAOIS.length > 0 && (
										<ul>
											{subsitesAOIS.map((subsite) => (
												<>
													<li id={`subsite${subsite.id}`}>
														{subsite.id}: {subsite.name}
													</li>
													<li
														className="subsitesHidden"
														id={`subsite${subsite.id}`}
													>
														{subsite.id}: {subsite.name}
													</li>
												</>
											))}
										</ul>
									)}
								</div>

								<div id="latlonginfo">
									<i className="fa-solid fa-arrow-pointer"></i> Lng: 43.000,
									Lat: 1.200
								</div>

								<Map
									center={[this.state.drawLong, this.state.drawLat]}
									style={`mapbox://styles/mapbox/${this.state.drawStyle}`}
									//zoom={[this.state.currentZoom]}
									onMouseMove={(map, event) => {
										document.getElementById(
											'latlonginfo'
										).innerHTML = `<i class="fa-solid fa-arrow-pointer"></i> Lng: ${event.lngLat[
											'lng'
										].toFixed(3)}, Lat: ${event.lngLat['lat'].toFixed(3)}`;
									}}
									onStyleLoad={(map, e) => {
										var draw = this.drawControl;
										const popup = new mapboxgl.Popup({
											closeOnClick: false,
											closeButton: false
										});

										try {
											map.on('mouseenter', 'parent_layer_id', (e) => {
												map.getCanvas().style.cursor = 'pointer';
												popup
													.setLngLat([e['lngLat']['lng'], e['lngLat']['lat']])
													.setHTML(
														`<p>PARENT SITE: ${this.state.parentAOI.id} ${this.state.parentAOI.name}</p>`
													)
													.addTo(map);
											});

											map.on('mouseleave', 'parent_layer_id', () => {
												map.getCanvas().style.cursor = '';
												popup.remove();
											});

											var coordinates = JSON.parse(drawAOI).coordinates;
											

											if (
												Object.hasOwn(siteInfo[0], 'aoi') &&
												typeof this.state.kml_geojson != 'object'
											) {
												if (siteInfo[0]['aoi'] != null) {
													
													draw.draw.add(JSON.parse(drawAOI));

													try {
														const bounds = new mapboxgl.LngLatBounds(
															coordinates[0][0][0],
															coordinates[0][0][0]
														);

														for (const coord of coordinates[0][0]) {
															bounds.extend(coord);
														}

														map.fitBounds(bounds, {
															padding: 10
														});
													} catch (e) {
														console.log(e);
													}
												}
											}
										} catch (e) {
											console.log(e);
										}

										let numSubsiteLayers = this.state.subsitesAOIS.length;
										let subsiteLayers = [];
										let subsiteIds = [];

										for (let i in this.state.subsitesAOIS) {
											let layer = `layer${i}`;
											subsiteLayers.push(layer);
											subsiteIds.push(this.state.subsitesAOIS[i]['id']);
										}

										for (let i in (subsiteLayers, subsiteIds)) {
											map.on('mouseenter', subsiteLayers[i], (e) => {
												map.getCanvas().style.cursor = 'pointer';

												document.getElementById(
													`subsite${subsiteIds[i]}`
												).style.fontWeight = 'bolder';

												map.setPaintProperty(
													subsiteLayers[i],
													'fill-opacity',
													0.5
												);
											});

											map.on('mouseleave', subsiteLayers[i], () => {
												map.getCanvas().style.cursor = '';
												document.getElementById(
													`subsite${subsiteIds[i]}`
												).style.fontWeight = 'normal';
												map.setPaintProperty(
													subsiteLayers[i],
													'fill-opacity',
													0.3
												);
											});
										}
									}}
								>
									{uploadedFile && (
										<Source id="source_id" geoJsonSource={kml_geojson}></Source>
									)}

									{uploadedFile && (
										<Layer
											type="fill"
											id="layer_id"
											sourceId="source_id"
											paint={{
												'fill-color': '#ADD8E6',
												'fill-opacity': 0.3
											}}
										/>
									)}
									{uploadedFile && (
										<Layer
											type="line"
											id="line_id"
											sourceId="source_id"
											paint={{
												'line-color': '#ADD8E6',
												'line-width': 3
											}}
										></Layer>
									)}

									{subsitesAOIsMap.map((subsite, idx) => (
										<>
											<Source
												id={`source${idx}`}
												geoJsonSource={subsite}
											></Source>
											<Layer
												type="line"
												id={`line${idx}`}
												sourceId={`source${idx}`}
												paint={{
													'line-color': '#ADD8E6',
													'line-width': 3
												}}
											></Layer>
											<Layer
												type="fill"
												id={`layer${idx}`}
												sourceId={`source${idx}`}
												paint={{
													'fill-color': '#ADD8E6',
													'fill-opacity': 0.3
												}}
											></Layer>
										</>
									))}

									{parentAOIMap && (
										<>
											<Source
												id="parent_id"
												geoJsonSource={parentAOIMap}
											></Source>

											<Layer
												type="fill"
												id="parent_layer_id"
												sourceId="parent_id"
												paint={{
													'fill-color': '#ADD8E6',
													'fill-opacity': 0.3
												}}
											/>

											<Layer
												type="line"
												id="line_id"
												sourceId="parent_id"
												paint={{
													'line-color': '#ADD8E6',
													'line-width': 3
												}}
											></Layer>
										</>
									)}

									<DrawControl
										onDrawCreate={this.onDrawUpdate}
										onDrawUpdate={this.onDrawUpdate}
										onDrawDelete={this.onDrawDelete}
										displayControlsDefault={false}
										controls={{
											point: false,
											line_string: false,
											polygon: true,
											trash: true
										}}
										ref={(drawControl) => {
											this.drawControl = drawControl;
										}}
									/>
								</Map>
							</div>
						</Col>
					</Row>

					<Label className="form-section-names px-3 pt-1">
						Area of Interest
					</Label>

					<Row className="d-flex justify-content-center px-4">
						<Col md={3} className="d-flex flex-column justify-content-center">
							<Row id="fileUploadedDiv" title="Uploaded file">
								<Col md={2}>
									<button
										id="deleteUploadFile"
										onClick={this.deleteFileUpload}
										title="Delete uploaded file"
									>
										<i className="fa-solid fa-trash-can"></i>
									</button>
								</Col>
								<Col md={10} className="text-truncate">
									<p id="fileUploadName"></p>
								</Col>
							</Row>

							<p id="fileUploadError" className="text-center text-danger"></p>
						</Col>

						<Col md={5}>
							<div>
								<textarea
									id="updateSiteAOITextArea"
									className="form-control-sm form-control"
									name="textValue"
									value={this.state.drawAOI}
									rows={6}
									cols={42}
									disabled={true}
									onChange={this.handleChange}
								/>
							</div>

							{/** COPY / DOWNLOAD AOI */}
							<div>
								<Row className="d-flex justify-content-center px-4">
									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												size="sm"
												id="updateSiteOpenInGeojson"
												className="AOIInfoButtons"
												type="button"
												onClick={(event) => this.openInGeojsonIO(event)}
												title="Open in geojson.io"
												cursor="pointer"
											>
												<i className="fa-solid fa-up-right-from-square mr-2" />
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												disabled={true}
												//onClick={(event) => this.openInGeojsonIO(event)}
												title="Open in geojson.io"
											>
												<i className="fa-solid fa-up-right-from-square mr-2" />
											</Button>
										)}
									</Col>
									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												id="copyAOI"
												onClick={(event) => this.copyAOI(event)}
												title="Copy AOI to clipboard"
												cursor="pointer"
											>
												<i className="fa-solid fa-copy mr-2" />
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												id="copyAOI"
												disabled={true}
												onClick={(event) => this.copyAOI(event)}
												title="Copy AOI to clipboard"
											>
												<i className="fa-solid fa-copy mr-2" />
											</Button>
										)}
									</Col>
									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												id="downloadKML"
												onClick={(event) => this.downloadAOIFile(event)}
												title="Download .kml file"
												cursor="pointer"
											>
												<i className="fa-solid fa-download mr-2" />
												kml
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												size="sm"
												className="AOIInfoButtons"
												type="button"
												id="downloadKML"
												//onClick={(event) => this.downloadAOIFile(event)}
												disabled={true}
												title="Download .kml file"
												cursor="pointer"
											>
												<i className="fa-solid fa-download mr-2" />
												kml
											</Button>
										)}
									</Col>

									<Col className="px-1 py-1">
										{this.state.drawArea.length > 0 && (
											<Button
												color="light"
												className="AOIInfoButtons"
												size="sm"
												type="button"
												id="downloadgeoJSON"
												onClick={(event) => this.downloadAOIFile(event)}
												title="Download .geojson file"
											>
												<i className="fa-solid fa-download mr-2" />
												json
											</Button>
										)}
										{this.state.drawArea.length === 0 && (
											<Button
												color="light"
												className="AOIInfoButtons"
												size="sm"
												type="button"
												id="downloadgeoJSON"
												//onClick={(event) => this.downloadAOIFile(event)}
												disabled={true}
												title="Download .geojson file"
											>
												<i className="fa-solid fa-download mr-2" />
												json
											</Button>
										)}
									</Col>
								</Row>
							</div>
							{/** COPY / DOWNLOAD AOI */}
						</Col>

						{/** AOI INFO - LAT LONG COUNTRY ETC */}
						<Col md={4}>
							<Table id="aoi-table">
								<tbody>
									<tr>
										<td>
											<Label>
												Area km<sup>2</sup>
											</Label>
										</td>
										<td>
											<Input
												type="text"
												disabled={true}
												bsSize="sm"
												value={this.state.drawArea}
											></Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Latitude</Label>
										</td>
										<td>
											<Input
												type="text"
												disabled={true}
												bsSize="sm"
												value={parseFloat(this.state.drawLat).toFixed(3)}
											></Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Longitude</Label>
										</td>
										<td>
											<Input
												type="text"
												disabled={true}
												bsSize="sm"
												value={parseFloat(this.state.drawLong).toFixed(3)}
											></Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Country</Label>
										</td>
										<td>
											<Input
												id="createSiteCountry"
												type="select"
												disabled={true}
												bsSize="sm"
												value={this.state.drawCountry}
											>
												<option value="">Select a country</option>
												{countries.map(({ id, name }, idx) => (
													<option value={id} key={`country${idx}`}>
														{name}
													</option>
												))}
											</Input>
										</td>
									</tr>

									<tr>
										<td>
											<Label>Region</Label>
										</td>
										<td>
											<Input
												id="createSiteRegion"
												type="select"
												disabled={true}
												bsSize="sm"
												value={this.state.drawRegion}
											>
												<option value="">Select a region</option>
												{regions.map(({ id, name }, idx) => (
													<option value={id} key={`region${idx}`}>
														{name}
													</option>
												))}
											</Input>
										</td>
									</tr>
								</tbody>
							</Table>
						</Col>
						{/** AOI INFO - LAT LONG COUNTRY ETC */}
					</Row>

					{/** UPDATE SITE FORM */}
					<Formik
						//enableReinitialize={true}
						initialValues={{
							updateSiteId: updateSite['id'],
							updateSiteName: updateSite['name'],
							updateSiteCompany: updateSite['company'],
							updateSiteType: updateSite['site_type_id'],
							updateSiteCommodity: updateSite['commodity_id'],

							// params
							prod_state: siteProdState,
							capacity: siteCapacity,
							twin_id: siteTwinId,
							use_smoke: siteUseSmoke,
							start_date: siteStartDate,
							end_date: siteEndDate,
							parent_id: updateSite['parent_id'],
							plant_type: sitePlantType
						}}
						validate={(values) => {
							const errors = {};

							// check that the commodity matches the site type
							var list_of_comms = commodities.filter(
								({ site_type }) =>
									site_type ===
									this.props.siteTypes.filter(
										({ id }) => id === parseInt(values.updateSiteType)
									)[0]['name']
							);

							for (let i in list_of_comms) {
								if (
									list_of_comms[i]['id'] === values.updateSiteCommodity ||
									list_of_comms[i]['id'] ===
										parseInt(values.updateSiteCommodity)
								) {
									errors.updateSiteCommodity = '';
									break;
								} else {
									errors.updateSiteCommodity =
										'This commodity cannot be chosen for this site type.';
								}
							}

							if (values.parent_id != updateSite['parent_id']) {
								errors.parent_id = `Parent ID must be ${updateSite['parent_id']}`;
							} else {
								errors.parent_id = '';
							}

							if (values.updateSiteId === updateSite['id']) {
								errors.updateSiteId = '';
							} else if (site_ids.includes(parseInt(values.updateSiteId))) {
								errors.updateSiteId = 'This ID already exists';
							} else if (values.updateSiteId.length === 0) {
								errors.updateSiteId = 'Provide a site ID';
							}

							if (values.updateSiteName.trim().length === 0) {
								errors.updateSiteName = 'A name is required';
							} else if (values.updateSiteName.trim().length > 80) {
								errors.updateSiteName =
									'Name should be no more than 80 characters';
							}

							if (values.updateSiteCompany.trim().length === 0) {
								errors.updateSiteCompany = 'Company details are required';
							} else if (values.updateSiteCompany.trim().length > 256) {
								errors.updateSiteCompany =
									'Company details should be no more than 256 characters';
							}

							if (values.updateSiteType === 1) {
								var prod_state_values = [-2, -1, 0, 1, 2, 5];
								if (values.prod_state.length === 0) {
									errors.prod_state = 'A production state is required';
								} else if (!prod_state_values.includes(values.prod_state)) {
									errors.prod_state =
										'Production state must be -2, -1, 0, 1, 2 or 5';
								} else {
									errors.prod_state = '';
								}

								if (values.capacity === '') {
									errors.capacity = 'A capacity is required';
								} else {
									errors.capacity = '';
								}

								if (values.twin_id) {
									errors.twin_id = '';
								} else {
									errors.twin_id = '';
								}

								if (
									values.plant_type === '' ||
									values.plant_type === 'EAF' ||
									values.plant_type === 'BF/BOF'
								) {
									errors.plant_type = '';
								} else {
									errors.plant_type =
										'Plant type must be EAF or BF/BOF or left blank';
								}

								if (values.use_smoke === '') {
									errors.use_smoke = 'use_smoke must be 1 or 0';
								} else {
									errors.use_smoke = '';
								}

								if (values.start_date) {
									errors.start_date = '';
								} else {
									errors.start_date = '';
								}

								if (values.end_date) {
									errors.end_date = '';
								} else {
									errors.end_date = '';
								}
							}

							if (
								this.state.drawAOI ===
									'Draw a polygon on the map to create an AOI' ||
								this.state.drawAOI ===
									'AOIs should be of a valid shape and have no intersecting lines' ||
								this.state.drawAOI === 'Upload a valid geoJSON or KML file.' ||
								this.state.drawAOI ===
									'All coordinates should be outwith subsite AOIs' ||
								this.state.drawAOI ===
									'AOIs should be of a valid shape and have no intersecting lines, and all subsite points should be within the parent site AOI' ||
								this.state.drawAOI === 
									'There is no AOI associated with this site.'
							) {
								errors.aoi = 'AOI error';
							} else {
								errors.aoi = '';
							}

							for (var key in errors) {
								if (errors.hasOwnProperty(key)) {
									var value = errors[key];
									if (value === '') {
										delete errors[key];
									}
								}
							}

							console.log(values);
							console.log(errors);

							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onUpdateAction({ values, setSubmitting });
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							setFieldValue,
							handleSubmit,
							isSubmitting
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalBody>
									<Label className="form-section-names pt-1">
										Site Information
									</Label>
									<Row className="px-4">
										<Col md={2}>
											<FormGroup>
												<Label for="updateSiteId">Site ID</Label>
												<Input
													tag={Field}
													type="number"
													id="updateSiteId"
													value={values.updateSiteId}
													placeholder={values.updateSiteId}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.updateSiteId && touched.updateSiteId}
													bsSize="sm"
												/>
												<FormFeedback>{errors.updateSiteId}</FormFeedback>
											</FormGroup>
										</Col>

										<Col md={5}>
											<FormGroup>
												<Label for="updateSiteName">Site name</Label>
												<Input
													tag={Field}
													id="updateSiteName"
													value={values.updateSiteName}
													placeholder={values.updateSiteName}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.updateSiteName && touched.updateSiteName
													}
													bsSize="sm"
													type="search"
												/>
												<FormFeedback>{errors.updateSiteName}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={5}>
											<FormGroup>
												<Label for="updateSiteCompany">Company</Label>
												<Input
													id="updateSiteCompany"
													value={values.updateSiteCompany || ''}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.updateSiteCompany &&
														touched.updateSiteCompany
													}
													bsSize="sm"
													type="search"
												/>
												<FormFeedback>{errors.updateSiteCompany}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>

									<FormGroup>
										<Row className="px-4" form>
											<Col md={6}>
												<FormGroup>
													<Label for="updateSiteType">Site Type</Label>
													<Input
														id="updateSiteType"
														type="select"
														onChange={handleChange}
														onBlur={handleBlur}
														value={values.updateSiteType}
														invalid={
															errors.updateSiteType && touched.updateSiteType
														}
														bsSize="sm"
														disabled={true}
													>
														{/* <option value="">Please select a site type</option> */}
														{siteTypes.map(({ id, name, template }, idx) => (
															<option value={id} key={`sitetype${idx}`}>
																{name}
															</option>
														))}
													</Input>

													<FormFeedback>{errors.updateSiteType}</FormFeedback>
												</FormGroup>
											</Col>

											<Col>
												<FormGroup>
													<Label for="updateSiteCommodity">Commodity</Label>
													<CustomInput
														id="updateSiteCommodity"
														type="select"
														onChange={handleChange}
														onBlur={handleBlur}
														placeholder="Please select a commodity type"
														value={values.updateSiteCommodity}
														invalid={
															errors.updateSiteCommodity &&
															touched.updateSiteCommodity
														}
														bsSize="sm"
														disabled={true}
														title="Commodity type must be the same as the parent site"
													>
														<option value="">Select a commodity type</option>
														{this.state.updateSite.parent_id === null &&
															commodities
																.filter(
																	({ site_type }) =>
																		site_type ===
																		siteTypes.filter(
																			({ id }) =>
																				id === parseInt(values.updateSiteType)
																		)[0]['name']
																)
																.map(({ id, symbol, site_type }, idx) => (
																	<option
																		value={parseInt(id)}
																		key={`comm${idx}`}
																	>
																		{symbol}
																	</option>
																))}
														{this.state.updateSite.parent_id != null &&
															commodities
																.filter(
																	({ site_type }) =>
																		site_type ===
																		siteTypes.filter(
																			({ id }) =>
																				id ===
																				parseInt(
																					this.state.parentSiteInfo.site_type_id
																				)
																		)[0]['name']
																)
																.map(({ id, symbol, site_type }, idx) => (
																	<option
																		value={parseInt(id)}
																		key={`comm${idx}`}
																	>
																		{symbol}
																	</option>
																))}
													</CustomInput>
													<FormFeedback>
														{errors.updateSiteCommodity}
													</FormFeedback>
												</FormGroup>
											</Col>
										</Row>
									</FormGroup>

									<Row className="d-flex justify-contents-center px-4">
										<Col xs={6} md={6} lg={6} className="px-0">
											<Table id="updateSiteIndexTable">
												<tbody>
													<tr>
														<td>Max Raw Index Score</td>
														<td>
															<Input
																id={siteMaxRawIndex}
																defaultValue={siteMaxRawIndex}
																bsSize="sm"
																onChange={this.handleChange}
																disabled={true}
															></Input>
														</td>
													</tr>

													<tr>
														<td>Max Raw Index Score 6</td>
														<td>
															<Input
																id={siteMaxRawIndex6}
																defaultValue={siteMaxRawIndex6}
																bsSize="sm"
																onChange={this.handleChange}
																disabled={true}
															></Input>
														</td>
													</tr>

													<tr>
														<td>Max Raw Index Score 7</td>
														<td>
															<Input
																id={siteMaxRawIndex7}
																defaultValue={siteMaxRawIndex7}
																bsSize="sm"
																onChange={this.handleChange}
																disabled={true}
															></Input>
														</td>
													</tr>

													<tr>
														<td>Max Raw Index Score 8</td>
														<td>
															<Input
																id={siteMaxRawIndex8}
																defaultValue={siteMaxRawIndex8}
																bsSize="sm"
																onChange={this.handleChange}
																disabled={true}
															></Input>
														</td>
													</tr>

													<tr>
														<td>Max Raw Index Score 9</td>
														<td>
															<Input
																id={siteMaxRawIndex9}
																defaultValue={siteMaxRawIndex9}
																bsSize="sm"
																onChange={this.handleChange}
																disabled={true}
															></Input>
														</td>
													</tr>
												</tbody>
											</Table>
										</Col>
										<Col xs={6} md={6} lg={6} className="px-2">
											<FormGroup>
												{siteTypes
													.filter(
														({ id }) => id === parseInt(values.updateSiteType)
													)
													.map((item) => {
														return (
															<Table
																id="updateSiteParamsTable"
																key={`key${item.name}`}
															>
																<tbody>
																	{item.template.map(({ name, type }, idx) => {
																		return (
																			<tr key={`key${name}`}>
																				<td>{name.replace('_', ' ')}</td>
																				<td>
																					<Input
																						id={name}
																						value={`${values[name]}`}
																						onChange={handleChange}
																						onBlur={handleBlur}
																						invalid={errors.hasOwnProperty(
																							name
																						)}
																						type={type.toLowerCase()}
																						bsSize="sm"
																					></Input>
																					<FormFeedback>{`${errors[name]}`}</FormFeedback>
																				</td>
																			</tr>
																		);
																	})}
																</tbody>
															</Table>
														);
													})}
											</FormGroup>
										</Col>
									</Row>

									{/* {this.state.subsitesAOIS.length > 0 && (
										<>
											<Label className="form-section-names px-2 pt-1">
												Subsites
											</Label>

											<Table>
												<thead>
													<th>ID</th>
													<th>Name</th>
												</thead>
												<tbody>
													{this.state.subsitesAOIS.map((subsite) => {
														return (
															<tr key={`sub${subsite.id}`}>
																<td>{subsite.id}</td>
																<td>{subsite.name}</td>
															</tr>
														);
													})}
												</tbody>
											</Table>
										</>
									)} */}
								</ModalBody>
								<ModalFooter>
									<Button
										id="updateSiteSubmitButton"
										color="info"
										size="sm"
										type="submit"
										disabled={isSubmitting}
									>
										Update site
									</Button>{' '}
									<Button
										color="secondary"
										size="sm"
										type="button"
										onClick={this.toggleUpdate}
									>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
					{/** UPDATE SITE FORM */}
				</Modal>

				<Modal isOpen={deleteModal} toggle={this.toggleDelete}>
					<ModalHeader toggle={this.toggleDelete}>
						<i className="fas fa-trash-alt mr-3" />
						Delete a site
					</ModalHeader>
					<ModalBody>
						<p>
							Please confirm that you really want to delete site '
							{deleteSite.name}'?
						</p>
						<p className="small text-muted">
							Deleting a site will remove associated group relationships in the
							database too, which include Captures, PseudoGroup relationships,
							Updates and UserSmelter sign-ups. A deleted site nor the data from
							these relationships can be recovered.
						</p>
						<p className="small text-danger">PROCEED WITH CAUTION!</p>
					</ModalBody>
					<ModalFooter>
						<Button color="info" size="sm" onClick={this.onDeleteAction}>
							Delete site
						</Button>{' '}
						<Button color="secondary" size="sm" onClick={this.toggleDelete}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}

	render() {
		return (
			<DocumentTitle title="Gains Admin | Sites">
				<Container fluid>
					<Crumbs path={[{ title: 'Sites' }]} />

					{this.buildResultsTable()}
					{this.buildModals()}
				</Container>
			</DocumentTitle>
		);
	}
}

// The connect() function connects a React component to a Redux store.
// It provides its connected component with the pieces of the data it needs from the store,
// and the functions it can use to dispatch actions to the store.

const mapStateToProps = ({
	users,
	regions,
	sites,
	countries,
	commodities,
	siteTypes,
	siteInfo,
	siteGroups,
	subsitesInfo
}) => {
	return {
		users,
		regions, // redux
		sites, // redux
		countries, // redux
		commodities, // redux
		siteTypes, // redux
		siteInfo, // redux
		siteGroups, // redux
		subsitesInfo // redux
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchUsers: () => dispatch(fetchUsers()),
	fetchRegions: () => dispatch(fetchRegions()),
	fetchSites: () => dispatch(fetchSites()),
	fetchSiteTypes: () => dispatch(fetchSiteTypes()),
	fetchCountries: () => dispatch(fetchCountries()),
	fetchCommodities: () => dispatch(fetchCommodities()),
	getAuthenticatedUserAttributes: () =>
		dispatch(getAuthenticatedUserAttributes()),
	createSite: (data) => dispatch(createSite(data)),
	updateSite: (data) => dispatch(updateSite(data)),
	fetchSiteInfo: (data) => dispatch(fetchSiteInfo(data)),
	fetchSubsiteInfo: (data) => dispatch(fetchSubsiteInfo(data)),
	deleteSite: (data) => dispatch(deleteSite(data)),
	fetchSiteGroups: (data) => dispatch(fetchSiteGroups(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Sites);
