import {
	area,
	centerOfMass,
	polygon,
	point,
	kinks,
	booleanPointInPolygon
} from '@turf/turf';
import config from 'config';

export const calcAOIArea = (coords) => {
	/**
	 * Provides AOI information, calculated from turf.js
	 */
	//console.log(coords);
	var poly = polygon(coords);

	var lat = centerOfMass(poly)['geometry']['coordinates'][1].toFixed(3);
	var long = centerOfMass(poly)['geometry']['coordinates'][0].toFixed(3);
	var areaAOI = (area(poly) / 1000000).toFixed(2);

	let AOIInfo = {
		lat: lat,
		long: long,
		area: areaAOI
	};

	return AOIInfo;
};

export const calcAOIAreaUpload = (kml_geojson) => {
	console.log(kml_geojson);

	var lat = centerOfMass(kml_geojson['data']['geometry'])['geometry'][
		'coordinates'
	][1].toFixed(3);
	var long = centerOfMass(kml_geojson['data']['geometry'])['geometry'][
		'coordinates'
	][0].toFixed(3);
	var areaAOI = (area(kml_geojson['data']['geometry']) / 1000000).toFixed(2);

	let AOIInfo = {
		lat: lat,
		long: long,
		area: areaAOI
	};

	return AOIInfo;
};

export const subsiteAOIWithinParentAOI = (coords, parentAOI) => {
	/**
	 * For subsites, calcs if all drawn points are within the parent site
	 * Returns True or False
	 */

	//console.log(parentAOI)
	var parentPoly
	if(parentAOI.hasOwnProperty('aoi')){
		parentPoly = polygon(parentAOI['aoi']['coordinates'][0]);
	} else {
		parentPoly = polygon(parentAOI['coordinates'][0])
	}

	

	try {
		let allPointsInParentAOI = false;

		// check if any coords in the drawn AOI fall within any current AOIs
		for (let i in coords[0]) {
			let pt = point(coords[0][i]);

			if (booleanPointInPolygon(pt, parentPoly)) {
				allPointsInParentAOI = true;
			} else {
				allPointsInParentAOI = false;
				break;
			}
		}

		return allPointsInParentAOI;
	} catch (e) {
		console.log(e);
	}
};

export const subsiteAOIWithinParentAOIUploadedFile = (
	kml_geojson,
	parentAOI
) => {
	let allPointsInParentAOI = false;
	let coords = kml_geojson['data']['geometry']['coordinates'][0];

	console.log(coords);

	// check if any coords in the drawn AOI fall within any current AOIs
	for (let i in coords) {
		if (booleanPointInPolygon(coords[i], parentAOI)) {
			allPointsInParentAOI = true;
		} else {
			allPointsInParentAOI = false;
			break;
		}
	}

	return allPointsInParentAOI;
};

export const stringifyGeoJSON = (features) => {
	/**
	 * Converts a JSON object into the stringified version for
	 * displaying on form
	 */

	return JSON.stringify(
		{
			type: 'Polygon',
			coordinates: features[0]['geometry']['coordinates']
		},
		undefined,
		4
	);
};

export const mapLayerGeoJSONFormat = (geoJSON, desc) => {
	/**
	 * Mapbox requires a specific JSON format to display features
	 * This function converts any geoJSON into that format
	 */

	//console.log(geoJSON)

	return {
		type: 'geojson',
		data: {
			type: 'Feature',
			properties: {
				description: desc
			},
			geometry: {
				type: 'Polygon',
				coordinates: geoJSON.coordinates[0]
			}
		}
	};
};

export const geojsonioJSONFormat = (coordinates) => {
	return JSON.stringify({
		type: 'FeatureCollection',
		features: [
			{
				type: 'Feature',
				properties: {},
				geometry: {
					type: 'Polygon',
					coordinates: coordinates
				}
			}
		]
	});
};

export const mapLayerPointFormat = (lat, long, desc) => {
	/**
	 * Mapbox requires a specific JSON format to display features
	 * This function converts any geoJSON into that format
	 */

	let mapboxSiteJSON = {
		type: 'Feature',
		properties: {
			description: desc
		},
		geometry: {
			type: 'Point',
			coordinates: [long, lat]
		}
	};
	return mapboxSiteJSON;
};

export const uploadedFileIsGeojson = (geojson) => {
	var kml_geojson, features_geojson;

	let coords = '';
	try {
		coords = geojson['data']['geometry']['coordinates'][0];
	} catch {}

	try {
		coords = geojson['geometry']['coordinates'];
	} catch {}

	try {
		coords = geojson['features'][0]['geometry']['coordinates'];
	} catch {}

	kml_geojson = {
		type: 'geojson',
		data: {
			type: 'Feature',
			geometry: {
				type: 'Polygon',
				coordinates: coords
			}
		}
	};

	features_geojson =  [
		{
			id: '',
			type: 'Feature',
			properties: {},
			geometry: {
				coordinates: coords,
				type: 'Polygon'
			}
		}
	];

	return [kml_geojson, features_geojson];
};

export const uploadedFileIsGeojson2 = (geojson) => {
	var kml_geojson;

	let coords = '';
	try {
		coords = geojson['data']['geometry']['coordinates'][0];
	} catch {}

	try {
		coords = geojson['geometry']['coordinates'];
	} catch {}

	try {
		coords = geojson['features'][0]['geometry']['coordinates'];
	} catch {}

	kml_geojson = [
		{
			id: '',
			type: 'Feature',
			properties: {},
			geometry: {
				coordinates: coords,
				type: 'Polygon'
			}
		}
	];

	console.log(geojson)

	// kml_geojson = {
	// 	type: 'geojson',
	// 	data: {
	// 		type: 'Feature',
	// 		geometry: {
	// 			type: 'Polygon',
	// 			coordinates: coords
	// 		}
	// 	}
	// };

	return kml_geojson;
};

export const uploadedFileIsKML = (obj) => {
	console.log(obj);
	var kml_geojson;
	var coords;
	var features_geojson

	try {
		coords = obj['children'].filter((x) => x.name === 'Polygon')[0][
			'children'
		][0]['children'][0]['children'][0]['value'];
	} catch (e) {
		console.log(e);
	}

	try {
		coords = obj['children'][0]['children']
			.filter((x) => x.name === 'Placemark')[0]
			['children'].filter((y) => y.name === 'Polygon')[0]
			['children'].filter((z) => z.name === 'outerBoundaryIs')[0]
			['children'].filter((a) => a.name === 'LinearRing')[0]
			['children'].filter((b) => b.name === 'coordinates')[0]['value'];
	} catch (e) {
		console.log(e);
	}

	try {
		coords = obj['data']['geometry']['coordinates'][0][0];
	} catch (e) {
		console.log(e);
	}

	try {
		coords = obj['children']
			.filter((x) => x.name === 'Polygon')[0]
			['children'].filter((y) => y.name === 'outerBoundaryIs')[0]
			['children'].filter((z) => z.name === 'LinearRing')[0]
			['children'].filter((a) => a.name === 'coordinates')[0]['value'];
	} catch (e) {
		console.log(e);
	}

	const myArray = coords.split(' ');
	let coords2 = [];

	for (let i in myArray) {
		let x = myArray[i].split(',');
		if (x.length === 3) {
			x = x.slice(0, -1);
		}

		let y = [];
		for (let j in x) {
			y.push(Number(x[j]));
		}

		coords2.push(y);
	}

	console.log(coords2)

	kml_geojson = {
		type: 'geojson',
		data: {
			type: 'Feature',
			geometry: {
				type: 'Polygon',
				coordinates: [coords2]
			}
		}
	};

	features_geojson =  [
		{
			id: '',
			type: 'Feature',
			properties: {},
			geometry: {
				coordinates: [coords2],
				type: 'Polygon'
			}
		}
	];

	return [kml_geojson, features_geojson];
};

export const isAOIValid = (coords) => {
	/**
	 * Calcs whether the AOI is a valid shape or has intersecting lines
	 * Returns True or False
	 */

	var poly = polygon(coords);

	try {
		if (kinks(poly).features.length > 0) {
			return false;
		} else {
			return true;
		}
	} catch (e) {
		console.log(e);
	}
};

export const disableMap = (fileName) => {
	document.getElementsByClassName(
		'mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_polygon'
	)[0].disabled = true;
	document.getElementById('fileUploadName').innerHTML = fileName;
	document.getElementById('fileUploadName').title = fileName;
	document.getElementById('uploadFileButton').disabled = 'true';
	document.getElementById('fileUploadedDiv').style.display = 'inline-block';
	document.getElementById('fileUploadError').innerHTML = '';
	document.getElementsByClassName(
		'mapbox-gl-draw_ctrl-draw-btn mapbox-gl-draw_trash'
	)[0].disabled = true;
};
