import { FETCH_SITE_GROUPS } from 'actions';

export default (state = [], action) => {
	switch (action.type) {
		case FETCH_SITE_GROUPS:
			return action.payload;
		default:
			return state;
	}
};
