import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import DocumentTitle from 'react-document-title';
import {
	Button,
	Col,
	Container,
	CustomInput,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	FormFeedback,
	Form,
	FormGroup,
	FormText,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
	UncontrolledDropdown
} from 'reactstrap';

import {
	fetchGroups,
	fetchGroupTypes,
	createGroupType,
	updateGroupType,
	deleteGroupType,
	fetchUsers,
	getAuthenticatedUserAttributes,
	deleteGroup,
	deleteAllGroupSites
} from 'actions';

import { Crumbs } from 'containers';
import './GroupType.scss';

class GroupType extends Component {
	state = {
		createModal: false,
		updateGroupTypeModal: false,
		updateGroupType: {},
		deleteModal: false,
		deleteGroupType: {},
		typeFilter: '',
		nameFilter: '',
		isAdmin: '',
		groupsToDelete: []
	};

	componentDidMount() {
		this.props.fetchGroupTypes();
		this.props.fetchGroups();
		this.updateUserState();
	}

	async updateUserState() {
		const { email } = await getAuthenticatedUserAttributes();
		let currentUser;
		let users = await this.props.fetchUsers();

		for (let user in users.payload) {
			if (users.payload[user].email === email) {
				currentUser = users.payload[user];

				if (
					currentUser.userRole === 'superUser' ||
					currentUser.userRole === 'admin'
				) {
					this.setState({
						isAdmin: true
					});
				} else {
					this.setState({
						isAdmin: false
					});
				}
			}
		}
	}

	onReloadList = () => {
		this.props.fetchGroupTypes();
	};

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	toggleCreate = () => {
		this.setState((prevState) => ({
			createModal: !prevState.createModal
		}));
	};

	toggleCreateGroupType = () => {
		this.setState((prevState) => ({
			createGroupTypeModal: !prevState.createGroupTypeModal
		}));
	};

	onCreateGroupTypeAction = async ({
		values: { createGroupTypeName },
		setSubmitting
	}) => {
		await this.props.createGroupType({
			name: createGroupTypeName
		});

		setSubmitting(false);
		this.setState({
			createGroupTypeModal: false
		});
	};

	toggleUpdate = () => {
		this.setState((prevState) => ({
			updateGroupTypeModal: !prevState.updateGroupTypeModal
		}));
	};

	onUpdate = async ({ grouptype }) => {
		this.setState({
			updateGroupTypeModal: true,
			updateGroupType: grouptype
		});
	};

	onUpdateAction = async ({
		values: { updateGroupTypeId, updateGroupTypeName },
		setSubmitting
	}) => {
		await this.props.updateGroupType({
			id: updateGroupTypeId,
			name: updateGroupTypeName.trim()
		});

		setSubmitting(false);

		this.setState({
			updateGroupTypeModal: false,
			updateGroupType: {}
		});
	};

	toggleDelete = () => {
		this.setState((prevState) => ({
			deleteModal: !prevState.deleteModal
		}));
	};

	onDelete = ({ grouptype }) => {
		var groupsToBeDeleted = this.props.Groups.filter(
			({ group_type }) => group_type === grouptype.name
		);
		this.setState({
			groupsToDelete: groupsToBeDeleted,
			deleteGroupType: grouptype,
			deleteModal: true
		});
	};

	onDeleteAction = async () => {
		const {
			deleteGroupType: { id },
			groupsToDelete
		} = this.state;

		// Delete associated groups
		for (let group in groupsToDelete) {
			let groupId = parseInt(groupsToDelete[group].id);

			// delete all sites from this group
			await this.props.deleteAllGroupSites({ id: groupId });

			// // delete group
			await this.props.deleteGroup({
				id: groupId
			});
		}

		// delete the group in the db
		await this.props.deleteGroupType({
			id: id
		});

		this.setState({
			deleteModal: false,
			deleteGroupType: {}
		});
	};

	buildResultsTable() {
		let { groupTypes = [] } = this.props;
		const { typeFilter, nameFilter, isAdmin } = this.state;

		if (nameFilter !== '') {
			groupTypes = groupTypes.filter(({ name }) => {
				return name.toLowerCase().includes(nameFilter.toLowerCase());
			});
		}
		if (typeFilter !== '') {
			groupTypes = groupTypes.filter(({ group_type }) => {
				return group_type === typeFilter;
			});
		}

		console.log(this);

		return (
			<Row noGutters>
				<Col xs="12">
					<Table>
						<thead>
							<tr>
								<th style={{ width: '60px' }}>#</th>
								<th>ID</th>
								<th colSpan="2">
									Group Type
									<div
										className="position-absolute align-bottom"
										style={{ right: '12px', top: '10px' }}
									>
										<CustomInput
											id="nameFilter"
											name="nameFilter"
											type="text"
											className="mr-2 name-filter"
											onChange={(event) => this.handleChange(event)}
											defaultValue={nameFilter}
											placeholder="Name search"
											bsSize="sm"
											inline
										/>
										{isAdmin && (
											<Button
												size="sm"
												color="info"
												className="mr-2"
												onClick={this.toggleCreateGroupType}
											>
												<i className="fas fa-plus mr-2" />
												Add Group Type
											</Button>
										)}
										<Button
											size="sm"
											outline
											color="secondary"
											onClick={this.onReloadList}
										>
											<i className="fas fa-sync-alt" />
											<span className="sr-only">Refresh</span>
										</Button>
									</div>
								</th>
							</tr>
						</thead>
						<tbody>
							{groupTypes.map((grouptype, ind) => {
								const { name, id } = grouptype;
								const dspInd = ind + 1;
								return (
									<tr key={`grouptype${dspInd}`}>
										<th scope="row" style={{ width: '60px' }}>
											{dspInd}
										</th>
										<td style={{ width: '60px' }}>{id}</td>
										<td style={{ width: '200px' }}>{name}</td>
										<td style={{ width: '15px' }}>
											{isAdmin && (
												<UncontrolledDropdown size="sm">
													<DropdownToggle className="pointered btn-light">
														<i className="fas fa-bars" />
														<span className="sr-only">Actions</span>
													</DropdownToggle>
													<DropdownMenu>
														<DropdownItem
															className="pointered"
															onClick={(e) => this.onUpdate({ grouptype }, e)}
														>
															<i className="fas fa-edit fa-fw mr-2" />
															Update
														</DropdownItem>
														<DropdownItem
															className="pointered"
															onClick={(e) => this.onDelete({ grouptype }, e)}
														>
															<i className="fas fa-trash-alt fa-fw mr-2" />
															Delete
														</DropdownItem>
													</DropdownMenu>
												</UncontrolledDropdown>
											)}
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		);
	}

	buildModals() {
		const {
			updateGroupTypeModal,
			updateGroupType,
			deleteModal,
			deleteGroupType,
			createGroupTypeModal,
			groupsToDelete
		} = this.state;

		return (
			<Fragment>
				<Modal isOpen={createGroupTypeModal} toggle={this.toggleCreate}>
					<Formik
						initialValues={{
							createGroupTypeName: ''
						}}
						validate={(values) => {
							const errors = {};
							if (values.createGroupTypeName.trim().length === 0) {
								errors.createGroupTypeName = 'A name is required';
							} else if (values.createGroupTypeName.trim().length > 256) {
								errors.createGroupTypeName =
									'Name should be no more than 256 characters';
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onCreateGroupTypeAction({ values, setSubmitting });
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setFieldValue
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalHeader toggle={this.toggleCreateGroupType}>
									<i className="fas fa-plus mr-3" />
									Create a new Group Type
								</ModalHeader>
								<ModalBody>
									<FormGroup>
										<Label for="createGroupTypeName">Group Type name</Label>
										<Input
											tag={Field}
											id="createGroupTypeName"
											placeholder="All China Smelters"
											value={values.createGroupTypeName}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={
												errors.createGroupTypeName &&
												touched.createGroupTypeName
											}
											bsSize="sm"
										/>
										<FormText>
											Group Type name should be no more than 256 characters
										</FormText>
										<FormFeedback>{errors.createGroupTypeName}</FormFeedback>
									</FormGroup>
								</ModalBody>
								<ModalFooter>
									<Button
										color="info"
										size="sm"
										type="submit"
										disabled={isSubmitting}
									>
										Create Group Type
									</Button>{' '}
									<Button
										color="secondary"
										size="sm"
										type="button"
										onClick={this.toggleCreateGroupType}
									>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</Modal>

				<Modal isOpen={updateGroupTypeModal} toggle={this.toggleUpdate}>
					<Formik
						initialValues={{
							updateGroupTypeId: updateGroupType.id,
							updateGroupTypeName: updateGroupType.name
						}}
						validate={(values) => {
							const errors = {};
							if (values.updateGroupTypeName.trim().length === 0) {
								errors.updateGroupTypeName = 'A name is required';
							} else if (values.updateGroupTypeName.trim().length > 256) {
								errors.updateGroupTypeName =
									'Name should be no more than 256 characters';
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onUpdateAction({ values, setSubmitting });
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							setFieldValue
						}) => (
							<Form onSubmit={handleSubmit}>
								<ModalHeader toggle={this.toggleUpdate}>
									<i className="fas fa-plus mr-3" />
									Update Group Type
								</ModalHeader>
								<ModalBody>
									<FormGroup>
										<Label for="updateGroupTypeName">Group Type name</Label>
										<Input
											tag={Field}
											id="updateGroupTypeName"
											placeholder="China NPI"
											value={values.updateGroupTypeName}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={
												errors.updateGroupTypeName &&
												touched.updateGroupTypeName
											}
											bsSize="sm"
										/>
										<FormText>
											Group Type name should be no more than 256 characters
										</FormText>
										<FormFeedback>{errors.updateGroupTypeName}</FormFeedback>
									</FormGroup>
								</ModalBody>
								<ModalFooter>
									<Button
										color="info"
										size="sm"
										type="submit"
										disabled={isSubmitting}
									>
										Update Group Type
									</Button>{' '}
									<Button
										color="secondary"
										size="sm"
										type="button"
										onClick={this.toggleUpdate}
									>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</Modal>

				<Modal isOpen={deleteModal} toggle={this.toggleDelete}>
					<ModalHeader toggle={this.toggleDelete}>
						<i className="fas fa-trash-alt mr-3" />
						Delete '<b>{deleteGroupType.name}</b>''
					</ModalHeader>
					<ModalBody>
						<p>
							Please confirm that you really want to delete Group Type '
							<b>{deleteGroupType.name}</b>'. The following associated groups
							will also be deleted:
						</p>
						{groupsToDelete.map((group) => (
							<p key={group.name}>{group.name}</p>
						))}

						<p className="small text-muted">
							Deleting a Group Type will remove associated group relationships
							in the database too, which include Site relationships. A deleted
							Group Type nor the data from these relationships can be recovered.
						</p>
						<p className="large text-danger">PROCEED WITH CAUTION!</p>
					</ModalBody>
					<ModalFooter>
						<Button color="info" size="sm" onClick={this.onDeleteAction}>
							Delete Group
						</Button>{' '}
						<Button color="secondary" size="sm" onClick={this.toggleDelete}>
							Cancel
						</Button>
					</ModalFooter>
				</Modal>
			</Fragment>
		);
	}

	render() {
		return (
			<DocumentTitle title="Gains Admin | Group Types">
				<Container fluid>
					<Crumbs path={[{ title: 'Group Types' }]} />
					{this.buildResultsTable()}
					{this.buildModals()}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ Groups, groupTypes, users }) => {
	return {
		Groups, // redux
		groupTypes, // redux
		users
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchGroups: () => dispatch(fetchGroups()),
	fetchUsers: () => dispatch(fetchUsers()),
	fetchGroupTypes: () => dispatch(fetchGroupTypes()),
	createGroupType: (data) => dispatch(createGroupType(data)),
	updateGroupType: (data) => dispatch(updateGroupType(data)),
	deleteGroupType: (data) => dispatch(deleteGroupType(data)),
	deleteGroup: (data) => dispatch(deleteGroup(data)),
	deleteAllGroupSites: (data) => dispatch(deleteAllGroupSites(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(GroupType);
