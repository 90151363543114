import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

import {
	FETCH_SITES,
	FETCH_SITES_ERR,
	CREATE_SITE,
	CREATE_SITE_ERR,
	UPDATE_SITE,
	UPDATE_SITE_ERR,
	DELETE_SITE,
	DELETE_SITE_ERR,
	FETCH_SITE_TYPES,
	FETCH_SITE_TYPES_ERR,
	FETCH_COMMODITIES,
	FETCH_COMMODITIES_ERR,
	FETCH_SITE_INFO,
	FETCH_SITE_INFO_ERR,
	FETCH_SUBSITES,
	FETCH_SUBSITES_ERR,
	//getAuthHeaders
} from 'actions';
import config from 'config';
import { replacer } from 'utils';


export const fetchSubsiteInfo = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.get(
		'gains-admin-api',
		replacer(config.apiEndpoints.fetchSubsiteInfo, {
			id
		}),
		{
			headers: {}
			//body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_SUBSITES,
				payload: response
			});
			//dispatch(fetchSites());
		},
		(err) => {
			dispatch({
				type: FETCH_SUBSITES_ERR,
				payload: err
			});
		}
	);
};

export const fetchSites = () => async (dispatch) => {
	const request = API.get('gains-admin-api', config.apiEndpoints.sites, {
		headers: {}
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_SITES,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_SITES_ERR,
				payload: err
			})
	);
};

export const createSite = (payload) => async (dispatch) => {
	const request = API.post('gains-admin-api', config.apiEndpoints.createSite, {
		headers: {},
		body: payload
	});

	return request.then(
		(response) => {
			dispatch({
				type: CREATE_SITE,
				payload: response[0]
			});
			dispatch(fetchSites());
			toastr.success('Create Site', 'Site created successfully');
		},
		(err) => {
			dispatch({
				type: CREATE_SITE_ERR,
				payload: err
			});
			toastr.error('Create Site', 'There was an error creating the Site');
		}
	);
};

export const updateSite = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.put(
		'gains-admin-api',
		replacer(config.apiEndpoints.updateSite, {
			id
		}),
		{
			headers: {},
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: UPDATE_SITE,
				payload: response[0]
			});
			dispatch(fetchSites());
			toastr.success('Update Site', 'Site updated successfully');
		},
		(err) => {
			dispatch({
				type: UPDATE_SITE_ERR,
				payload: err
			});
			toastr.error('Update Site', 'There was an error updating the Site');
		}
	);
};

export const deleteSite = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.del(
		'gains-admin-api',
		replacer(config.apiEndpoints.deleteSite, {
			id
		}),
		{
			headers: {}
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_SITE,
				payload: response[0]
			});
			dispatch(fetchSites());
			toastr.success('Delete Site', 'Site deleted successfully');
		},
		(err) => {
			dispatch({
				type: DELETE_SITE_ERR,
				payload: err
			});
			toastr.error('Delete Site', 'There was an error deleting the Site');
		}
	);
};

export const fetchSiteTypes = () => async (dispatch) => {
	const request = API.get('gains-admin-api', config.apiEndpoints.siteTypes, {
		headers: {}
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_SITE_TYPES,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_SITE_TYPES_ERR,
				payload: err
			})
	);
};

export const fetchSiteInfo = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.get(
		'gains-admin-api',
		replacer(config.apiEndpoints.siteInfo, {
			id
		}),
		{
			headers: {}
			//body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: FETCH_SITE_INFO,
				payload: response
			});
			dispatch(fetchSites());
			//toastr.success('Create Site', 'Site updated successfully');
		},
		(err) => {
			dispatch({
				type: FETCH_SITE_INFO_ERR,
				payload: err
			});
			//toastr.error('Update Site', 'There was an error updating the Site');
		}
	);
};

export const fetchCommodities = () => async (dispatch) => {
	const request = API.get('gains-admin-api', config.apiEndpoints.commodities, {
		headers: {}
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_COMMODITIES,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_COMMODITIES_ERR,
				payload: err
			})
	);
};
