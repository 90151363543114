import React, { Component } from 'react';
import { connect } from 'react-redux';
import DocumentTitle from 'react-document-title';
import { Col, Container, Row, Table } from 'reactstrap';

import { fetchCountries } from 'actions';

import { Crumbs } from 'containers';

class Countries extends Component {
	state = {
		idFilter: '',
		nameFilter: '',
		regionFilter: ''
		//idSort: '',
		// nameSort: '',
		// regionSort: ''
		// createModal: false,
		// createGroupTypeModal: false,
		// updateModal: false,
		// updateGroup: {},
		// deleteModal: false,
		// deleteGroup: {},
		// securityGroupModal: false,
		// securityGroupPseudoGroup: {},
		// createSecurityGroupLine: false,
		// updateSecurityGroup: null,
		// deleteSecurityGroupModal: false,
		// deleteSecurityGroup: {},
		// typeFilter: '',
		// nameFilter: ''
	};

	componentDidMount() {
		this.props.fetchCountries();
	}

	onReloadList = () => {
		this.props.fetchCountries();
	};

	handleChange = (event) => {
		// let sortParams = ['nameSort', 'regionSort', 'idSort']

		// for (let i in sortParams) {
		//     if (this.state[event.target.id] in sortParams) {
		//         sortParams.pop(this.state[event.target.id])
		//     }
		//     var x = sortParams[i]
		//     this.setState({
		//         [x]: ''
		//     })
		// }

		// if (this.state[event.target.id] === 'AZ') {
		// 	this.setState({
		// 		[event.target.id]: 'ZA'
		// 	});
		// } else {
		// 	this.setState({
		// 		[event.target.id]: 'AZ'
		// 	});
		// }

		this.setState({
			[event.target.id]: event.target.value
		});
	};

	buildResultsTable() {
		console.log(this);
		let { countries = [] } = this.props;
		//let { regionSort, nameSort, idSort } = this.state;

		// if (regionSort === 'AZ') {
		// 	countries.sort(function (a, b) {
		// 		return b.region < a.region ? 1 : b.region > a.region ? -1 : 0;
		// 	});
		// } else if (regionSort === 'ZA') {
		// 	countries.sort(function (a, b) {
		// 		return a.region < b.region ? 1 : a.region > b.region ? -1 : 0;
		// 	});
		// }

		// if (nameSort === 'AZ') {
		// 	countries.sort(function (a, b) {
		// 		return b.name < a.name
		// 			? 1 // if b should come earlier, push a to end
		// 			: b.region > a.region
		// 			? -1 // if b should come later, push a to begin
		// 			: 0; // a and b are equal
		// 	});
		// } else if (nameSort === 'ZA') {
		// 	countries.sort(function (a, b) {
		// 		return a.name < b.name
		// 			? 1 // if b should come earlier, push a to end
		// 			: a.name > b.name
		// 			? -1 // if b should come later, push a to begin
		// 			: 0; // a and b are equal
		// 	});
		// }

		// if (idSort === 'AZ') {
		// 	countries.sort(function (a, b) {
		// 		return b.id < a.id
		// 			? 1 // if b should come earlier, push a to end
		// 			: b.region > a.region
		// 			? -1 // if b should come later, push a to begin
		// 			: 0; // a and b are equal
		// 	});
		// } else if (idSort === 'ZA') {
		// 	countries.sort(function (a, b) {
		// 		return a.id < b.id
		// 			? 1 // if b should come earlier, push a to end
		// 			: a.id > b.id
		// 			? -1 // if b should come later, push a to begin
		// 			: 0; // a and b are equal
		// 	});
		// }

		return (
			<Row noGutters>
				<Col xs="12">
					<Table>
						<thead>
							<tr>
								<th style={{ width: '60px' }}>#</th>
								<th>
									ID{' '}
									{/* <button>
										<i
											id="idSort"
											onClick={this.handleChange}
											className="pl-4 fa-solid fa-sort"
										></i>
									</button>{' '} */}
								</th>
								<th>
									Name{' '}
									{/* <button>
										<i
											id="nameSort"
											onClick={this.handleChange}
											class="pl-4 fa-solid fa-sort"
										></i>
									</button>{' '} */}
								</th>
								<th>
									Region{' '}
									{/* <button>
										<i
											id="regionSort"
											onClick={this.handleChange}
											class="pl-4 fa-solid fa-sort"
										></i>
									</button> */}
								</th>
								{/*<th colSpan="2">
									Group name
									<div
										className="position-absolute align-bottom"
										style={{ right: '12px', top: '10px' }}
									>
										 <CustomInput
											id="nameFilter"
											name="nameFilter"
											type="text"
											className="mr-2 name-filter"
											onChange={(event) => this.handleChange(event)}
											defaultValue={nameFilter}
											placeholder="Name search"
											bsSize="sm"
											inline
										/>
										<CustomInput
											id="typeFilter"
											name="typeFilter"
											type="select"
											className="mr-2 facet-select"
											onChange={(event) => this.handleChange(event)}
											defaultValue={typeFilter}
											bsSize="sm"
											inline
										>
											<option value="">Any type</option>
											{groupTypes.map((type) => (
												<option value={type.name} key={type.id}>
													{type.name}
												</option>
											))}
										</CustomInput>
										<Button
											size="sm"
											color="info"
											className="mr-2"
											onClick={this.toggleCreate}
										>
											<i className="fas fa-plus mr-2" />
											Add Group
										</Button>
										<Button size="sm" color="info" className="mr-2" onClick={this.toggleCreateGroupType}>
											<i className="fas fa-plus mr-2" />Add Group Type
										</Button>
										<Button
											size="sm"
											outline
											color="secondary"
											onClick={this.onReloadList}
										>
											<i className="fas fa-sync-alt" />
											<span className="sr-only">Refresh</span>
										</Button> 
									</div>
								</th>*/}
							</tr>
						</thead>
						<tbody>
							{countries.map((country, ind) => {
								const { id, name, region } = country;
								const dspInd = ind + 1;
								return (
									<tr key={`region${dspInd}`}>
										<th scope="row" style={{ width: '60px' }}>
											{dspInd}
										</th>
										<td style={{ width: '60px' }}>{id}</td>
										<td style={{ width: '80px' }}>{name}</td>
										<td style={{ width: '200px' }}>{region}</td>
										<td style={{ width: '45px' }}>
											{/* <UncontrolledDropdown size="sm">
												<DropdownToggle className="pointered btn-light">
													<i className="fas fa-bars" />
													<span className="sr-only">Actions</span>
												</DropdownToggle>
												<DropdownMenu>
													<DropdownItem
														className="pointered"
														onClick={(e) => this.onUpdate({ group }, e)}
													>
														<i className="fas fa-edit fa-fw mr-2" />
														Update
													</DropdownItem>
													<DropdownItem
														className="pointered"
														onClick={(e) =>
															this.onDisplaySecurityGroups({ group }, e)
														}
													>
														<i className="fas fa-list fa-fw mr-2" />
														Security groups
													</DropdownItem>
													<DropdownItem divider />
													<DropdownItem
														className="pointered"
														onClick={(e) => this.onDelete({ group }, e)}
													>
														<i className="fas fa-trash-alt fa-fw mr-2" />
														Delete
													</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown> */}
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		);
	}

	// buildModals() {
	// 	let { groupTypes = [] } = this.props;

	// 	const {
	// 		createModal,
	// 		updateModal,
	// 		updateGroup,
	// 		deleteModal,
	// 		deleteGroup,
	// 		createGroupTypeModal,
	// 		createSecurityGroupLine,
	// 		securityGroupModal,
	// 		securityGroupPseudoGroup: { name: securityGroupPseudoGroupName = '' },
	// 		updateSecurityGroup,
	// 		deleteSecurityGroupModal,
	// 		deleteSecurityGroup
	// 	} = this.state;

	// 	const { groupSites, sites, pseudoGroupSecurityGroups } = this.props;

	// 	const multiSelectSites = sites
	// 		.sort((a, b) => a.name.localeCompare(b.name))
	// 		.map(({ id, name }) => {
	// 			return {
	// 				value: id,
	// 				label: name
	// 			};
	// 		});

	// 		const multiSelectSitesIDs = sites
	// 		.sort((a, b) => a.name.localeCompare(b.name))
	// 		.map(({ id }) => {
	// 			return {
	// 				value: id,
	// 				label: id
	// 			};
	// 		});

	// 	let updateGroupInitialSites = [];
	// 	let updateGroupInitialSitesIDs = [];

	// 	if (updateGroup && groupSites) {
	// 		let myList = [];
	// 		groupSites.map(myFunc);
	// 		function myFunc(site) {
	// 			myList.push(site['id']);
	// 			updateGroupInitialSitesIDs.push(site['id'])
	// 		}

	// 		let updateGroupSites = myList;
	// 		updateGroupInitialSites = multiSelectSites.filter(({ value }) =>
	// 			updateGroupSites.includes(value)
	// 		);

	// 		updateGroupInitialSitesIDs = multiSelectSitesIDs.filter(({ value }) =>
	// 			updateGroupSites.includes(value)
	// 		);
	// 		updateGroupInitialSitesIDs.sort((a,b) => a-b)
	// 	}

	// 	return (
	// 		<Fragment>
	// 			<Modal isOpen={createModal} toggle={this.toggleCreate}>
	// 				<Formik
	// 					initialValues={{
	// 						createGroupName: '',
	// 						createGroupType: '',
	// 						createGroupSites: []
	// 					}}
	// 					validate={(values) => {
	// 						const errors = {};
	// 						if (values.createGroupName.trim().length === 0) {
	// 							errors.createGroupName = 'A name is required';
	// 						} else if (values.createGroupName.trim().length > 256) {
	// 							errors.createGroupName =
	// 								'Name should be no more than 256 characters';
	// 						}
	// 						if (values.createGroupType.trim().length === 0) {
	// 							errors.createGroupType = 'A type is required';
	// 						}
	// 						return errors;
	// 					}}
	// 					onSubmit={(values, { setSubmitting }) => {
	// 						//console.log(values)
	// 						this.onCreateAction({ values, setSubmitting });
	// 					}}
	// 				>
	// 					{({
	// 						values,
	// 						errors,
	// 						touched,
	// 						handleChange,
	// 						handleBlur,
	// 						handleSubmit,
	// 						isSubmitting,
	// 						setFieldValue
	// 					}) => (
	// 						<Form onSubmit={handleSubmit}>
	// 							<ModalHeader toggle={this.toggleCreate}>
	// 								<i className="fas fa-plus mr-3" />
	// 								Create a new Group
	// 							</ModalHeader>
	// 							<ModalBody>
	// 								<FormGroup>
	// 									<Label for="createGroupName">Group name</Label>
	// 									<Input
	// 										tag={Field}
	// 										id="createGroupName"
	// 										placeholder="China NPI"
	// 										value={values.createGroupName}
	// 										onChange={handleChange}
	// 										onBlur={handleBlur}
	// 										invalid={
	// 											errors.createGroupName && touched.createGroupName
	// 										}
	// 										bsSize="sm"
	// 									/>
	// 									<FormText>
	// 										Group name should be no more than 256 characters
	// 									</FormText>
	// 									<FormFeedback>{errors.createGroupName}</FormFeedback>
	// 								</FormGroup>
	// 								<Row form>
	// 									<Col md={6}>
	// 										<FormGroup>
	// 											<Label for="createGroupType">Type</Label>
	// 											<CustomInput
	// 												id="createGroupType"
	// 												type="select"
	// 												onChange={handleChange}
	// 												onBlur={handleBlur}
	// 												defaultValue={values.createGroupType}
	// 												invalid={
	// 													errors.createGroupType && touched.createGroupType
	// 												}
	// 												bsSize="sm"
	// 											>
	// 												<option value="">Please select a type</option>
	// 												{groupTypes.map((type) => (
	// 													<option value={type.id} key={type.id}>
	// 														{type.name}
	// 													</option>
	// 												))}
	// 											</CustomInput>
	// 											<FormText>A type is required</FormText>
	// 											<FormFeedback>{errors.createGroupType}</FormFeedback>
	// 										</FormGroup>
	// 									</Col>
	// 								</Row>
	// 								<FormGroup>
	// 									<Label for="createGroupSites">Sites</Label>
	// 									<Select
	// 										id="createGroupSites"
	// 										isMulti
	// 										options={multiSelectSites}
	// 										value={values.createGroupSites}
	// 										onChange={(option) => {
	// 											setFieldValue(
	// 												'createGroupSites',
	// 												option ? option.map((item) => item) : []
	// 											);
	// 										}}
	// 										classNamePrefix="react-select"
	// 										placeholder="Select Sites..."
	// 										styles={multiSelectStyle}
	// 									/>
	// 									<FormText>
	// 										Please select Sites that should belong to this pseudo
	// 										group
	// 									</FormText>
	// 								</FormGroup>
	// 							</ModalBody>
	// 							<ModalFooter>
	// 								<Button
	// 									color="info"
	// 									size="sm"
	// 									type="submit"
	// 									disabled={isSubmitting}
	// 								>
	// 									Create Group
	// 								</Button>{' '}
	// 								<Button
	// 									color="secondary"
	// 									size="sm"
	// 									type="button"
	// 									onClick={this.toggleCreate}
	// 								>
	// 									Cancel
	// 								</Button>
	// 							</ModalFooter>
	// 						</Form>
	// 					)}
	// 				</Formik>
	// 			</Modal>

	// 			<Modal isOpen={createGroupTypeModal} toggle={this.toggleCreate}>
	// 				<Formik
	// 					initialValues={{
	// 						createGroupTypeName: ''
	// 					}}
	// 					validate={(values) => {
	// 						const errors = {};
	// 						if (values.createGroupTypeName.trim().length === 0) {
	// 							errors.createGroupTypeName = 'A name is required';
	// 						} else if (values.createGroupTypeName.trim().length > 256) {
	// 							errors.createGroupTypeName =
	// 								'Name should be no more than 256 characters';
	// 						}
	// 						return errors;
	// 					}}
	// 					onSubmit={(values, { setSubmitting }) => {
	// 						this.onCreateGroupTypeAction({ values, setSubmitting });
	// 					}}
	// 				>
	// 					{({
	// 						values,
	// 						errors,
	// 						touched,
	// 						handleChange,
	// 						handleBlur,
	// 						handleSubmit,
	// 						isSubmitting,
	// 						setFieldValue
	// 					}) => (
	// 						<Form onSubmit={handleSubmit}>
	// 							<ModalHeader toggle={this.toggleCreateGroupType}>
	// 								<i className="fas fa-plus mr-3" />
	// 								Create a new Group Type
	// 							</ModalHeader>
	// 							<ModalBody>
	// 								<FormGroup>
	// 									<Label for="createGroupTypeName">Group Type name</Label>
	// 									<Input
	// 										tag={Field}
	// 										id="createGroupTypeName"
	// 										placeholder="All China Smelters"
	// 										value={values.createGroupTypeName}
	// 										onChange={handleChange}
	// 										onBlur={handleBlur}
	// 										invalid={
	// 											errors.createGroupTypeName &&
	// 											touched.createGroupTypeName
	// 										}
	// 										bsSize="sm"
	// 									/>
	// 									<FormText>
	// 										Group Type name should be no more than 256 characters
	// 									</FormText>
	// 									<FormFeedback>{errors.createGroupTypeName}</FormFeedback>
	// 								</FormGroup>
	// 							</ModalBody>
	// 							<ModalFooter>
	// 								<Button
	// 									color="info"
	// 									size="sm"
	// 									type="submit"
	// 									disabled={isSubmitting}
	// 								>
	// 									Create Group Type
	// 								</Button>{' '}
	// 								<Button
	// 									color="secondary"
	// 									size="sm"
	// 									type="button"
	// 									onClick={this.toggleCreateGroupType}
	// 								>
	// 									Cancel
	// 								</Button>
	// 							</ModalFooter>
	// 						</Form>
	// 					)}
	// 				</Formik>
	// 			</Modal>

	// 			<Modal isOpen={updateModal} toggle={this.toggleUpdate}>
	// 				<Formik
	// 					initialValues={{
	// 						updateGroupId: updateGroup.id,
	// 						updateGroupName: updateGroup.name,
	// 						updateGroupType: updateGroup.group_type,
	// 						updateGroupSites: updateGroupInitialSites,
	// 						updateGroupSitesIDs: updateGroupInitialSitesIDs
	// 					}}
	// 					validate={(values) => {
	// 						const errors = {};
	// 						if (values.updateGroupName.trim().length === 0) {
	// 							errors.updateGroupName = 'A name is required';
	// 						} else if (values.updateGroupName.trim().length > 256) {
	// 							errors.updateGroupName =
	// 								'Name should be no more than 256 characters';
	// 						}
	// 						if (values.updateGroupType.trim().length === 0) {
	// 							errors.updateGroupType = 'A type is required';
	// 						}
	// 						return errors;
	// 					}}
	// 					onSubmit={(values, { setSubmitting }) => {
	// 						this.onUpdateAction({ values, setSubmitting });
	// 					}}
	// 				>
	// 					{({
	// 						values,
	// 						errors,
	// 						touched,
	// 						handleChange,
	// 						handleBlur,
	// 						handleSubmit,
	// 						isSubmitting,
	// 						setFieldValue
	// 					}) => (
	// 						<Form onSubmit={handleSubmit}>
	// 							<ModalHeader toggle={this.toggleUpdate}>
	// 								<i className="fa-solid fa-pen mr-3" />
	// 								Update <b>{values.updateGroupName}</b>
	// 							</ModalHeader>
	// 							<ModalBody>
	// 								<FormGroup>
	// 									<Label for="updateGroupName">Group name</Label>
	// 									<Input
	// 										tag={Field}
	// 										id="updateGroupName"
	// 										placeholder="China NPI"
	// 										value={values.updateGroupName}
	// 										onChange={handleChange}
	// 										onBlur={handleBlur}
	// 										invalid={
	// 											errors.updateGroupName && touched.updateGroupName
	// 										}
	// 										bsSize="sm"
	// 									/>
	// 									<FormText>
	// 										Group name should be no more than 256 characters
	// 									</FormText>
	// 									<FormFeedback>{errors.updateGroupName}</FormFeedback>
	// 								</FormGroup>
	// 								<Row form>
	// 									<Col md={6}>
	// 										<FormGroup>
	// 											<Label for="updateGroupType">Type</Label>
	// 											<CustomInput
	// 												id="updateGroupType"
	// 												type="select"
	// 												onChange={handleChange}
	// 												onBlur={handleBlur}
	// 												defaultValue={values.updateGroupType}
	// 												invalid={
	// 													errors.updateGroupType && touched.updateGroupType
	// 												}
	// 												bsSize="sm"
	// 											>
	// 												{groupTypes.map((type) => (
	// 													<option value={type.name} key={type.name}>
	// 														{type.name}
	// 													</option>
	// 												))}
	// 											</CustomInput>
	// 											<FormText>A type is required</FormText>
	// 											<FormFeedback>{errors.updateGroupType}</FormFeedback>
	// 										</FormGroup>
	// 									</Col>
	// 								</Row>
	// 								<FormGroup>
	// 									<Label for="updateGroupSites">
	// 										Sites ({updateGroupInitialSites.length})
	// 									</Label>
	// 									<Select
	// 										id="updateGroupSites"
	// 										isMulti
	// 										options={multiSelectSites}
	// 										value={values.updateGroupSites}
	// 										onChange={(option) => {
	// 											setFieldValue(
	// 												'updateGroupSites',
	// 												option ? option.map((item) => item) : []
	// 											);
	// 										}}
	// 										classNamePrefix="react-select"
	// 										placeholder="Select Smelters..."
	// 										styles={multiSelectStyle}
	// 									/>

	// 									{/* <Select
	// 										id="updateGroupSitesIDs"
	// 										isMulti
	// 										options={multiSelectSitesIDs}
	// 										value={values.updateGroupSitesIDs}
	// 										onChange={(option) => {
	// 											setFieldValue(
	// 												'updateGroupSitesIDs',
	// 												option ? option.map((item) => item) : []
	// 											);
	// 										}}
	// 										classNamePrefix="react-select"
	// 										placeholder="Select Smelters..."
	// 										styles={multiSelectStyle}
	// 									/>
	// 									<FormText>
	// 										Please select Sites that should belong to this Group
	// 									</FormText> */}
	// 								</FormGroup>
	// 							</ModalBody>
	// 							<ModalFooter>
	// 								<Button
	// 									color="info"
	// 									size="sm"
	// 									type="submit"
	// 									disabled={isSubmitting}
	// 								>
	// 									Update Group
	// 								</Button>{' '}
	// 								<Button
	// 									color="secondary"
	// 									size="sm"
	// 									type="button"
	// 									onClick={this.toggleUpdate}
	// 								>
	// 									Cancel
	// 								</Button>
	// 							</ModalFooter>
	// 						</Form>
	// 					)}
	// 				</Formik>
	// 			</Modal>

	// 			<Modal isOpen={deleteModal} toggle={this.toggleDelete}>
	// 				<ModalHeader toggle={this.toggleDelete}>
	// 					<i className="fas fa-trash-alt mr-3" />
	// 					Delete a Group
	// 				</ModalHeader>
	// 				<ModalBody>
	// 					<p>
	// 						Please confirm that you really want to delete Group '
	// 						{deleteGroup.name}'?
	// 					</p>
	// 					<p className="small text-muted">
	// 						Deleting a Group will remove associated group relationships in the
	// 						database too, which include Site relationships and Security Group
	// 						relationships. A deleted Group nor the data from these
	// 						relationships can be recovered.
	// 					</p>
	// 					<p className="small text-danger">PROCEED WITH CAUTION!</p>
	// 				</ModalBody>
	// 				<ModalFooter>
	// 					<Button color="info" size="sm" onClick={this.onDeleteAction}>
	// 						Delete Group
	// 					</Button>{' '}
	// 					<Button color="secondary" size="sm" onClick={this.toggleDelete}>
	// 						Cancel
	// 					</Button>
	// 				</ModalFooter>
	// 			</Modal>

	// 			<Modal
	// 				size="lg"
	// 				isOpen={securityGroupModal}
	// 				toggle={this.togglePseudoGroupSecurityGroups}
	// 			>
	// 				<ModalHeader toggle={this.togglePseudoGroupSecurityGroups}>
	// 					<i className="fas fa-list mr-3" />
	// 					Manage security groups for Group '{securityGroupPseudoGroupName}'
	// 				</ModalHeader>
	// 				<ModalBody>
	// 					<Table striped size="sm" className="reducedBorders">
	// 						<thead>
	// 							<tr>
	// 								<th style={{ width: '5%' }}>#</th>
	// 								<th style={{ width: '55%' }}>Group</th>
	// 								<th style={{ width: '25%' }}>Type</th>
	// 								<th style={{ width: '7%', textAlign: 'center' }}>Sort</th>
	// 								<th style={{ width: '8%', textAlign: 'center' }} />
	// 							</tr>
	// 						</thead>
	// 						<tbody>
	// 							{pseudoGroupSecurityGroups.map((securitygroup, ind) => {
	// 								const { id, cognito_group, sort_order, type } = securitygroup;
	// 								const dspInd = ind + 1;

	// 								if (updateSecurityGroup && updateSecurityGroup.id === id) {
	// 									return (
	// 										<Formik
	// 											initialValues={{
	// 												updateSecurityGroupCognitoGroup:
	// 													updateSecurityGroup.cognito_group,
	// 												updateSecurityGroupType: updateSecurityGroup.type,
	// 												updateSecurityGroupSort:
	// 													updateSecurityGroup.sort_order
	// 											}}
	// 											validate={(values) => {
	// 												const errors = {};
	// 												if (
	// 													values.updateSecurityGroupCognitoGroup.trim()
	// 														.length === 0
	// 												) {
	// 													errors.updateSecurityGroupCognitoGroup =
	// 														'A group is required';
	// 												}
	// 												if (
	// 													values.updateSecurityGroupType.trim().length === 0
	// 												) {
	// 													errors.updateSecurityGroupType =
	// 														'A type is required';
	// 												}
	// 												if (
	// 													values.updateSecurityGroupSort.toString().trim()
	// 														.length === 0
	// 												) {
	// 													errors.updateSecurityGroupSort =
	// 														'A sort is required';
	// 												}
	// 												return errors;
	// 											}}
	// 											onSubmit={(values, { setSubmitting }) => {
	// 												this.onUpdateSecurityGroupAction({
	// 													values,
	// 													setSubmitting
	// 												});
	// 											}}
	// 										>
	// 											{({
	// 												values,
	// 												errors,
	// 												touched,
	// 												handleChange,
	// 												handleBlur,
	// 												handleSubmit
	// 											}) => (
	// 												<tr key={`securitygroup${dspInd}`}>
	// 													<th scope="row" style={{ width: '5%' }}>
	// 														{dspInd}
	// 													</th>
	// 													<td style={{ width: '55%' }}>
	// 														<CustomInput
	// 															id="updateSecurityGroupCognitoGroup"
	// 															type="select"
	// 															onChange={handleChange}
	// 															onBlur={handleBlur}
	// 															defaultValue={
	// 																values.updateSecurityGroupCognitoGroup
	// 															}
	// 															invalid={
	// 																errors.updateSecurityGroupCognitoGroup &&
	// 																touched.updateSecurityGroupCognitoGroup
	// 															}
	// 															bsSize="sm"
	// 														>
	// 															<option value="">Please select a group</option>
	// 															{config.cognitoSecurityGroups.map(
	// 																(grp, idx) => (
	// 																	<option value={grp} key={`cogGrg${idx}`}>
	// 																		{grp}
	// 																	</option>
	// 																)
	// 															)}
	// 														</CustomInput>
	// 													</td>
	// 													<td style={{ width: '25%' }}>
	// 														<Input
	// 															tag={Field}
	// 															id="updateSecurityGroupType"
	// 															placeholder="default"
	// 															value={values.updateSecurityGroupType}
	// 															onChange={handleChange}
	// 															onBlur={handleBlur}
	// 															invalid={
	// 																errors.updateSecurityGroupType &&
	// 																touched.updateSecurityGroupType
	// 															}
	// 															bsSize="sm"
	// 														/>
	// 													</td>
	// 													<td style={{ width: '7%', textAlign: 'center' }}>
	// 														<Input
	// 															tag={Field}
	// 															id="updateSecurityGroupSort"
	// 															placeholder="0"
	// 															value={values.updateSecurityGroupSort}
	// 															onChange={handleChange}
	// 															onBlur={handleBlur}
	// 															invalid={
	// 																errors.updateSecurityGroupSort &&
	// 																touched.updateSecurityGroupSort
	// 															}
	// 															bsSize="sm"
	// 														/>
	// 													</td>
	// 													<td style={{ width: '8%', textAlign: 'right' }}>
	// 														<i
	// 															className="fas fa-check fa-fw pointered text-success mr-1 align-middle"
	// 															onClick={handleSubmit}
	// 														>
	// 															<span className="sr-only">
	// 																Save security group update
	// 															</span>
	// 														</i>
	// 														<i
	// 															className="fas fa-ban fa-fw pointered text-danger align-middle"
	// 															onClick={(e) =>
	// 																this.onCancelUpdateSecurityGroup()
	// 															}
	// 														>
	// 															<span className="sr-only">
	// 																Cancel security group update
	// 															</span>
	// 														</i>
	// 													</td>
	// 												</tr>
	// 											)}
	// 										</Formik>
	// 									);
	// 								}

	// 								return (
	// 									<tr key={`securitygroup${dspInd}`}>
	// 										<th scope="row" style={{ width: '5%' }}>
	// 											{dspInd}
	// 										</th>
	// 										<td style={{ width: '55%' }}>{cognito_group}</td>
	// 										<td style={{ width: '25%' }}>{type}</td>
	// 										<td style={{ width: '7%', textAlign: 'center' }}>
	// 											{sort_order}
	// 										</td>
	// 										<td style={{ width: '8%', textAlign: 'right' }}>
	// 											<i
	// 												className="far fa-edit fa-fw pointered text-muted mr-1"
	// 												onClick={(e) =>
	// 													this.onUpdateSecurityGroup({ securitygroup }, e)
	// 												}
	// 											>
	// 												<span className="sr-only">Edit security group</span>
	// 											</i>
	// 											<i
	// 												className="far fa-trash-alt fa-fw pointered text-danger"
	// 												onClick={(e) =>
	// 													this.onDeleteSecurityGroup({ securitygroup }, e)
	// 												}
	// 											>
	// 												<span className="sr-only">Delete security group</span>
	// 											</i>
	// 										</td>
	// 									</tr>
	// 								);
	// 							})}
	// 							{!createSecurityGroupLine && (
	// 								<tr>
	// 									<th />
	// 									<td style={{ width: '55%' }} />
	// 									<td style={{ width: '25%' }} />
	// 									<td style={{ width: '7%' }} />
	// 									<td style={{ width: '8%', textAlign: 'right' }}>
	// 										<i
	// 											className="fas fa-plus fa-fw pointered text-success align-middle"
	// 											onClick={(e) => this.onCreateSecurityGroup()}
	// 										>
	// 											<span className="sr-only">
	// 												Add security group create
	// 											</span>
	// 										</i>
	// 									</td>
	// 								</tr>
	// 							)}
	// 							{createSecurityGroupLine && (
	// 								<Formik
	// 									initialValues={{
	// 										createSecurityGroupCognitoGroup: '',
	// 										createSecurityGroupType: '',
	// 										createSecurityGroupSort: '0'
	// 									}}
	// 									validate={(values) => {
	// 										const errors = {};
	// 										if (
	// 											values.createSecurityGroupCognitoGroup.trim().length ===
	// 											0
	// 										) {
	// 											errors.createSecurityGroupCognitoGroup =
	// 												'A group is required';
	// 										}
	// 										if (values.createSecurityGroupType.trim().length === 0) {
	// 											errors.createSecurityGroupType = 'A type is required';
	// 										}
	// 										if (values.createSecurityGroupSort.trim().length === 0) {
	// 											errors.createSecurityGroupSort = 'A sort is required';
	// 										}
	// 										return errors;
	// 									}}
	// 									onSubmit={(values, { setSubmitting }) => {
	// 										this.onCreateSecurityGroupAction({
	// 											values,
	// 											setSubmitting
	// 										});
	// 									}}
	// 								>
	// 									{({
	// 										values,
	// 										errors,
	// 										touched,
	// 										handleChange,
	// 										handleBlur,
	// 										handleSubmit
	// 									}) => (
	// 										<tr>
	// 											<th scope="row" style={{ width: '5%' }}>
	// 												{pseudoGroupSecurityGroups.length + 1}
	// 											</th>
	// 											<td style={{ width: '55%' }}>
	// 												<CustomInput
	// 													id="createSecurityGroupCognitoGroup"
	// 													type="select"
	// 													onChange={handleChange}
	// 													onBlur={handleBlur}
	// 													defaultValue={
	// 														values.createSecurityGroupCognitoGroup
	// 													}
	// 													invalid={
	// 														errors.createSecurityGroupCognitoGroup &&
	// 														touched.createSecurityGroupCognitoGroup
	// 													}
	// 													bsSize="sm"
	// 												>
	// 													<option value="">Please select a group</option>
	// 													{config.cognitoSecurityGroups.map((grp, idx) => (
	// 														<option value={grp} key={`cogGrg${idx}`}>
	// 															{grp}
	// 														</option>
	// 													))}
	// 												</CustomInput>
	// 											</td>
	// 											<td style={{ width: '25%' }}>
	// 												<Input
	// 													tag={Field}
	// 													id="createSecurityGroupType"
	// 													placeholder="default"
	// 													value={values.createSecurityGroupType}
	// 													onChange={handleChange}
	// 													onBlur={handleBlur}
	// 													invalid={
	// 														errors.createSecurityGroupType &&
	// 														touched.createSecurityGroupType
	// 													}
	// 													bsSize="sm"
	// 												/>
	// 											</td>
	// 											<td style={{ width: '7%', textAlign: 'center' }}>
	// 												<Input
	// 													tag={Field}
	// 													id="createSecurityGroupSort"
	// 													placeholder="0"
	// 													value={values.createSecurityGroupSort}
	// 													onChange={handleChange}
	// 													onBlur={handleBlur}
	// 													invalid={
	// 														errors.createSecurityGroupSort &&
	// 														touched.createSecurityGroupSort
	// 													}
	// 													bsSize="sm"
	// 												/>
	// 											</td>
	// 											<td style={{ width: '8%', textAlign: 'right' }}>
	// 												<i
	// 													className="fas fa-check fa-fw pointered text-success mr-1 align-middle"
	// 													onClick={handleSubmit}
	// 												>
	// 													<span className="sr-only">
	// 														Save security group create
	// 													</span>
	// 												</i>
	// 												<i
	// 													className="fas fa-ban fa-fw pointered text-danger align-middle"
	// 													onClick={(e) => this.toggleCreateSecurityGroup()}
	// 												>
	// 													<span className="sr-only">
	// 														Cancel security group create
	// 													</span>
	// 												</i>
	// 											</td>
	// 										</tr>
	// 									)}
	// 								</Formik>
	// 							)}
	// 						</tbody>
	// 					</Table>
	// 				</ModalBody>
	// 				<ModalFooter>
	// 					<Button
	// 						color="secondary"
	// 						size="sm"
	// 						onClick={this.togglePseudoGroupSecurityGroups}
	// 					>
	// 						Close
	// 					</Button>
	// 				</ModalFooter>
	// 			</Modal>

	// 			<Modal
	// 				isOpen={deleteSecurityGroupModal}
	// 				toggle={this.toggleSecurityGroupDelete}
	// 			>
	// 				<ModalHeader toggle={this.toggleSecurityGroupDelete}>
	// 					<i className="fas fa-trash-alt mr-3" />
	// 					Delete a security group
	// 				</ModalHeader>
	// 				<ModalBody>
	// 					<p>
	// 						Please confirm that you really want to delete the '
	// 						{deleteSecurityGroup.cognito_group}' security group?
	// 					</p>
	// 					<p className="small text-muted">
	// 						A deleted security group cannot be recovered.
	// 					</p>
	// 					<p className="small text-danger">PROCEED WITH CAUTION!</p>
	// 				</ModalBody>
	// 				<ModalFooter>
	// 					<Button
	// 						color="primary"
	// 						size="sm"
	// 						onClick={this.onDeleteSecurityGroupAction}
	// 					>
	// 						Delete security group
	// 					</Button>{' '}
	// 					<Button
	// 						color="secondary"
	// 						size="sm"
	// 						onClick={this.toggleSecurityGroupDelete}
	// 					>
	// 						Cancel
	// 					</Button>
	// 				</ModalFooter>
	// 			</Modal>
	// 		</Fragment>
	// 	);
	// }

	render() {
		return (
			<DocumentTitle title="Gains Admin | Countries">
				<Container fluid>
					<Crumbs path={[{ title: 'Countries' }]} />
					{this.buildResultsTable()}
					{/* {this.buildModals()} */}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ countries }) => {
	return {
		countries
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchCountries: () => dispatch(fetchCountries())
});

export default connect(mapStateToProps, mapDispatchToProps)(Countries);
