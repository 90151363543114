import React, { Component } from 'react';
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText, Label } from 'reactstrap';

import './ItemListPicker.scss';

export default class ItemListPicker extends Component {
	state = {
		selectedValue: '',
		selectedIndex: -1
	};

	async componentDidMount() {
		const { selectedItem, items } = this.props;
		await this.setState({
			selectedValue: selectedItem.value,
			selectedIndex: items.findIndex((item) => {
				return item.value === selectedItem.value;
			})
		});
	}

	emitValue() {
		const { selectedValue } = this.state;
		const { onItemSelect } = this.props;
		onItemSelect(selectedValue);
	}

	onChange = async ({ target: { value } }) => {
		const { items } = this.props;
		if (value) {
			await this.setState({
				selectedValue: value,
				selectedIndex: items.findIndex((item) => {
					return item.value === value;
				})
			});
		}
		this.emitValue();
	};

	onPickNextItem = async () => {
		const { items } = this.props;
		const { selectedIndex } = this.state;
		const newIndex = selectedIndex - 1;
		await this.setState({
			selectedValue: items[newIndex].value,
			selectedIndex: newIndex
		});
		this.emitValue();
	};

	onPickPreviousItem = async () => {
		const { items } = this.props;
		const { selectedIndex } = this.state;
		const newIndex = selectedIndex + 1;
		await this.setState({
			selectedValue: items[newIndex].value,
			selectedIndex: newIndex
		});
		this.emitValue();
	};

	render() {
		const { id, icon, items } = this.props;
		const { selectedValue } = this.state;

		let firstItem = selectedValue === items[items.length - 1].value;
		let lastItem = selectedValue === items[0].value;

		return (
			<Label for={`ItemListPicker-${id}`} className="item-list-picker align-middle mr-3">
				<span className="sr-only">Please select an item</span>
				<InputGroup>
					<InputGroupAddon addonType="prepend">
						<InputGroupText>
							<i className={icon} />
						</InputGroupText>
					</InputGroupAddon>
					<Input
						className="custom-select pointered"
						type="select"
						id={`ItemListPicker-${id}`}
						onChange={this.onChange}
						value={selectedValue}
					>
						{items.map(({ name, value }, i) => {
							return (
								<option value={value} key={`ItemListPicker-${id}-${i}`}>
									{name}
								</option>
							);
						})}
					</Input>
					<InputGroupAddon addonType="append">
						<Button outline color="secondary" disabled={firstItem} onClick={this.onPickPreviousItem}>
							<i className="fas fa-arrow-left" />
						</Button>
					</InputGroupAddon>
					<InputGroupAddon addonType="append">
						<Button outline color="secondary" disabled={lastItem} onClick={this.onPickNextItem}>
							<i className="fas fa-arrow-right" />
						</Button>
					</InputGroupAddon>
				</InputGroup>
			</Label>
		);
	}
}
