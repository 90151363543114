import {
	FETCH_USERS,
} from 'actions';

const usersReducer = (state = [], action) => {
	switch (action.type) {
		case FETCH_USERS:
			return action.payload;
		default:
			return state;
	}
};

// const usersProductionReducer = (state = [], action) => {
// 	switch (action.type) {
// 		case FETCH_USERS_PRODUCTION:
// 			return action.payload;
// 		default:
// 			return state;
// 	}
// };

// const userProcessReducer = (state = null, action) => {
// 	switch (action.type) {
// 		case CLEAR_USER_PROCESS:
// 			return null;
// 		case REMOVE_USER_FROM_GROUP_STAGING:
// 		case ADD_USER_TO_GROUP_STAGING:
// 		case REMOVE_USER_FROM_GROUP_PRODUCTION:
// 		case ADD_USER_TO_GROUP_PRODUCTION:
// 			return true;
// 		case REMOVE_USER_FROM_GROUP_STAGING_ERR:
// 		case ADD_USER_TO_GROUP_STAGING_ERR:
// 		case REMOVE_USER_FROM_GROUP_PRODUCTION_ERR:
// 		case ADD_USER_TO_GROUP_PRODUCTION_ERR:
// 			return false;
// 		default:
// 			return state;
// 	}
// };

export { usersReducer };
