import React, { Component } from 'react';
import { Auth } from 'aws-amplify';

export default class Logout extends Component {
	

	handleLogout = async () => {
		await Auth.signOut();
		this.props.userHasAuthenticated(false, []);
		this.props.history.push('/');
	};

	render() {
		//console.log(this)
		return (
			<button type="button" className="dropdown-item pointered" onClick={this.handleLogout}>
				<i className="fas fa-fw fa-sign-out-alt mr-1" /> Log out
			</button>
		);
	}
}
