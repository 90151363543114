import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

import {
	FETCH_GROUPS,
	FETCH_GROUPS_ERR,
	FETCH_SITE_GROUPS,
	FETCH_SITE_GROUPS_ERR,
	CREATE_GROUP,
	CREATE_GROUP_ERR,
	UPDATE_GROUP,
	UPDATE_GROUP_ERR,
	DELETE_GROUP,
	DELETE_GROUP_ERR,
	FETCH_GROUP_TYPES,
	FETCH_GROUP_TYPES_ERR,
	CREATE_GROUP_TYPE,
	CREATE_GROUP_TYPE_ERR,
	UPDATE_GROUP_TYPE,
	UPDATE_GROUP_TYPE_ERR,
	DELETE_GROUP_TYPE,
	DELETE_GROUP_TYPE_ERR,
	FETCH_GROUP_SITES,
	FETCH_GROUP_SITES_ERR,
	ADD_GROUP_SITES,
	ADD_GROUP_SITES_ERR,
	DELETE_ALL_GROUP_SITES,
	DELETE_ALL_GROUP_SITES_ERR,
	FETCH_GROUP_SECURITY_GROUPS,
	FETCH_GROUP_SECURITY_GROUPS_ERR,
	CREATE_GROUP_SECURITY_GROUP,
	CREATE_GROUP_SECURITY_GROUP_ERR,
	UPDATE_GROUP_SECURITY_GROUP,
	UPDATE_GROUP_SECURITY_GROUP_ERR,
	DELETE_GROUP_SECURITY_GROUP,
	DELETE_GROUP_SECURITY_GROUP_ERR
	//getAuthHeaders
} from 'actions';
import config from 'config';
import { replacer } from 'utils';

export const fetchGroups = () => async (dispatch) => {
	const request = API.get('gains-admin-api', config.apiEndpoints.groups, {
		headers: {}
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_GROUPS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_GROUPS_ERR,
				payload: err
			})
	);
};

export const fetchSiteGroups = (payload) => async (dispatch) => {
	const { id } = payload;
	const request = API.get(
		'gains-admin-api',
		replacer(config.apiEndpoints.siteGroups, {
			id
		}),
		{
			headers: {}
			//body: payload
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_SITE_GROUPS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_SITE_GROUPS_ERR,
				payload: err
			})
	);
};

export const createGroup = (payload) => async (dispatch) => {
	const request = API.post('gains-admin-api', config.apiEndpoints.createGroup, {
		headers: {},
		body: payload
	});

	return request.then(
		(response) => {
			dispatch({
				type: CREATE_GROUP,
				payload: response[0]
			});
			dispatch(fetchGroups());
			toastr.success('Create Group', 'Group created successfully');
		},
		(err) => {
			dispatch({
				type: CREATE_GROUP_ERR,
				payload: err
			});
			toastr.error('Create Group', 'There was an error creating the Group');
		}
	);
};

export const updateGroup = (payload) => async (dispatch) => {
	const { id } = payload;
	const request = API.put(
		'gains-admin-api',
		replacer(config.apiEndpoints.updateGroup, {
			id
		}),
		{
			headers: {},
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: UPDATE_GROUP,
				payload: response[0]
			});
			dispatch(fetchGroups());
			toastr.success('Update Group', 'Group updated successfully');
		},
		(err) => {
			dispatch({
				type: UPDATE_GROUP_ERR,
				payload: err
			});
			toastr.error('Update Group', 'There was an error updating the Group');
		}
	);
};

export const deleteGroup = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.del(
		'gains-admin-api',
		replacer(config.apiEndpoints.deleteGroup, {
			id
		}),
		{
			headers: {}
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_GROUP,
				payload: response[0]
			});
			dispatch(fetchGroups());
			toastr.success('Delete Group', 'Group deleted successfully');
		},
		(err) => {
			dispatch({
				type: DELETE_GROUP_ERR,
				payload: err
			});
			toastr.error('Delete Group', 'There was an error deleting the Group');
		}
	);
};

export const fetchGroupTypes = () => async (dispatch) => {
	const request = API.get('gains-admin-api', config.apiEndpoints.groupTypes, {
		//headers: await getAuthHeaders()
		headers: {}
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_GROUP_TYPES,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_GROUP_TYPES_ERR,
				payload: err
			})
	);
};

export const createGroupType = (payload) => async (dispatch) => {
	const request = API.post(
		'gains-admin-api',
		config.apiEndpoints.createGroupType,
		{
			//headers: await getAuthHeaders(),
			headers: {},
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: CREATE_GROUP_TYPE,
				payload: response[0]
			});
			dispatch(fetchGroupTypes());
			toastr.success('Create Group Type', 'Group type created successfully');
		},
		(err) => {
			dispatch({
				type: CREATE_GROUP_TYPE_ERR,
				payload: err
			});
			toastr.error(
				'Create Group Type',
				'There was an error creating the group type'
			);
		}
	);
};

export const updateGroupType = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.put(
		'gains-admin-api',
		replacer(config.apiEndpoints.updateGroupType, {
			id
		}),
		{
			//headers: await getAuthHeaders(),
			headers: {},
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: UPDATE_GROUP_TYPE,
				payload: response[0]
			});
			dispatch(fetchGroupTypes());
			toastr.success('Update Group Type', 'Group type updated successfully');
		},
		(err) => {
			dispatch({
				type: UPDATE_GROUP_TYPE_ERR,
				payload: err
			});
			toastr.error(
				'Update Group Type',
				'There was an error updating the group type'
			);
		}
	);
};

export const deleteGroupType = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.del(
		'gains-admin-api',
		replacer(config.apiEndpoints.deleteGroupType, {
			id
		}),
		{
			//headers: await getAuthHeaders()
			headers: {}
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_GROUP_TYPE,
				payload: response[0]
			});
			dispatch(fetchGroupTypes());
			toastr.success('Delete Group Type', 'Group type deleted successfully');
		},
		(err) => {
			dispatch({
				type: DELETE_GROUP_TYPE_ERR,
				payload: err
			});
			toastr.error(
				'Delete Group Type',
				'There was an error deleting the group type'
			);
		}
	);
};

export const fetchGroupSites = (payload) => async (dispatch) => {
	const { id } = payload;
	const request = API.get(
		'gains-admin-api',
		replacer(config.apiEndpoints.groupSites, {
			id
		}),
		{
			//headers: await getAuthHeaders()
			headers: {}
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_GROUP_SITES,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_GROUP_SITES_ERR,
				payload: err
			})
	);
};

export const addGroupSites = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.post(
		'gains-admin-api',
		replacer(config.apiEndpoints.groupSites, {
			id
		}),
		{
			headers: {},
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: ADD_GROUP_SITES,
				payload: response[0]
			});
			dispatch(fetchGroupTypes());
			toastr.success('Add Sites to Group', 'Sites added to Group successfully');
		},
		(err) => {
			dispatch({
				type: ADD_GROUP_SITES_ERR,
				payload: err
			});
			toastr.error(
				'Add Sites to Group',
				'There was an error adding sites to the Group'
			);
		}
	);
};

export const deleteAllGroupSites = (payload) => async (dispatch) => {
	
	const { id } = payload;
	const request = API.del(
		'gains-admin-api',
		replacer(config.apiEndpoints.groupSites, {
			id
		}),
		{
			//headers: await getAuthHeaders()
			headers: {}
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_ALL_GROUP_SITES,
				payload: response[0]
			});
			dispatch(fetchGroupTypes());
			//toastr.success('Delete all Group Sites', `All sites deleted successfully`);
		},
		(err) => {
			dispatch({
				type: DELETE_ALL_GROUP_SITES_ERR,
				payload: err
			});
			// toastr.error(
			// 	'Delete all Group Sites',
			// 	'There was an error deleting the group sites'
			// );
		}
	);
};

export const fetchGroupsecurityGroups = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.get(
		'gains-admin-api',
		replacer(config.apiEndpoints.pseudoGroupSecurityGroups, {
			id
		}),
		{
			headers: {}
		}
	);

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_GROUP_SECURITY_GROUPS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_GROUP_SECURITY_GROUPS_ERR,
				payload: err
			})
	);
};

export const createPseudoGroupSecurityGroup = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.post(
		'gains-admin-api',
		replacer(config.apiEndpoints.createPseudoGroupSecurityGroup, {
			id
		}),
		{
			headers: {},
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: CREATE_GROUP_SECURITY_GROUP,
				payload: response[0]
			});
			dispatch(fetchGroupsecurityGroups({ id }));
			toastr.success(
				'Create Security Group',
				'Security group created successfully'
			);
		},
		(err) => {
			dispatch({
				type: CREATE_GROUP_SECURITY_GROUP_ERR,
				payload: err
			});
			toastr.error(
				'Create Security Group',
				'There was an error creating the security group'
			);
		}
	);
};

export const updatePseudoGroupSecurityGroup = (payload) => async (dispatch) => {
	const { id, securityGroupId } = payload;

	const request = API.put(
		'gains-admin-api',
		replacer(config.apiEndpoints.updatePseudoGroupSecurityGroup, {
			id,
			securityGroupId
		}),
		{
			headers: {},
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: UPDATE_GROUP_SECURITY_GROUP,
				payload: response[0]
			});
			dispatch(fetchGroupsecurityGroups({ id }));
			toastr.success(
				'Update Security Group',
				'Security group updated successfully'
			);
		},
		(err) => {
			dispatch({
				type: UPDATE_GROUP_SECURITY_GROUP_ERR,
				payload: err
			});
			toastr.error(
				'Update Security Group',
				'There was an error updating the security group'
			);
		}
	);
};

export const deletePseudoGroupSecurityGroup = (payload) => async (dispatch) => {
	const { id, securityGroupId } = payload;

	const request = API.del(
		'gains-admin-api',
		replacer(config.apiEndpoints.deletePseudoGroupSecurityGroup, {
			id,
			securityGroupId
		}),
		{
			headers: {}
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_GROUP_SECURITY_GROUP,
				payload: response[0]
			});
			dispatch(fetchGroupsecurityGroups({ id }));
			toastr.success(
				'Delete Security Group',
				'Security group deleted successfully'
			);
		},
		(err) => {
			dispatch({
				type: DELETE_GROUP_SECURITY_GROUP_ERR,
				payload: err
			});
			toastr.error(
				'Delete Security Group',
				'There was an error deleting the security group'
			);
		}
	);
};
