export const replacer = (template, obj) => {
	var keys = Object.keys(obj);
	var func = Function(...keys, 'return `' + template + '`;'); // eslint-disable-line no-new-func
	return func(...keys.map((k) => obj[k]));
};

const queryCheck = (s) => document.createDocumentFragment().querySelector(s);
export const isSelectorValid = (selector) => {
  try {
    queryCheck(selector);
  } catch (err) {
    return false;
  }
  return true;
};
