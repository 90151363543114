//    DEVELOPEMENT    //
// const config = {
// 	apiGateway: {
// 		URL: 'https://api-dev.gains.earthi.world',
// 		REGION: 'eu-west-2'
// 	},
// 	cognito: {
// 		REGION: 'eu-west-2',
// 		USER_POOL_ID: 'eu-west-2_oKbWrU50D',
// 		APP_CLIENT_ID: '151dhepbp0uu460t0dbd2bivvu',
// 		IDENTITY_POOL_ID: 'eu-west-2:471e29e3-6266-450c-aef0-13c08bc87e6a'
// 	},
// 	idleTimeout: 1000 * 60 * 30
// };

const config = {
	apiGateway: {
		URL: 'https://api.gains.earthi.world',
		REGION: 'eu-west-2'
	},
	cognito: {
		REGION: 'eu-west-2',
		USER_POOL_ID: 'eu-west-2_plUl26ZwS',
		APP_CLIENT_ID: '5c5i4aom7t7tcrftcr5bt7euvs',
		IDENTITY_POOL_ID: 'eu-west-2:2ec2c7a7-e87e-4e4d-adab-55d302dfa533',
		AuthenticationFlowType: 'USER_PASSWORD_AUTH'
	},
	idleTimeout: 1000 * 60 * 30
};

const mapboxToken =
	// development
	//'pk.eyJ1IjoibW9qb3NiZWFucyIsImEiOiJjbGlkMTN2N2cwNTU0M2VzMDdqbDhsMmFwIn0.HFEl4nHKHUcp_Rz6QOGSnw';

	// production
	'pk.eyJ1IjoiZWFydGgtaS10ZWNoIiwiYSI6ImNsamg4cXR1cDAyYjkzY254ems4aWY1amMifQ.8MLjrfX13N_HS1Gk7kFJXA';

const apiEndpoints = {
	// <i class="fa-solid fa-city"></i>   SMELTER
	// <i class="fa-solid fa-plane"></i>  AIRFIELD
	// fad fa-layer-group                 STOCKPILES

	regions: '/regions',
	createRegion: '/regions',
	updateRegion: '/regions/${id}', // eslint-disable-line no-template-curly-in-string

	sites: '/sites',
	createSite: '/sites',
	updateSite: '/sites/${id}', // eslint-disable-line no-template-curly-in-string
	deleteSite: '/sites/${id}', // eslint-disable-line no-template-curly-in-string
	siteGroups: '/sites/${id}/groups', // eslint-disable-line no-template-curly-in-string
	siteTypes: '/site-types',
	siteInfo: '/sites/${id}/info', // eslint-disable-line no-template-curly-in-string
	fetchSubsiteInfo: '/sites/subsites/${id}', // eslint-disable-line no-template-curly-in-string

	groups: '/groups',
	createGroup: '/groups',
	updateGroup: '/groups/${id}', // eslint-disable-line no-template-curly-in-string
	deleteGroup: '/groups/${id}', // eslint-disable-line no-template-curly-in-string
	groupSites: '/groups/${id}/sites', // eslint-disable-line no-template-curly-in-string

	groupTypes: '/group-types',
	createGroupType: '/group-types',
	updateGroupType: '/group-types/${id}', // eslint-disable-line no-template-curly-in-string
	deleteGroupType: '/group-types/${id}', // eslint-disable-line no-template-curly-in-string

	countries: '/countries',
	commodities: '/commodities',

	users: '/users',
	updateUser: '/users/${id}', // eslint-disable-line no-template-curly-in-string
	deleteUser: '/users/${id}', // eslint-disable-line no-template-curly-in-string
	approveUser: '/update-user-role'
};

const aoi_input_type = [
	{
		name: 'aoi_map',
		mapDisabled: false,
		AOIFieldDisabled: true,
		uploadFieldDisabled: true,
		labelName: 'Draw on map',
		fileUpload: ''
	},
	// {
	// 	name: 'aoi_paste',
	// 	mapDisabled: true,
	// 	AOIFieldDisabled: true,
	// 	uploadFieldDisabled: true,
	// 	labelName: 'Input coordinates',
	// 	fileUpload: ''
	// },
	{
		name: 'aoi_upload',
		mapDisabled: true,
		AOIFieldDisabled: true,
		uploadFieldDisabled: false,
		labelName: 'Upload file'
	}
];

const mapbox_styles = [
	{
		stylename: 'streets',
		url: 'streets-v12',
		text: 'black'
	},
	{
		stylename: 'outdoors',
		url: 'outdoors-v12',
		text: 'black'
	},
	{
		stylename: 'light',
		url: 'light-v11',
		text: 'black'
	},
	{
		stylename: 'dark',
		url: 'dark-v11',
		text: 'white'
	},
	{
		stylename: 'satellite',
		url: 'satellite-v9',
		text: 'white'
	}
];

const site_types = [
	{
		site_type: 'smelter',
		id: 1,
		icon: 'fa-solid fa-city',
		color: 'primary'
	},
	{
		site_type: 'airfield',
		id: 2,
		icon: 'fa-solid fa-plane-departure',
		color: 'success'
	},
	{
		site_type: 'stockpiles',
		id: 3,
		icon: 'fa-solid fa-layer-group',
		color: 'warning'
	}
];

const country_centroids = [
	{
		latlong: [-88.85911489238985, 13.758041517055418],
		name: 'El Salvador'
	},
	{
		latlong: [-90.31219349119617, 15.820878515352684],
		name: 'Guatemala'
	},
	{
		latlong: [-101.55399731630118, 23.87436068093592],
		name: 'Mexico'
	},
	{
		latlong: [-98.41680517868062, 57.550480044655636],
		name: 'Canada'
	},
	{
		latlong: [-64.53238503843076, -35.697270518120085],
		name: 'Argentina'
	},

	{
		latlong: [-70.76863431739216, -37.82938283049967],
		name: 'Chile'
	},
	{
		latlong: [-78.4630326109714, -1.5642721388853116],
		name: 'Ecuador'
	},
	{
		latlong: [-74.11416196781884, -8.522717984240291],
		name: 'Peru'
	},
	{
		latlong: [-64.45209597511206, -16.7312488393574],
		name: 'Bolivia'
	},
	{
		latlong: [-54.355206608256424, -11.524630416426652],
		name: 'Brazil'
	},
	{
		latlong: [-58.38906357428651, -23.42190559259428],
		name: 'Paraguay'
	},
	{
		latlong: [-56.01919523458085, -32.78195043831093],
		name: 'Uruguay'
	},
	{
		latlong: [-64.7458500599169, 32.315067430740726],
		name: 'Bermuda'
	},
	{
		latlong: [-68.29350445958761, 12.180844982440338],
		name: 'Bonaire'
	},
	{
		latlong: [-64.62406519257699, 18.42195819619707],
		name: 'British Virgin Islands'
	},
	{
		latlong: [-81.25203208977878, 19.311231805620288],
		name: 'Cayman Islands'
	},
	{
		latlong: [-72.6445066243485, 4.187753877352739],
		name: 'Colombia'
	},
	{
		latlong: [-84.14673625701816, 9.863467407406214],
		name: 'Costa Rica'
	},
	{
		latlong: [-79.69817857618705, 21.476176522869448],
		name: 'Cuba'
	},
	{
		latlong: [-68.96939768599042, 12.199996647939832],
		name: 'Curacao'
	},
	{
		latlong: [-61.360471946942994, 15.429269860940513],
		name: 'Dominica'
	},
	{
		latlong: [-70.43495198520012, 18.77954818522993],
		name: 'Dominican Republic'
	},
	{
		latlong: [-53.32232307156624, 3.857429742497078],
		name: 'French Guiana'
	},
	{
		latlong: [-61.67937937204098, 12.112926656338907],
		name: 'Grenada'
	},
	{
		latlong: [-61.54382262282755, 16.24420002705553],
		name: 'Guadeloupe'
	},
	{
		latlong: [-58.91352612754667, 4.68211981385056],
		name: 'Guyana'
	},
	{
		latlong: [-72.89291379842, 18.883520486983567],
		name: 'Haiti'
	},
	{
		latlong: [-86.49251679006962, 14.740370695750006],
		name: 'Honduras'
	},
	{
		latlong: [-77.30358894542778, 18.12207889341651],
		name: 'Jamaica'
	},
	{
		latlong: [-61.01432380875083, 14.642697353597645],
		name: 'Martinique'
	},
	{
		latlong: [-62.18693281256255, 16.735363391460357],
		name: 'Montserrat'
	},
	{
		latlong: [-85.016088327669, 12.893566631930554],
		name: 'Nicaragua'
	},
	{
		latlong: [-80.14428761482796, 8.439536749576892],
		name: 'Panama'
	},
	{
		latlong: [-66.49425339593509, 18.216224086610914],
		name: 'Puerto Rico'
	},
	{
		latlong: [-63.23739481909494, 17.632512616389718],
		name: 'Saba'
	},
	{
		latlong: [-62.83051610005156, 17.90561691241738],
		name: 'Saint Barthelemy'
	},
	{
		latlong: [-62.978230589445026, 17.4919042294197],
		name: 'Saint Eustatius'
	},
	{
		latlong: [-62.74560385895787, 17.314736299587768],
		name: 'Saint Kitts and Nevis'
	},
	{
		latlong: [-60.9689510935251, 13.895749185129906],
		name: 'Saint Lucia'
	},
	{
		latlong: [-63.06678525361946, 18.078012113224464],
		name: 'Saint Martin'
	},
	{
		latlong: [-56.32465353437558, 46.95153913615198],
		name: 'Saint Pierre and Miquelon'
	},
	{
		latlong: [-61.193766354393034, 13.254808122970651],
		name: 'Saint Vincent and the Grenadines'
	},
	{
		latlong: [-63.06883135915303, 18.03942608463078],
		name: 'Sint Maarten'
	},
	{
		latlong: [-55.855514311561286, 4.098723595920171],
		name: 'Suriname'
	},
	{
		latlong: [-61.37236579976247, 10.415515638298093],
		name: 'Trinidad and Tobago'
	},
	{
		latlong: [-71.74058946811508, 21.799865427483745],
		name: 'Turks and Caicos Islands'
	},
	{
		latlong: [-64.76155341409797, 17.738009708772523],
		name: 'US Virgin Islands'
	},
	{
		latlong: [-66.36492135985132, 7.148324760507107],
		name: 'Venezuela'
	},
	{
		latlong: [-1.6932816211842325, 12.108709036312737],
		name: 'Burkina Faso'
	},
	{
		latlong: [-23.63401005900474, 15.076411518651643],
		name: 'Cabo Verde'
	},
	{
		latlong: [-5.571710194917734, 7.536779279421307],
		name: "Côte d'Ivoire"
	},
	{
		latlong: [-15.383380385869662, 13.428617959189328],
		name: 'Gambia'
	},
	{
		latlong: [-1.219233362526581, 7.94530467243628],
		name: 'Ghana'
	},
	{
		latlong: [-5.345549484594358, 36.14022671336082],
		name: 'Gibraltar'
	},
	{
		latlong: [-10.986948848040218, 10.255986541378112],
		name: 'Guinea'
	},
	{
		latlong: [-14.980186756910847, 11.980075324820504],
		name: 'Guinea-Bissau'
	},
	{
		latlong: [-9.258988935497618, 6.52012979398834],
		name: 'Liberia'
	},
	{
		latlong: [-4.346399841781153, 17.168146208584837],
		name: 'Mali'
	},
	{
		latlong: [-10.495079045035716, 20.466731212820022],
		name: 'Mauritania'
	},
	{
		latlong: [-8.817212587250811, 28.687598134979325],
		name: 'Morocco'
	},
	{
		latlong: [-7.933662183874006, 39.67529214702138],
		name: 'Portugal'
	},
	{
		latlong: [-14.610875368352305, 14.228861491763402],
		name: 'Senegal'
	},
	{
		latlong: [-11.78656695731115, 8.561330384750587],
		name: 'Sierra Leone'
	},
	{
		latlong: [-42.07567788066985, 74.16847218965994],
		name: 'Greenland'
	},
	{
		latlong: [-2.576392582891568, 49.45870771378872],
		name: 'Guernsey'
	},
	{
		latlong: [-8.241128545554096, 53.30489539816495],
		name: 'Ireland'
	},
	{
		latlong: [-4.532995055468449, 54.22855301008011],
		name: 'Isle of Man'
	},
	{
		latlong: [-2.1291601162653575, 49.215396925724306],
		name: 'Jersey'
	},
	{
		latlong: [-2.852943909329258, 53.97844735080214],
		name: 'United Kingdom'
	},
	{
		latlong: [-19.05682967106099, 65.12360920205514],
		name: 'Iceland'
	},
	{
		latlong: [-6.9811060913122835, 62.130896281495346],
		name: 'Faroe Islands'
	},
	{
		latlong: [16.036378851505283, 78.57318936469626],
		name: 'Svalbard'
	},
	{
		latlong: [3.411969465057627, -54.42316679395248],
		name: 'Bouvet Island'
	},
	{
		latlong: [170.69035541428696, -43.82765432544426],
		name: 'New Zealand'
	},
	{
		latlong: [17.651768783079, -12.167424062667942],
		name: 'Angola'
	},
	{
		latlong: [23.85779956995608, -22.236609002062902],
		name: 'Botswana'
	},
	{
		latlong: [29.88518227845293, -3.261251993278643],
		name: 'Burundi'
	},
	{
		latlong: [43.34826587429403, -11.658861474508491],
		name: 'Comoros'
	},
	{
		latlong: [14.879732849491393, -0.7294391595233845],
		name: 'Congo'
	},
	{
		latlong: [23.419827574282188, -3.338629596207896],
		name: 'Congo DRC'
	},
	{
		latlong: [11.839410898545754, -0.628448459921234],
		name: 'Gabon'
	},
	{
		latlong: [37.95309411262371, 0.6899182318376179],
		name: 'Kenya'
	},
	{
		latlong: [28.24475317864227, -29.60168116924817],
		name: 'Lesotho'
	},
	{
		latlong: [34.23441182298881, -13.128986464184024],
		name: 'Malawi'
	},
	{
		latlong: [42.74374761089645, -17.06449193630804],
		name: 'Juan De Nova Island'
	},
	{
		latlong: [35.208577031290176, -17.525230309488748],
		name: 'Mozambique'
	},
	{
		latlong: [18.16451345845268, -21.90858163281473],
		name: 'Namibia'
	},
	{
		latlong: [29.919439681764082, -2.014687460047154],
		name: 'Rwanda'
	},
	{
		latlong: [6.606158796857607, 0.22744704294793774],
		name: 'Sao Tome and Principe'
	},
	{
		latlong: [24.75252746489084, -28.55361930679731],
		name: 'South Africa'
	},
	{
		latlong: [31.510685746082007, -26.562540935608702],
		name: 'Eswatini'
	},
	{
		latlong: [34.81832206060381, -6.355794440041147],
		name: 'Tanzania'
	},
	{
		latlong: [27.75521363430896, -13.162832953186246],
		name: 'Zambia'
	},
	{
		latlong: [29.717829640720844, -18.92700121981475],
		name: 'Zimbabwe'
	},
	{
		latlong: [72.43501618476016, -7.323548444385743],
		name: 'British Indian Ocean Territory'
	},
	{
		latlong: [69.54686984724839, -49.26329687105643],
		name: 'French Southern Territories'
	},
	{
		latlong: [73.49298560844045, -53.084170035513736],
		name: 'Heard Island and McDonald Islands'
	},
	{
		latlong: [46.68493466832544, -19.04163612493041],
		name: 'Madagascar'
	},
	{
		latlong: [57.56415671066546, -20.28142317475198],
		name: 'Mauritius'
	},
	{
		latlong: [45.128142327031064, -12.824468416301052],
		name: 'Mayotte'
	},
	{
		latlong: [47.290948081543384, -11.566224871643417],
		name: 'Glorioso Islands'
	},
	{
		latlong: [55.54393506194689, -21.119825460665105],
		name: 'Réunion'
	},
	{
		latlong: [55.47250789595527, -4.660002318822744],
		name: 'Seychelles'
	},
	{
		latlong: [2.6558464719769135, 28.350969744889056],
		name: 'Algeria'
	},
	{
		latlong: [2.305714528830206, 9.503013199615893],
		name: 'Benin'
	},
	{
		latlong: [12.948474142398263, 6.294168487480992],
		name: 'Cameroon'
	},
	{
		latlong: [20.520743419397256, 6.331390033944319],
		name: 'Central African Republic'
	},
	{
		latlong: [18.427113900363025, 15.283493546654503],
		name: 'Chad'
	},
	{
		latlong: [10.425456672353823, 1.5954643936590733],
		name: 'Equatorial Guinea'
	},
	{
		latlong: [-157.39024189323504, 1.8676673749241066],
		name: 'Kiribati'
	},
	{
		latlong: [17.91133392454237, 27.202915771690794],
		name: 'Libya'
	},
	{
		latlong: [14.441922442508494, 35.890522650899314],
		name: 'Malta'
	},
	{
		latlong: [8.86863247002646, 17.08105392407292],
		name: 'Niger'
	},
	{
		latlong: [8.147714845256194, 9.61029352034213],
		name: 'Nigeria'
	},
	{
		latlong: [0.8990857571109684, 8.660743037717811],
		name: 'Togo'
	},
	{
		latlong: [9.65587551697984, 34.08636179565723],
		name: 'Tunisia'
	},
	{
		latlong: [33.375346009199205, 35.11700416345239],
		name: 'Cyprus'
	},
	{
		latlong: [42.613496898789506, 11.750235727618804],
		name: 'Djibouti'
	},
	{
		latlong: [30.240135435012338, 26.60517034450628],
		name: 'Egypt'
	},
	{
		latlong: [39.2672401449901, 15.005533147667684],
		name: 'Eritrea'
	},
	{
		latlong: [39.914902886544276, 8.729389557048396],
		name: 'Ethiopia'
	},
	{
		latlong: [23.110368936161876, 39.42012261727978],
		name: 'Greece'
	},
	{
		latlong: [43.832529181056884, 33.105075667527906],
		name: 'Iraq'
	},
	{
		latlong: [35.027923472437024, 31.513542220043195],
		name: 'Israel'
	},
	{
		latlong: [36.95728884547246, 31.387064884449156],
		name: 'Jordan'
	},
	{
		latlong: [35.89651946324749, 33.91160170722086],
		name: 'Lebanon'
	},
	{
		latlong: [35.24251184154588, 31.930818736453883],
		name: 'Palestinian Territory'
	},
	{
		latlong: [30.3851856901788, 7.657782041763295],
		name: 'South Sudan'
	},
	{
		latlong: [29.951458283594064, 15.67060230984256],
		name: 'Sudan'
	},
	{
		latlong: [38.5117323139514, 35.09751106058316],
		name: 'Syria'
	},
	{
		latlong: [35.56886764076691, 38.93207363123396],
		name: 'Turkey'
	},
	{
		latlong: [32.34371768463123, 1.2821729218416205],
		name: 'Uganda'
	},
	{
		latlong: [1.5802243611232873, 42.54859834854764],
		name: 'Andorra'
	},
	{
		latlong: [-96.33161660829639, 38.8208089190304],
		name: 'United States'
	},
	{
		latlong: [2.1940236627886227, 46.6423682169416],
		name: 'France'
	},
	{
		latlong: [9.547674672376376, 47.14627562133036],
		name: 'Liechtenstein'
	},
	{
		latlong: [7.412820873271196, 43.74798224995656],
		name: 'Monaco'
	},
	{
		latlong: [8.286928794895285, 46.73678128684938],
		name: 'Switzerland'
	},
	{
		latlong: [4.675010154696485, 50.6182138854095],
		name: 'Belgium'
	},
	{
		latlong: [10.426171427430804, 51.08304539800482],
		name: 'Germany'
	},
	{
		latlong: [6.103230338458876, 49.77523454542369],
		name: 'Luxembourg'
	},
	{
		latlong: [5.554136426128487, 52.134054128923886],
		name: 'Netherlands'
	},
	{
		latlong: [20.061082767269493, 41.14165894891656],
		name: 'Albania'
	},
	{
		latlong: [13.797778364631036, 47.631858269895794],
		name: 'Austria'
	},
	{
		latlong: [17.83467240787538, 44.14415356126429],
		name: 'Bosnia and Herzegovina'
	},
	{
		latlong: [16.625761129583374, 44.91192100856702],
		name: 'Croatia'
	},
	{
		latlong: [15.383273292023533, 49.74917370930982],
		name: 'Czech Republic'
	},
	{
		latlong: [9.378670542409406, 56.00118817971057],
		name: 'Denmark'
	},
	{
		latlong: [19.39620048366142, 47.22527332486294],
		name: 'Hungary'
	},
	{
		latlong: [12.763657166123137, 42.98201127614267],
		name: 'Italy'
	},
	{
		latlong: [19.29505087156758, 42.73694835210454],
		name: 'Montenegro'
	},
	{
		latlong: [19.43573279234468, 52.06848055692473],
		name: 'Poland'
	},
	{
		latlong: [12.461278349581722, 43.942820729097896],
		name: 'San Marino'
	},
	{
		latlong: [20.85677444395745, 44.02679870131969],
		name: 'Serbia'
	},
	{
		latlong: [19.581015362490966, 48.69808390520484],
		name: 'Slovakia'
	},
	{
		latlong: [14.890636899973781, 46.13759229564504],
		name: 'Slovenia'
	},
	{
		latlong: [21.70998923872772, 41.59402890143112],
		name: 'North Macedonia'
	},
	{
		latlong: [12.451312917026133, 41.90402351316735],
		name: 'Vatican City'
	},
	{
		latlong: [16.670259272390894, 64.97775882947745],
		name: 'Norway'
	},
	{
		latlong: [17.062431988004956, 62.73420986108448],
		name: 'Sweden'
	},
	{
		latlong: [27.964252054715104, 53.46791374543163],
		name: 'Belarus'
	},
	{
		latlong: [25.251739122561908, 42.82043677302438],
		name: 'Bulgaria'
	},
	{
		latlong: [25.916870250633806, 58.648108311231034],
		name: 'Estonia'
	},
	{
		latlong: [25.65738433454702, 65.01578959749911],
		name: 'Finland'
	},
	{
		latlong: [43.378866534112234, 42.17986277737226],
		name: 'Georgia'
	},
	{
		latlong: [24.693671325654403, 56.813853047554154],
		name: 'Latvia'
	},
	{
		latlong: [23.946021605013534, 55.29437393417175],
		name: 'Lithuania'
	},
	{
		latlong: [28.391111865941348, 47.0725674580696],
		name: 'Moldova'
	},
	{
		latlong: [25.094158201563292, 45.82454894397586],
		name: 'Romania'
	},
	{
		latlong: [31.27377208442636, 48.657532515563794],
		name: 'Ukraine'
	},
	{
		latlong: [66.59216131095278, 34.13402601376932],
		name: 'Afghanistan'
	},
	{
		latlong: [50.540695402276775, 26.04798501537066],
		name: 'Bahrain'
	},
	{
		latlong: [81.17300408530181, 23.586300567746722],
		name: 'India'
	},
	{
		latlong: [54.237077001065444, 32.906023742890056],
		name: 'Iran'
	},
	{
		latlong: [47.56311109320184, 29.281360965443092],
		name: 'Kuwait'
	},
	{
		latlong: [73.10076245140479, -0.6065577168009924],
		name: 'Maldives'
	},
	{
		latlong: [84.1338898313567, 28.300920699755657],
		name: 'Nepal'
	},
	{
		latlong: [55.841088119829, 20.7242833183209],
		name: 'Oman'
	},
	{
		latlong: [69.08835090769651, 30.116188371410882],
		name: 'Pakistan'
	},
	{
		latlong: [51.19794918743203, 25.318528486425386],
		name: 'Qatar'
	},
	{
		latlong: [44.600958178225596, 24.136038144757897],
		name: 'Saudi Arabia'
	},
	{
		latlong: [45.40037867243972, 6.524534573103924],
		name: 'Somalia'
	},
	{
		latlong: [80.66931169770622, 7.696630939329944],
		name: 'Sri Lanka'
	},
	{
		latlong: [70.94215281065698, 38.56933138382972],
		name: 'Tajikistan'
	},
	{
		latlong: [58.4577357627054, 39.06069118001429],
		name: 'Turkmenistan'
	},
	{
		latlong: [54.27920525789581, 24.18250292309135],
		name: 'United Arab Emirates'
	},
	{
		latlong: [47.46815793206386, 16.001392616307445],
		name: 'Yemen'
	},
	{
		latlong: [45.05490831965259, 40.17841274230679],
		name: 'Armenia'
	},
	{
		latlong: [48.634592670644324, 40.3920509942049],
		name: 'Azerbaijan'
	},
	{
		latlong: [66.3759193479301, 47.641465195176835],
		name: 'Kazakhstan'
	},
	{
		latlong: [74.17532903468319, 41.35698905438358],
		name: 'Kyrgyzstan'
	},
	{
		latlong: [63.8548297593985, 41.4879065244633],
		name: 'Uzbekistan'
	},
	{
		latlong: [105.70209512200549, -10.446440802183416],
		name: 'Christmas Island'
	},
	{
		latlong: [96.83688767323002, -12.171249450199545],
		name: 'Cocos Islands'
	},
	{
		latlong: [113.96538246847302, 0.15591979959037652],
		name: 'Indonesia'
	},
	{
		latlong: [125.95024049562659, -8.809894630601962],
		name: 'Timor-Leste'
	},
	{
		latlong: [134.02277170916162, -25.697337673983082],
		name: 'Australia'
	},
	{
		latlong: [166.92937633139178, -0.5221021440668993],
		name: 'Nauru'
	},
	{
		latlong: [165.50767040438612, -21.33003372660827],
		name: 'New Caledonia'
	},
	{
		latlong: [167.95259597483337, -29.037654445046282],
		name: 'Norfolk Island'
	},
	{
		latlong: [144.8348942994695, -7.156912819152135],
		name: 'Papua New Guinea'
	},
	{
		latlong: [160.16475795033884, -9.613104734596515],
		name: 'Solomon Islands'
	},
	{
		latlong: [179.217833977593, -8.514701506447222],
		name: 'Tuvalu'
	},
	{
		latlong: [166.84912735669738, -15.189132121699332],
		name: 'Vanuatu'
	},
	{
		latlong: [105.03973078680701, 12.699186865507775],
		name: 'Cambodia'
	},
	{
		latlong: [103.76375899026448, 18.117282736873282],
		name: 'Laos'
	},
	{
		latlong: [114.63330303992869, 3.6716608556387857],
		name: 'Malaysia'
	},
	{
		latlong: [97.08892285397344, 19.901227931399873],
		name: 'Myanmar'
	},
	{
		latlong: [103.81025757634053, 1.3528251890006349],
		name: 'Singapore'
	},
	{
		latlong: [101.08675116214552, 13.66222784745538],
		name: 'Thailand'
	},
	{
		latlong: [105.91338832758704, 16.517347170839393],
		name: 'Vietnam'
	},
	{
		latlong: [90.43212562608613, 23.673728665121],
		name: 'Bangladesh'
	},
	{
		latlong: [90.46716647173861, 27.42163933959824],
		name: 'Bhutan'
	},
	{
		latlong: [104.69113855849604, 38.07325481105728],
		name: 'China'
	},
	{
		latlong: [114.6430958360464, 4.543205889917609],
		name: 'Brunei Darussalam'
	},
	{
		latlong: [121.82208941416745, 15.586542251094242],
		name: 'Philippines'
	},
	{
		latlong: [127.76224551357649, 36.402386712544114],
		name: 'South Korea'
	},
	{
		latlong: [103.3987360327455, 47.08644454604851],
		name: 'Mongolia'
	},
	{
		latlong: [127.3379805653744, 40.19198091470839],
		name: 'North Korea'
	},
	{
		latlong: [144.78024458298802, 13.445430479945276],
		name: 'Guam'
	},
	{
		latlong: [137.46934234351835, 36.76738832597829],
		name: 'Japan'
	},
	{
		latlong: [168.72016025351076, 7.307929900994344],
		name: 'Marshall Islands'
	},
	{
		latlong: [158.2291899444527, 6.8789448129255435],
		name: 'Micronesia'
	},
	{
		latlong: [145.74119737203134, 15.178063516432115],
		name: 'Northern Mariana Islands'
	},
	{
		latlong: [134.57965086721052, 7.534775852547396],
		name: 'Palau'
	},
	{
		latlong: [98.6704990698032, 59.039434214106194],
		name: 'Russian Federation'
	},
	{
		latlong: [-3.6516251409956983, 40.365008336683836],
		name: 'Spain'
	},
	{
		latlong: [-16.53799441021647, 28.297665106525546],
		name: 'Canarias'
	}
];

const mapBoxLayerStyles = {
	fill_paint: {
		'fill-color': '#cceeff',
		'fill-opacity': 0.5
	},

	line_paint: {
		'line-color': '#cceeff',
		'line-width': 3
	}
};

export default {
	mapboxToken,
	apiEndpoints,
	mapbox_styles,
	site_types,
	aoi_input_type,
	country_centroids,
	mapBoxLayerStyles,
	...config
};
