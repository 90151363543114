export const multiSelectStyle = {
	clearIndicator: (base) => ({
		...base,
		padding: 4
	}),
	container: (base) => ({
		...base,
		verticalAlign: 'top',
		marginRight: 0
	}),
	control: (base, state) => ({
		...base,
		minHeight: 31,
		fontSize: '0.9rem',
		borderColor: '#6c757d',
		'&:hover': {
			borderColor: '#6c757d'
		},
		cursor: 'pointer',
		boxShadow: state.isFocused ? '0 0 0 0.2rem rgba(108, 117, 125, 0.5)' : ''
	}),
	dropdownIndicator: (base) => ({
		...base,
		padding: 4,
		color: '#666'
	}),
	input: (base) => ({
		...base,
		margin: 0,
		padding: 0
	}),
	menu: (base) => ({
		...base,
		zIndex: 99,
		fontSize: '0.9rem'
	}),
	multiValue: (base) => ({
		...base,
		minHeight: 23,
		fontSize: '0.88rem'
	}),
	option: (base) => ({
		...base,
		cursor: 'pointer'
	}),
	placeholder: (base) => ({
		...base,
		padding: '0px 5px 2px',
		color: '#6c757d'
	}),
	valueContainer: (base) => ({
		...base,
		padding: '0px 1px'
	})
};
