const menuConfig = {
	items: [
		{
			path: '/home',
			title: 'Dashboard',
			icon: 'fa-solid fa-gauge',
			groups: []
		},
		{
			path: '/site',
			title: 'Sites',
			icon: 'fa-solid fa-fire-flame-simple',
			groups: []
		},
		{
			path: '/group',
			title: 'Groups',
			icon: 'fa-solid fa-sitemap',
			groups: []
		},
		{
			path: '/grouptype',
			title: '    Group Type',
			//icon: 'fad fa-layer-group',
			icon: 'fa-solid fa-filter',
			groups: []
		},
		{
			path: '/region',
			title: 'Regions',
			icon: 'fa-solid fa-globe-americas',
			groups: []
		},
		{
			path: '/users',
			title: 'Users',
			icon: 'fa-solid fa-user-group',
			groups: []
		},
		{
			path: '/countries',
			title: 'Countries',
			icon: 'fa-solid fa-globe',
			groups: []
		},
		
		// {
		// 	path: '/approveUser',
		// 	title: 'Approve User',
		// 	icon: 'fa-solid fa-user-check',
		// 	groups: []
		// },
			//groups: [ 'basic' ]
	]
};

export default menuConfig;
