import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

import {
	FETCH_USERS,
	FETCH_USERS_ERR,
	FETCH_USER,
	FETCH_USER_ERR,
	APPROVE_USER,
	APPROVE_USER_ERR,
	DELETE_USER,
	DELETE_USER_ERR,
	UPDATE_USER,
	UPDATE_USER_ERR
} from 'actions';
import config from 'config';
import { replacer } from 'utils';

export const approveUser = (payload) => async (dispatch) => {
	let body = {
		
		userRole: 'user',
		enabled: 'true'
	};

	let id = payload['id'];

	const request = API.put(
		'gains-admin-api',
		replacer(config.apiEndpoints.updateUser, {
			id
		}),
		{
			headers: {},
			body: body
		}
	);
	

	return request.then(
		(response) => {
			dispatch({
				type: APPROVE_USER,
				payload: response
			});
			toastr.success(payload['toaster_title'], payload['toaster_feedback']);
		},
		(err) => {
			console.log(err)
			dispatch({
				type: APPROVE_USER_ERR,
				payload: err
			});
			toastr.error('User not found', 'This email address was not found');
		}
	);
};

export const updateUser = (payload) => async (dispatch) => {
	let body = {
		userRole: payload['userRole'],
		enabled: payload['enabled']
	};
	let id = payload['id'];

	const request = API.put(
		'gains-admin-api',
		replacer(config.apiEndpoints.updateUser, {
			id
		}),
		{
			headers: {},
			body: body
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: UPDATE_USER,
				payload: response
			});

			dispatch(fetchUsers());
			toastr.success(payload['toaster_title'], payload['toaster_feedback']);
		},
		(err) => {
			console.log(err)
			dispatch({
				type: UPDATE_USER_ERR,
				payload: err
			});
			toastr.error('User not found', 'This email address was not found');
		}
	);
};

export const deleteUser = (payload) => async (dispatch) => {
	const { email } = payload;
	let id = payload['id'];

	const request = API.del(
		'gains-admin-api',
		replacer(config.apiEndpoints.deleteUser, {
			id
		}),
		{
			headers: {}
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: DELETE_USER,
				payload: response
			});
			dispatch(fetchUsers());
			toastr.success('Delete User', `User "${email}" deleted successfully`);
		},
		(err) => {
			dispatch({
				type: DELETE_USER_ERR,
				payload: err
			});
			toastr.error('Delete User', 'There was an error deleting the User');
		}
	);
};

export const fetchUsers = () => async (dispatch) => {
	const request = API.get('gains-admin-api', config.apiEndpoints.users, {
		headers: {}
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_USERS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_USERS_ERR,
				payload: err
			})
	);
};

export const fetchUser = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.get('gains-admin-api', config.apiEndpoints.user, {
		headers: {},
		body: id
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_USER,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_USER_ERR,
				payload: err
			})
	);
};
