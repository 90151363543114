import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import DocumentTitle from 'react-document-title';
import {
	Button,
	Row,
	Col,
	Container,
	Form,
	FormFeedback,
	FormGroup,
	Label,
	Input
} from 'reactstrap';
import { approveUser} from 'actions';
import { Crumbs } from 'containers';

class ApproveUser extends Component {
	constructor(props) {
		super(props);

		this.state = {
			userRole: '',
			
		};
	}

	

	handleChange = (event) => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	setVerifyState = (event) => {
		this.setState({
			userRole: event.target.id
		})
	};

	onVerifyAction = async ({ values: { email }, setSubmitting }) => {

		let body = {
				email: email.trim(),
				userRole: this.state.userRole
			}

		console.log(body)

		// Insert the new smelter into the db
		await this.props.approveUser(body);
		

		setSubmitting(false);
		//this.setState({ email: '' });
	};

	renderForm() {
		return (
			<Fragment>
				<p>
					To confirm a user sign-up for Gains Admin, please fill in the email
					address for the user below.
				</p>
				<Row>
					<Col md={6}>
						<Formik
							initialValues={{
								email: ''
							}}
							validate={(values) => {
								const errors = {};
								var mailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
								if (values.email.trim().length === 0) {
									errors.email = 'An email is required';
								} else if (values.email.trim().length > 256) {
									errors.email = 'Email should be no more than 256 characters';
								} else if (!values.email.trim().match(mailformat)) {
									errors.email = 'Email does not match standard email format';
								}
								return errors;
							}}
							onSubmit={(values, { setSubmitting }) => {
								this.onVerifyAction({ values, setSubmitting });
							}}
						>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								submitForm,
								isSubmitting,
								setFieldValue
							}) => (
								<Form onSubmit={handleSubmit}>
									<FormGroup>
										<Label for="email">Email</Label>
										<Input
											id="email"
											placeholder="Enter email"
											value={values.email}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={errors.email && touched.email}
										/>
										<FormFeedback>{errors.email}</FormFeedback>
									</FormGroup>
									<Button
										id="user"
										className="m-2"
										color="info"
										size="sm"
										type="submit"
										onClick={(event) => this.setVerifyState(event)}
										disabled={isSubmitting}
									>
										Verify email
									</Button>

									<Button
										id="denied"
										className="m-2"
										color="info"
										size="sm"
										type="submit"
										onClick={(event) => this.setVerifyState(event)}
										disabled={isSubmitting}
									>
										Reject email
									</Button>
								</Form>
							)}
						</Formik>
					</Col>
				</Row>
			</Fragment>
		);
	}

	render() {
		return (
			<DocumentTitle title="Gains Admin | Approve User">
				<Container fluid>
					<Crumbs path={[{ title: 'Approve User' }]} />
					{this.renderForm()}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ usersStaging, usersProduction }) => {
	return {
		usersStaging, // redux
		usersProduction // redux
	};
};

const mapDispatchToProps = (dispatch) => ({
	approveUser: (data) => dispatch(approveUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApproveUser);
