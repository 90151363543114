import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Formik, Field } from 'formik';
import DocumentTitle from 'react-document-title';
import {
	Button,
	Col,
	Container,
	FormFeedback,
	Form,
	FormGroup,
	FormText,
	Input,
	Label,
	Modal,
	ModalBody,
	ModalFooter,
	ModalHeader,
	Row,
	Table,
} from 'reactstrap';

import { fetchRegions, createRegion, updateRegion } from 'actions';

import { Crumbs } from 'containers';

import './Regions.scss';

class Regions extends Component {
	state = {
		createModal: false,
		updateModal: false,
		updateRegion: {}
	};

	componentDidMount() {
		this.props.fetchRegions();
	}

	onReloadList = () => {
		this.props.fetchRegions();
	};

	toggleCreate = () => {
		this.setState((prevState) => ({
			createModal: !prevState.createModal
		}));
	};

	onCreateAction = async ({
		values: {
			createRegionName,
			createRegionLeft,
			createRegionRight,
			createRegionTop,
			createRegionBottom,
			createRegionResolution,
			createRegionCamera
		},
		setSubmitting
	}) => {
		// Insert the new region into the db
		await this.props.createRegion({
			name: createRegionName.trim(),
			view_left: createRegionLeft.trim(),
			view_right: createRegionRight.trim(),
			view_top: createRegionTop.trim(),
			view_bottom: createRegionBottom.trim(),
			resolution: createRegionResolution.trim(),
			camera: createRegionCamera.trim()
		});

		setSubmitting(false);

		this.setState({
			createModal: false
		});
	};

	toggleUpdate = () => {
		this.setState((prevState) => ({
			updateModal: !prevState.updateModal
		}));
	};

	onUpdate = ({ region }) => {
		this.setState({
			updateRegion: region,
			updateModal: true
		});
	};

	onUpdateAction = async ({
		values: {
			updateRegionId,
			updateRegionName,
			updateRegionLeft,
			updateRegionRight,
			updateRegionTop,
			updateRegionBottom,
			updateRegionResolution,
			updateRegionCamera
		},
		setSubmitting
	}) => {
		// Update the region in the db
		await this.props.updateRegion({
			id: updateRegionId,
			name: updateRegionName.trim(),
			view_left: updateRegionLeft.toString().trim(),
			view_right: updateRegionRight.toString().trim(),
			view_top: updateRegionTop.toString().trim(),
			view_bottom: updateRegionBottom.toString().trim(),
			resolution: updateRegionResolution.toString().trim(),
			camera: updateRegionCamera.toString().trim()
		});

		setSubmitting(false);

		this.setState({
			updateModal: false,
			updateRegion: {}
		});
	};

	buildResultsTable() {
		const { regions = [] } = this.props;

		return (
			<Row noGutters>
				<Col xs="12">
					<Table>
						<thead>
							<tr>
								<th style={{ width: '60px' }}>#</th>
								<th colSpan="2">
									Region name
									<span
										className="position-absolute align-bottom"
										style={{ right: '12px', top: '10px' }}
									>
										{/* <Button size="sm" color="success" className="mr-2" onClick={this.toggleCreate}>
											<i className="fas fa-plus mr-2" />Add region
										</Button> */}
										<Button size="sm" outline color="secondary" onClick={this.onReloadList}>
											<i className="fas fa-sync-alt" />
											<span className="sr-only">Refresh</span>
										</Button>
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{regions.map((region, ind) => {
								const { name } = region;
								const dspInd = ind + 1;
								return (
									<tr key={`region${dspInd}`}>
										<th scope="row" style={{ width: '60px' }}>
											{dspInd}
										</th>
										<td>{name}</td>
										<td style={{ width: '45px' }}>
											{/* <UncontrolledDropdown size="sm">
												<DropdownToggle className="pointered btn-light">
													<i className="fas fa-bars" />
													<span className="sr-only">Actions</span>
												</DropdownToggle>
												<DropdownMenu>
													<DropdownItem
														className="pointered"
														onClick={(e) => this.onUpdate({ region }, e)}
													>
														<i className="fas fa-edit fa-fw mr-2" />Update
													</DropdownItem>
												</DropdownMenu>
											</UncontrolledDropdown> */}
										</td>
									</tr>
								);
							})}
						</tbody>
					</Table>
				</Col>
			</Row>
		);
	}

	buildModals() {
		const { createModal, updateModal, updateRegion } = this.state;

		return (
			<Fragment>
				<Modal isOpen={createModal} toggle={this.toggleCreate}>
					<Formik
						initialValues={{
							createRegionName: '',
							createRegionLeft: '',
							createRegionRight: '',
							createRegionTop: '',
							createRegionBottom: '',
							createRegionResolution: '',
							createRegionCamera: ''
						}}
						validate={(values) => {
							const errors = {};
							if (values.createRegionName.trim().length === 0) {
								errors.createRegionName = 'A name is required';
							} else if (values.createRegionName.trim().length > 45) {
								errors.createRegionName = 'Name should be no more than 45 characters';
							}
							if (values.createRegionLeft.trim().length === 0 || isNaN(values.createRegionLeft)) {
								errors.createRegionLeft = 'A number is required';
							}
							if (values.createRegionRight.trim().length === 0 || isNaN(values.createRegionRight)) {
								errors.createRegionRight = 'A number is required';
							}
							if (values.createRegionTop.trim().length === 0 || isNaN(values.createRegionTop)) {
								errors.createRegionTop = 'A number is required';
							}
							if (values.createRegionBottom.trim().length === 0 || isNaN(values.createRegionBottom)) {
								errors.createRegionBottom = 'A number is required';
							}
							if (
								values.createRegionResolution.trim().length === 0 ||
								isNaN(values.createRegionResolution)
							) {
								errors.createRegionResolution = 'A number is required';
							}
							if (values.createRegionCamera.trim().length === 0 || isNaN(values.createRegionCamera)) {
								errors.createRegionCamera = 'A number is required';
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onCreateAction({ values, setSubmitting });
						}}
					>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<Form onSubmit={handleSubmit}>
								<ModalHeader toggle={this.toggleCreate}>
									<i className="fas fa-plus mr-3" />Create a new region
								</ModalHeader>
								<ModalBody>
									<FormGroup>
										<Label for="createRegionName">Region name</Label>
										<Input
											tag={Field}
											id="createRegionName"
											placeholder="Enter a region name"
											value={values.createRegionName}
											onChange={handleChange}
											onBlur={handleBlur}
											invalid={errors.createRegionName && touched.createRegionName}
											bsSize="sm"
										/>
										<FormText>Region name should be no more than 45 characters</FormText>
										<FormFeedback>{errors.createRegionName}</FormFeedback>
									</FormGroup>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="createRegionLeft">View left</Label>
												<Input
													tag={Field}
													id="createRegionLeft"
													placeholder="Enter a left coordinate"
													value={values.createRegionLeft}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.createRegionLeft && touched.createRegionLeft}
													bsSize="sm"
												/>
												<FormText>View left should be numeric</FormText>
												<FormFeedback>{errors.createRegionLeft}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="createRegionRight">View right</Label>
												<Input
													tag={Field}
													id="createRegionRight"
													placeholder="Enter a right coordinate"
													value={values.createRegionRight}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.createRegionRight && touched.createRegionRight}
													bsSize="sm"
												/>
												<FormText>View right should be numeric</FormText>
												<FormFeedback>{errors.createRegionRight}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="createRegionTop">View top</Label>
												<Input
													tag={Field}
													id="createRegionTop"
													placeholder="Enter a top coordinate"
													value={values.createRegionTop}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.createRegionTop && touched.createRegionTop}
													bsSize="sm"
												/>
												<FormText>View top should be numeric</FormText>
												<FormFeedback>{errors.createRegionTop}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="createRegionBottom">View bottom</Label>
												<Input
													tag={Field}
													id="createRegionBottom"
													placeholder="Enter a bottom coordinate"
													value={values.createRegionBottom}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.createRegionBottom && touched.createRegionBottom}
													bsSize="sm"
												/>
												<FormText>View bottom should be numeric</FormText>
												<FormFeedback>{errors.createRegionBottom}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="createRegionResolution">Resolution</Label>
												<Input
													tag={Field}
													id="createRegionResolution"
													placeholder="Enter a resolution"
													value={values.createRegionResolution}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.createRegionResolution && touched.createRegionResolution
													}
													bsSize="sm"
												/>
												<FormText>Resolution should be numeric</FormText>
												<FormFeedback>{errors.createRegionResolution}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="createRegionCamera">Camera</Label>
												<Input
													tag={Field}
													id="createRegionCamera"
													placeholder="Enter a camera value"
													value={values.createRegionCamera}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.createRegionCamera && touched.createRegionCamera}
													bsSize="sm"
												/>
												<FormText>Camera should be numeric</FormText>
												<FormFeedback>{errors.createRegionCamera}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" size="sm" type="submit" disabled={isSubmitting}>
										Create region
									</Button>{' '}
									<Button color="secondary" size="sm" type="button" onClick={this.toggleCreate}>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</Modal>

				<Modal isOpen={updateModal} toggle={this.toggleUpdate}>
					<Formik
						initialValues={{
							updateRegionId: updateRegion.id,
							updateRegionName: updateRegion.name,
							updateRegionLeft: updateRegion.view_left,
							updateRegionRight: updateRegion.view_right,
							updateRegionTop: updateRegion.view_top,
							updateRegionBottom: updateRegion.view_bottom,
							updateRegionResolution: updateRegion.resolution,
							updateRegionCamera: updateRegion.camera
						}}
						validate={(values) => {
							const errors = {};
							if (values.updateRegionName.toString().trim().length === 0) {
								errors.updateRegionName = 'A name is required';
							} else if (values.updateRegionName.toString().trim().length > 45) {
								errors.updateRegionName = 'Name should be no more than 45 characters';
							}
							if (
								values.updateRegionLeft.toString().trim().length === 0 ||
								isNaN(values.updateRegionLeft)
							) {
								errors.updateRegionLeft = 'A number is required';
							}
							if (
								values.updateRegionRight.toString().trim().length === 0 ||
								isNaN(values.updateRegionRight)
							) {
								errors.updateRegionRight = 'A number is required';
							}
							if (
								values.updateRegionTop.toString().trim().length === 0 ||
								isNaN(values.updateRegionTop)
							) {
								errors.updateRegionTop = 'A number is required';
							}
							if (
								values.updateRegionBottom.toString().trim().length === 0 ||
								isNaN(values.updateRegionBottom)
							) {
								errors.updateRegionBottom = 'A number is required';
							}
							if (
								values.updateRegionResolution.toString().trim().length === 0 ||
								isNaN(values.updateRegionResolution)
							) {
								errors.updateRegionResolution = 'A number is required';
							}
							if (
								values.updateRegionCamera.toString().trim().length === 0 ||
								isNaN(values.updateRegionCamera)
							) {
								errors.updateRegionCamera = 'A number is required';
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							this.onUpdateAction({ values, setSubmitting });
						}}
					>
						{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
							<Form onSubmit={handleSubmit}>
								<ModalHeader toggle={this.toggleUpdate}>
									<i className="fas fa-plus mr-3" />Update region
								</ModalHeader>
								<ModalBody>
									<FormGroup>
										<Label for="updateRegionName">Region name</Label>
										<Input
											tag={Field}
											id="updateRegionName"
											placeholder="Enter a region name"
											value={values.updateRegionName}
											onChange={handleChange}
											invalid={errors.updateRegionName && touched.updateRegionName}
											bsSize="sm"
										/>
										<FormText>Region name should be no more than 45 characters</FormText>
										<FormFeedback>{errors.updateRegionName}</FormFeedback>
									</FormGroup>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="updateRegionLeft">View left</Label>
												<Input
													tag={Field}
													id="updateRegionLeft"
													placeholder="Enter a left coordinate"
													value={values.updateRegionLeft}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.updateRegionLeft && touched.updateRegionLeft}
													bsSize="sm"
												/>
												<FormText>View left should be numeric</FormText>
												<FormFeedback>{errors.updateRegionLeft}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="updateRegionRight">View right</Label>
												<Input
													tag={Field}
													id="updateRegionRight"
													placeholder="Enter a right coordinate"
													value={values.updateRegionRight}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.updateRegionRight && touched.updateRegionRight}
													bsSize="sm"
												/>
												<FormText>View right should be numeric</FormText>
												<FormFeedback>{errors.updateRegionRight}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="updateRegionTop">View top</Label>
												<Input
													tag={Field}
													id="updateRegionTop"
													placeholder="Enter a top coordinate"
													value={values.updateRegionTop}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.updateRegionTop && touched.updateRegionTop}
													bsSize="sm"
												/>
												<FormText>View top should be numeric</FormText>
												<FormFeedback>{errors.updateRegionTop}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="updateRegionBottom">View bottom</Label>
												<Input
													tag={Field}
													id="updateRegionBottom"
													placeholder="Enter a bottom coordinate"
													value={values.updateRegionBottom}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.updateRegionBottom && touched.updateRegionBottom}
													bsSize="sm"
												/>
												<FormText>View bottom should be numeric</FormText>
												<FormFeedback>{errors.updateRegionBottom}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
									<Row form>
										<Col md={6}>
											<FormGroup>
												<Label for="updateRegionResolution">Resolution</Label>
												<Input
													tag={Field}
													id="updateRegionResolution"
													placeholder="Enter a resolution"
													value={values.updateRegionResolution}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={
														errors.updateRegionResolution && touched.updateRegionResolution
													}
													bsSize="sm"
												/>
												<FormText>Resolution should be numeric</FormText>
												<FormFeedback>{errors.updateRegionResolution}</FormFeedback>
											</FormGroup>
										</Col>
										<Col md={6}>
											<FormGroup>
												<Label for="updateRegionCamera">Camera</Label>
												<Input
													tag={Field}
													id="updateRegionCamera"
													placeholder="Enter a camera value"
													value={values.updateRegionCamera}
													onChange={handleChange}
													onBlur={handleBlur}
													invalid={errors.updateRegionCamera && touched.updateRegionCamera}
													bsSize="sm"
												/>
												<FormText>Camera should be numeric</FormText>
												<FormFeedback>{errors.updateRegionCamera}</FormFeedback>
											</FormGroup>
										</Col>
									</Row>
								</ModalBody>
								<ModalFooter>
									<Button color="primary" size="sm" type="submit" disabled={isSubmitting}>
										Update region
									</Button>{' '}
									<Button color="secondary" size="sm" type="button" onClick={this.toggleUpdate}>
										Cancel
									</Button>
								</ModalFooter>
							</Form>
						)}
					</Formik>
				</Modal>
			</Fragment>
		);
	}

	render() {
		return (
			<DocumentTitle title="Gains Admin | Regions">
				<Container fluid>
					<Crumbs path={[ { title: 'Regions' } ]} />
					{this.buildResultsTable()}
					{this.buildModals()}
				</Container>
			</DocumentTitle>
		);
	}
}

const mapStateToProps = ({ regions }) => {
	return {
		regions // redux
	};
};

const mapDispatchToProps = (dispatch) => ({
	fetchRegions: () => dispatch(fetchRegions()),
	createRegion: (data) => dispatch(createRegion(data)),
	updateRegion: (data) => dispatch(updateRegion(data))
});

export default connect(mapStateToProps, mapDispatchToProps)(Regions);
