import React from 'react';

export default () => {
	return (
		<ol>
			<li>
				<strong>Definitions</strong>
				<ol>
					<li>
						<strong>"Agreement"</strong> means these terms and conditions.
					</li>
					<li>
						<strong>"Applicable Law"</strong> means any legally binding obligation applicable to a party,
						including statutes, rules, regulations, codes, court rulings, or any other binding requirement.
					</li>
					<li>
						<strong>"Earth-i"</strong>, <strong>"we"</strong>, <strong>"us"</strong> or <strong>"our"</strong> means
						Earth-i Ltd., with its registered office at Northside House, 69 Tweedy Road, Bromley, Kent,
						England, BR1 3WA, with company number 08508188 and VAT number GB216813321.
					</li>
					<li>
						<strong>"Gains Data"</strong> means data delivered through the Gains applications programming
						interface or user interface.
					</li>
					<li>
						<strong>"Gains Admin Service"</strong> means the user interface provided at
						https://Gains-admin.earthi.world, the APIs connected with that system and all data and content
						provided through that system
					</li>
					<li>
						<strong>"Exported Data"</strong> means Gains Data that is exported or downloaded from our
						systems.
					</li>
					<li>
						<strong>"Services"</strong> means the services that we agree to provide to you, including the
						Gains Admin Service.
					</li>
					<li>
						<strong>"Term"</strong> is defined in section 7.1.
					</li>
					<li>
						<strong>"User"</strong> means an individual that you (or your agency clients) have authorised to
						use the Services.
					</li>
					<li>
						<strong>"You"</strong> or <strong>"Customer"</strong> means the party whose details are
						registered in the Gains Admin Service.
					</li>
				</ol>
			</li>
			<li>
				<strong>The Services</strong>
				<ol>
					<li>
						<strong>The Services:</strong>This Agreement governs your use of our Services and any
						information provided to you resulting from your use of those Services. The Services are subject
						to the terms of this Agreement.
					</li>
					<li>
						<strong>Warranties and exclusions:</strong> We warrant that during the Term we will: (a) provide
						the Services with reasonable skill and care; (b) maintain all licences and permissions necessary
						to perform our obligations under this Agreement. We do not provide any warranty as to the
						fitness of the Gains Admin Service or Gains Data for any particular purpose.
					</li>
					<li>
						<strong>No liability:</strong> We will provide the Services as a preview of a future operational
						product. We exclude all liability for the performance, functionality or the result of or use of
						the content of the Gains Admin Service and the Gains Data.
					</li>
				</ol>
			</li>
			<li>
				<strong>Your use of the services</strong>
				<ol>
					<li>
						<strong>Responsibility:</strong> You: (a) are responsible for your use of the Services and for
						your Users' compliance with the online user guides, help and training materials, and your Users'
						compliance with this Agreement (as if they were you); (b) will ensure that your use of Exported
						Data complies with Applicable Law; (c) will comply with any other existing agreements between
						you and Earth-i.
					</li>
					<li>
						<strong>Password protection:</strong> You are responsible for ensuring that any user IDs and
						passwords required for your Users to use Services are kept safe and confidential. You will
						promptly notify us upon discovery if the security of a user ID or password may be compromised.
					</li>
					<li>
						<strong>Restrictions:</strong> You will not: (a) sell, resell, license, sublicense, distribute,
						or otherwise make the Services (or the results of the Services) and / or the Gains Data
						available to anybody other than your Users; (b) subject to Applicable Law, attempt to reverse
						compile, disassemble, reverse engineer, or otherwise reduce to human-perceivable form any part
						of the Services; (c) use the Services or any Gains Data to violate Applicable Law, including
						Applicable Law about data protection, privacy, or information security; (d) communicate any
						material which is obscene, defamatory, offensive, or abusive via the Services; or (e)
						purposefully interfere with or disrupt the integrity or performance of our Services, including
						spamming, hacking, purposefully violating or circumventing our API rate limits, or similar
						activities.
					</li>
					<li>
						<strong>Removal of Gains Data:</strong> A licensor or Applicable Law may require us to remove
						Gains Data from our Services. In such cases, we will notify you of the impacted data and you
						will promptly remove the same Exported Data from your systems, whether during or after the Term.
					</li>
				</ol>
			</li>
			<li>
				<strong>Fees and payment for services</strong>
				<ol>
					<li>
						<strong>Fees:</strong> No fees are chargeable for the Gains Admin Service.
					</li>
					<li>
						<strong>Other services:</strong> Fees may be chargeable as agreed by the Parties from time to
						time.
					</li>
					<li>
						<strong>Invoicing and Late Payment:</strong> You will provide us with the information we
						reasonably require to invoice you. If you pay the fees late, we may charge you interest at 1.5%
						over the applicable base rate of our principal bankers compounded monthly, or any higher amount
						allowed by Applicable Law.
					</li>
					<li>
						<strong>Taxes:</strong> Our fees are exclusive of legally applicable taxes, levies, duties or
						similar governmental assessments, including goods and services, value-added, and sales taxes
						(collectively, <strong>"Taxes"</strong>).
					</li>
				</ol>
			</li>
			<li>
				<strong>Confidential information</strong>
				<ol>
					<li>
						<strong>Confidential Information:</strong> "Confidential Information" means any information that
						is disclosed by a party (<strong>"Disclosing Party"</strong>) to the other party (<strong>"Receiving Party"</strong>)
						that is expressly marked as confidential or that a reasonable person under the circumstances
						would understand to be confidential. Our Confidential Information includes Gains Data, trade
						secrets, non-public copyrighted materials, our product roadmap, and this Agreement. Confidential
						Information does not include any information that: (a) is or becomes generally known to the
						public without breach of any obligation owed to the Disclosing Party; (b) the Receiving Party
						knew prior to its disclosure by the Disclosing Party without breach of any obligation owed to
						the Disclosing Party; (c) is received from a third party without breach of any obligation owed
						to the Disclosing Party; or (d) the Receiving Party independently developed.
					</li>
					<li>
						<strong>Strict confidence:</strong> From the effective date of this Agreement until two years
						after the end of the Term, each party will hold in strict confidence, and will not use other
						than for the purposes of performing its obligations under this Agreement and/or using or
						supplying the Services, all Confidential Information of the other party. Nothing in this section
						5 prevents the Receiving Party from disclosing the Confidential Information of the Disclosing
						Party to its corporate affiliates, legal advisers, accountants, or other professional advisers
						where required (collectively, <strong>"Permitted Recipients"</strong>), provided that (i) the
						party so disclosing will remain responsible for its obligations and for the Permitted
						Recipients' use (and any disclosure) of the Confidential Information.
					</li>
				</ol>
			</li>
			<li>
				<strong>Intellectual Property</strong>
				<ol>
					<li>
						<strong>Our intellectual property rights:</strong> We, or our licensors, own all intellectual
						property rights in the Services and in the Gains Data. Except as expressly stated otherwise in
						this Agreement, no one is granted any rights to or interest of any kind in our intellectual
						property rights.
					</li>
					<li>
						<strong>Exported Data Licence:</strong> We grant to you a worldwide, non-exclusive,
						non-transferable, royalty-free, licence to use the Exported Data in accordance with this
						Agreement.
					</li>
					<li>
						<strong>Licence to use your feedback:</strong> You grant to us, and you undertake to procure
						that your Users grant to us, a worldwide, perpetual, irrevocable, royalty-free licence to use
						and incorporate into the Services any suggestion, enhancement request, recommendation,
						correction, or other feedback provided by you or your Users arising out of your use of the
						Services.
					</li>
				</ol>
			</li>
			<li>
				<strong>Term and termination</strong>
				<ol>
					<li>
						<strong>Term:</strong> The Term of this Agreement is the period from the date of acceptance for
						two years, unless terminated earlier in accordance with this Agreement.
					</li>
					<li>
						<strong>Termination by either Party:</strong> Either Party may terminate this Agreement by
						written notice to the other party at any time if: (a) the other party is in material breach and,
						if remediable, the breach is not remedied within 30 days of being notified in writing of the
						breach; or (b) the other party begins bankruptcy proceedings or becomes the subject of a
						petition in liquidation or any other proceeding relating to insolvency, liquidation, or
						assignment for the benefit of creditors.
					</li>
					<li>
						<strong>Our termination rights:</strong> We may immediately suspend our performance or terminate
						this Agreement upon written notice if: (a) you breach any the clauses in section 3; (b) we have
						good faith concerns about your financial solvency; or (c) you fail to pay any fees by their due
						date and the default is more than 30 days.
					</li>
					<li>
						<strong>Accrued rights and liabilities and survival:</strong> Termination of this Agreement,
						however arising, does not affect the accrued rights and liabilities of the parties as at
						termination. The following sections survive termination of this Agreement: 1, 3.1, 3.4, 5, 6, 7,
						8, 9, and 10.
					</li>
				</ol>
			</li>
			<li>
				<strong>Exclusions and limitations of liability</strong>
				<ol>
					<li>
						<strong>What the parties are liable for:</strong> Nothing in this Agreement excludes or limits a
						party's liability for any liability that cannot be excluded or limited under Applicable Law
						(including fraud).
					</li>
					<li>
						<strong>Losses a party is never liable for:</strong> Subject to section 8.1, each party excludes
						all liability, whether in tort (including for negligence), breach of statutory duty, contract,
						misrepresentation, restitution, or otherwise, direct or indirect, whether foreseeable, known,
						foreseen, or otherwise, however arising, for any: (a) loss of revenue or profits (of any kind);
						(b) loss of goodwill; (c) loss of business; or (d) special, indirect, or consequential loss,
						costs, damages, charges, or expenses.
					</li>
					<li>
						<strong>Liability cap:</strong> Subject always to sections 2.3, 8.1, 8.2 and 10.10, each party's
						total liability, however arising, will under no circumstances exceed in aggregate, the greater
						of: (a) £30,000 or (b) 110% of the total amount of fees paid or payable for our Services under
						this Agreement for the 12 months immediately preceding the date any first claim arose.
					</li>
				</ol>
			</li>
			<li>
				<strong>Mutual indemnities</strong>
				<ol>
					<li>
						<strong>When you indemnify us:</strong> You will indemnify us, our Affiliates, and our and their
						officers, directors, employees, and agents, against any Losses arising out of your breach
						(including a breach by your Users or any agency clients) of section 3.
					</li>
					<li>
						<strong>Indemnity process:</strong> An indemnity is subject to the indemnified party giving the
						indemnifying party prompt written notice of any claim and sole control to defend or settle the
						claim. The indemnified party will use its commercially reasonable efforts to mitigate its
						Losses.
					</li>
				</ol>
			</li>
			<li>
				<strong>General</strong>
				<ol>
					<li>
						<strong>Privacy:</strong> We collect and process User data in accordance with our{' '}
						<a href="https://earthi.space/privacy-policy/" target="_blank" rel="noopener noreferrer">
							Privacy Policy
						</a>.
					</li>
					<li>
						<strong>Rules of interpretation:</strong> The following rules of interpretation apply to this
						Agreement: (a) the words <strong>"include"</strong> and <strong>"including"</strong> are deemed
						to have the words <strong>"without limitation"</strong> following them; (b) references to the
						singular include the plural and vice versa; and (c) references to <strong>"will"</strong> are to
						be construed as having the same meaning as <strong>"shall"</strong>.
					</li>
					<li>
						<strong>Force Majeure:</strong> Neither party is liable for a breach caused by an event beyond
						its reasonable control, including a natural disaster, disease outbreak, war, riot, terrorist
						action, civil commotion, malicious damage, government action, industrial action or dispute,
						fire, flood, storm, or failure of third party telecommunications services.
					</li>
					<li>
						<strong>Publicity:</strong> We will not disclose that you are a customer without your prior
						written consent.
					</li>
					<li>
						<strong>Invalidity:</strong> If any term of this Agreement is found invalid, illegal or
						unenforceable, the rest of the Agreement remains in effect.
					</li>
					<li>
						<strong>No waiver:</strong> Either party's delay or failure to enforce a term of the Agreement
						is not a waiver of that right and does not prevent that party from later enforcing that term or
						any other term.
					</li>
					<li>
						<strong>Notice:</strong> Each party will deliver notices for legal service or material breach by
						a courier service or recorded delivery: for Earth-i, to our registered office address as set out
						above; for you, to your registered company address. Any notices for any matter other than legal
						service or material breach may be delivered in accordance with the previous sentence or by
						email. If sent by email, you will send the notice to Gains@earthi.world and we will send the
						notice to you to via the email registered in the Gains Admin Service. Notice sent by recorded
						delivery is received three London, UK business days after posting. A party is deemed to receive
						email notice one working day after it is sent.
					</li>
					<li>
						<strong>Assignment:</strong> You may not assign, transfer, charge or otherwise encumber, create
						any trust over, or deal in any manner with this Agreement, or any right, benefit, or interest
						under it, nor transfer or novate (each an <strong>"Assignment"</strong>) without our prior
						written consent. Any Assignment without our prior written consent is void.
					</li>
					<li>
						<strong>Anti-bribery:</strong> The parties will: (a) comply with all Applicable Law relating to
						anti-bribery or anti-corruption; (b) not engage in any act which, if it had occurred in the
						United Kingdom, the United States, or Singapore would have violated Applicable Law relating to
						anti-bribery or anti-corruption; and (c) promptly report to the other party if it receives a
						request for undue advantage.
					</li>
					<li>
						<strong>Entire agreement:</strong> This Agreement, including materials incorporated by
						reference, constitutes the exclusive statement of agreement and understanding between the
						parties. This Agreement excludes all prior or contemporaneous proposals, understandings,
						agreements, or representations, whether oral or written about its subject matter. You agree that
						no representations were made prior to entering into this Agreement and that, in entering into
						this Agreement, you do not rely on, and will have no remedy in respect of, any statement,
						representation, warranty, or understanding (whether negligently or innocently made) of any
						person (whether party to this Agreement or not) other than as set out in this Agreement. Nothing
						in this Agreement excludes or limits our liability for fraudulent misrepresentations or excludes
						(but this Agreement does limit) our liability for any fundamental misrepresentation.
					</li>
					<li>
						<strong>Order of priority:</strong> If there is a conflict of this Agreement: sections 8 and
						10.10 of these terms and conditions prevail over other sections of these terms and conditions.
						The English version of these terms and conditions prevails over any non-English version.
					</li>
					<li>
						<strong>Third party rights:</strong> Other than as set out in sections 9.1 and 9.2, nothing in
						this Agreement creates or confers any rights or other benefits in favour of any person other
						than the parties to this Agreement even if that person has relied on the term or has indicated
						to any party to this Agreement its assent to the term.
					</li>
					<li>
						<strong>No agency:</strong> This Agreement does not create a partnership, franchise, joint
						venture, agency, fiduciary, or employment relationship between the parties.
					</li>
					<li>
						<strong>Governing law and jurisdiction:</strong> This Agreement and any dispute or claim arising
						out of or in connection with it or its subject matter, existence, negotiation, validity or
						termination (including non-contractual disputes or claims) shall be governed by and construed in
						accordance with English law.
					</li>
				</ol>
			</li>
		</ol>
	);
};
