import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

import {
	FETCH_REGIONS,
	FETCH_REGIONS_ERR,
	CREATE_REGION,
	CREATE_REGION_ERR,
	UPDATE_REGION,
	UPDATE_REGION_ERR,
	getAuthHeaders
} from 'actions';
import config from 'config';
import { replacer } from 'utils';

export const fetchRegions = () => async (dispatch) => {
	const request = API.get('gains-admin-api', config.apiEndpoints.regions, {
		headers: {}
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_REGIONS,
				payload: response
			}),
		(err) =>
			dispatch({
				type: FETCH_REGIONS_ERR,
				payload: err
			})
	);
};

export const createRegion = (payload) => async (dispatch) => {
	const request = API.post('gains-admin-api', config.apiEndpoints.createRegion, {
		headers: await getAuthHeaders(),
		body: payload
	});

	return request.then(
		(response) => {
			dispatch({
				type: CREATE_REGION,
				payload: response[0]
			});
			dispatch(fetchRegions());
			toastr.success('Create Region', 'Region created successfully');
		},
		(err) => {
			dispatch({
				type: CREATE_REGION_ERR,
				payload: err
			});
			toastr.error('Create Region', 'There was an error creating the region');
		}
	);
};

export const updateRegion = (payload) => async (dispatch) => {
	const { id } = payload;

	const request = API.put(
		'gains-admin-api',
		replacer(config.apiEndpoints.updateRegion, {
			id
		}),
		{
			headers: await getAuthHeaders(),
			body: payload
		}
	);

	return request.then(
		(response) => {
			dispatch({
				type: UPDATE_REGION,
				payload: response[0]
			});
			dispatch(fetchRegions());
			toastr.success('Update Region', 'Region updated successfully');
		},
		(err) => {
			dispatch({
				type: UPDATE_REGION_ERR,
				payload: err
			});
			toastr.error('Update Region', 'There was an error updating the region');
		}
	);
};



