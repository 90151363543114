import { API } from 'aws-amplify';
import { toastr } from 'react-redux-toastr';

import {
	FETCH_COUNTRIES,
	FETCH_COUNTRIES_ERR,
} from 'actions';
import config from 'config';

export const fetchCountries = () => async (dispatch) => {
	const request = API.get('gains-admin-api', config.apiEndpoints.countries, {
		headers: {}
	});

	return request.then(
		(response) =>
			dispatch({
				type: FETCH_COUNTRIES,
				payload: response
			}),
		(err) => {
			dispatch({
				type: FETCH_COUNTRIES_ERR,
				payload: err
			});
			toastr.error('Cannot fetch countries', 'Please allow time to fetch the countries data');
		}
	);
};
